const getRegencyCode = (regency) => {
  if (regency === 'Gresik') {
    return 'GRS';
  }

  if (regency === 'Surabaya') {
    return 'SBY';
  }

  if (regency === 'Sidoarjo') {
    return 'SDA';
  }

  if (regency === 'Mojokerto') {
    return 'MJK';
  }

  return '-';
};

const getRomanCode = (month) => {
  switch (month) {
    case 1:
      return 'I';
    case 2:
      return 'II';
    case 3:
      return 'III';
    case 4:
      return 'IV';
    case 5:
      return 'V';
    case 6:
      return 'VI';
    case 7:
      return 'VII';
    case 8:
      return 'VIII';
    case 9:
      return 'IX';
    case 10:
      return 'X';
    case 11:
      return 'XI';
    case 12:
      return 'XII';
    default:
      return '-';
  }
};

const split = (list, len) => {
  const result = [];
  const total = Math.ceil(list.length / 3);
  list = list.reverse();
  for (let i = 0; i < total; i++) {
    result.push(list.splice(0, len));
  }

  for (let i = 0; i < result.length; i++) {
    result[i] = result[i].reverse();
  }
  return result.reverse();
};

export const generateReferenceDocument = (order, documentType) => {
  const regencyCode = getRegencyCode(order.client.regency);
  const romanMonth = getRomanCode(new Date().getMonth() + 1);

  let type = '';

  if (documentType === 'SURAT_JALAN') {
    type = 'SJ';
  } else if (documentType === 'INVOICE') {
    type = 'INV';
  }

  return `${order.id}/${type}/${
    order.company
  }/${regencyCode}/${romanMonth}/${new Date().getFullYear()}`;
};

export const numberInWords = (number) => {
  const numStr = number.toString();
  const satuan = [
    'Satu',
    'Dua',
    'Tiga',
    'Empat',
    'Lima',
    'Enam',
    'Tujuh',
    'Delapan',
    'Sembilan',
  ];
  const besaran = ['', 'Puluh', 'Ratus', 'Ribu', 'Juta', 'Miliar', 'Triliun'];
  const subBesaran = ['', 'Ribu', 'Juta', 'Miliar', 'Triliun'];

  const tmp = split(numStr.split(''), 3);

  for (let i = 0; i < tmp.length; i++) {
    let iLen = tmp[i].length;
    for (let j = 0; j < tmp[i].length; j++) {
      tmp[i][j] = (satuan[tmp[i][j] - 1] + ' ' + besaran[iLen - 1 - j]).trim();
      if (tmp[i][j - 1] === 'satu puluh') {
        if (tmp[i][j] === 'satu') {
          tmp[i][j] = 'sebelas';
          tmp[i][j - 1] = '';
        } else {
          tmp[i][j - 1] = tmp[i][j];
          tmp[i][j] = 'belas';
        }
      }

      if (tmp[i][j].split(' ')[0] === 'undefined') {
        tmp[i][j] = '';
      }

      if (
        tmp[i][j].split(' ')[0] === 'satu' &&
        (tmp[i][j].split(' ')[1] === 'ribu' ||
          tmp[i][j].split(' ')[1] === 'ratus')
      ) {
        tmp[i][j] = 'se' + tmp[i][j].split(' ')[1];
      }
    }

    if (tmp.length > 1) {
      tmp[i].push(subBesaran[tmp.length - 1 - i]);
    }
  }
  return `${tmp
    .join(' ')
    .replace(/,/g, ' ')
    .replace(/  +/g, ' ')
    .trim()} Rupiah`;
};

export const checkDisabledStatusOrder = (status) => {
  if (['TERBELI', 'BATAL', 'TERKIRIM', 'TERBAYAR', 'RETUR'].includes(status)) {
    return true;
  }

  return false;
};

export const selectedStatusItems = (status) => {
  if (status === 'NEW') {
    return ['NEW', 'TERBELI', 'BATAL'];
  }

  if (status === 'TERBELI') {
    return ['TERBELI', 'TERKIRIM'];
  }

  if (status === 'TERKIRIM') {
    return ['TERKIRIM', 'TERBAYAR', 'RETUR'];
  }

  if (status === 'TERBAYAR') {
    return ['TERBAYAR', 'RETUR'];
  }

  return [];
};

export const checkStatusOrder = (items) => {
  if (!items.length) return 'CANCEL';

  const statusItems = items
    .filter((y) => y.status !== 'CANCEL')
    .map((x) => x.status);

  if (statusItems.some((x) => x === 'TERBELI')) {
    return 'PROCESS';
  }

  if (statusItems.every((x) => x === 'TERKIRIM')) {
    return 'WAITING INPUT BILL';
  }

  if (statusItems.every((x) => x === 'TERBAYAR')) {
    return 'DONE';
  }

  return 'NEW';
};

export const chipColor = (value) => {
  if (value === 'NEW') {
    return 'info';
  }

  if (value === 'PROCESS') {
    return 'warning';
  }

  if (value === 'DONE') {
    return 'success';
  }

  if (value === 'WAITING INPUT BILL') {
    return 'warning';
  }

  if (value === 'WAITING PAYMENT') {
    return 'error';
  }

  if (value === 'GJI') {
    return 'primary';
  }

  if (value === 'GJA') {
    return 'warning';
  }

  if (value === 'CANCEL') {
    return 'error';
  }

  return 'primary';
};

export const isShowBill = (items) => {
  if (!items?.length) return false;

  const data = items.filter((x) =>
    ['NEW', 'TERBELI', 'TERKIRIM'].includes(x.status),
  );
  const total = data.length;
  const shippedItems = data.filter((x) => x.status === 'TERKIRIM');

  if (total === shippedItems.length) {
    return true;
  }

  return false;
};

export const companyInfo = (code) => {
  if (code === 'GJI') {
    return {
      name: 'CV GLOBAL JAYA INDO',
      address:
        'Perumahan Alam Bukit Raya Blok C3 No. 46 Desa Kembangan Kec. Kebomas Kab. Gresik, Kode Pos 61124',
      phone: '+62 813-8825-7789',
    };
  }

  if (code === 'GJA') {
    return {
      name: 'CV GANESHA JAYA ARTHA',
      address:
        'Jl. Jepara PPI Barat Blok B No 10 RT 02 / RW 01, Kel. Morokrembangan, Kec. Krembangan, Kota Surabaya, Prov. Jawa Timur',
      phone: '+62 813-9343-9398',
    };
  }

  return {
    name: 'CV GLOBAL JAYA INDO',
    address:
      'Perumahan Alam Bukit Raya Blok C3 No. 46 Desa Kembangan Kec. Kebomas Kab. Gresik, Kode Pos 61124',
    phone: '+62 813-8825-7789',
  };
};

export const orderDocuments = (order) => {
  if (order?.status === 'NEW') {
    return [
      'Cetak Rencana Belanja',
      'Cetak Surat Jalan',
      'Tambah Item Rencana Belanja',
    ];
  }

  if (order?.status === 'PROCESS' || order?.status === 'WAITING INPUT BILL') {
    return [
      'Cetak Surat Jalan',
      'Cetak Rencana Belanja',
      'Cetak Informasi Produk',
    ];
  }

  if (
    order?.status === 'DONE' ||
    order?.status === 'WAITING PAYMENT' ||
    order?.status === 'PENDING PAYMENT' ||
    order?.status === 'WAITING CASH'
  ) {
    if (order?.is_custom) {
      return [
        'Cetak Tagihan',
        'Cetak Tagihan Custom',
        'Cetak Informasi Produk',
      ];
    }

    return ['Cetak Tagihan', 'Cetak Informasi Produk'];
  }

  return [];
};

export const showButtonNonCash = (status) => {
  if (status === 'WAITING INPUT BILL' || status === 'PENDING PAYMENT') {
    return true;
  }

  return false;
};

export const getTotalCashBill = (data) => {
  // TOTAL TAGIHAN
  return data?.sub_total + data?.total_loan - data?.total_discount;
};

export const checkPaymentMethod = (data) => {
  if (data?.payment_method) {
    return data?.payment_method;
  }

  return '';
};

export const getTotalInstalments = (data, order) => {
  if (order?.status === 'PENDING PAYMENT') {
    const existingInstallments = order?.order_payments?.reduce(
      (n, { payment_fee }) => Number(n) + Number(payment_fee),
      0,
    );

    const currentInstallments = data?.reduce(
      (n, { amount }) => Number(n) + Number(amount.value),
      0,
    );

    return existingInstallments + currentInstallments;
  }

  if (order?.status === 'DONE') {
    return order?.order_payments?.reduce(
      (n, { payment_fee }) => Number(n) + Number(payment_fee),
      0,
    );
  }

  return data?.reduce((n, { amount }) => Number(n) + Number(amount.value), 0);
};

export const checkInstallmentCashMethod = (grandTotal, totalPayment) => {
  if (totalPayment <= grandTotal) {
    return false;
  }

  return true;
};

export const checkBtnNonCash = (totalSales, currentPayment) => {
  if (currentPayment <= totalSales) {
    return false;
  }

  return true;
};

export const getExtraBasePrice = (data) => {
  if (
    data.extra_base_price &&
    data.total_extra_base_price &&
    data.status !== 'NEW'
  ) {
    return {
      extra: Number(data.extra_base_price).toLocaleString('id-ID'),
      total: data.total_extra_base_price,
    };
  }

  return {
    extra: Math.floor(data.base_price * 0.01),
    total: Math.floor(data.base_price + data.base_price * 0.01),
  };
};

export const generateArrayOfYears = () => {
  const initialYear = 2022;
  const currentYear = new Date().getFullYear();
  const range = currentYear - initialYear;
  let years = [];

  for (var i = 0; i <= range; i++) {
    years.push(initialYear + i);
  }

  return years;
};

/**
 *
 * @param {*} row
 * @returns
 *
 * RUMUS: HARGA JUAL - (HARGA DASAR + ONGKIR) - DISCOUNT
 */
export const displayEstimateProfit = (row) => {
  const basePrice = row?.base_price + row?.delivery_fee - row?.discount;
  const nominal = row?.selling_price - basePrice;
  // const percentage = row?.profit_percentage;
  const percentage = Math.floor(
    ((row.selling_price -
      row?.total_extra_base_price -
      row.discount -
      row.delivery_fee) /
      row?.total_extra_base_price) *
      100,
  );

  return (
    <div style={{ whiteSpace: 'pre-line' }}>
      {`Estimasi Laba Rp. ${nominal.toLocaleString('id-ID')}
    Estimasi Laba ${percentage}%`}
    </div>
  );
};

export const displayTextFee = (type) => {
  if (['PLATFORM', 'EXPENSE'].includes(type)) {
    const percent = type === 'PLATFORM' ? 5 : 2;
    return (
      <div
        style={{ whiteSpace: 'pre-line' }}
      >{`Rumus: ${percent}% dari Sub Total dan Total Pembelanjaan tidak mencapai 40% dari Uang Masuk.
    `}</div>
    );
  }

  if (type === 'SUB_TOTAL') {
    return (
      <div
        style={{ whiteSpace: 'pre-line' }}
      >{`Rumus: Uang Masuk - Total Pajak - Total Jual - Pinjaman
      *Uang Masuk: Nominal pada No Referensi + Down Payment
    `}</div>
    );
  }

  if (type === 'REFUND') {
    return (
      <div style={{ whiteSpace: 'pre-line' }}>{`Rumus: Sub Total - Platform Fee
    `}</div>
    );
  }

  if (type === 'TOTAL') {
    return (
      <div
        style={{ whiteSpace: 'pre-line' }}
      >{`Rumus: Pengembalian - Total Diskon
    `}</div>
    );
  }

  if (type === 'UANG_MASUK') {
    return (
      <div
        style={{ whiteSpace: 'pre-line' }}
      >{`Rumus: Total Nominal No Referensi - Down Payment
    `}</div>
    );
  }
};

export const checkStatusRental = (rental) => {
  if (rental?.status === 'ONGOING') {
    // if (!rental?.print_installation_fee) {
    return ['Cetak Surat Jalan', 'Cetak Invoice Instalasi & Ongkir'];
    // }

    // return ['Cetak Surat Jalan'];
  }

  if (rental?.status === 'NEW') {
    return ['Cetak Surat Penawaran'];
  }

  if (rental?.status === 'OFFERING') {
    if (rental?.generate_offering && !rental?.generate_mou)
      return ['Edit Kontrak', 'Cetak MoU', 'Cetak Surat Penawaran'];

    if (rental?.generate_offering && rental?.generate_mou)
      return ['Cetak MoU', 'Approve Kontrak', 'Cetak Surat Penawaran'];

    return ['Cetak Surat Penawaran'];
  }

  return [];
};

export const chipPayment = (value) => {
  // PENDING, GENERATED, WAITING PAYMENT, PAID
  if (value === 'PENDING') {
    return 'error';
  }

  if (value === 'GENERATED') {
    return 'info';
  }

  if (value === 'WAITING PAYMENT') {
    return 'primary';
  }

  if (value === 'PAID') {
    return 'success';
  }

  return 'primary';
};
