import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const DashboardPDF = (
  dashboard,
  dashboardEstimate,
  { year, employees, employee },
) => {
  let doc = new jsPDF({
    putOnlyUsedFonts: true,
    orientation: 'portrait',
    format: [210, 330],
  });

  doc.setFontSize(13);
  doc.setFont('times', 'bold');
  doc.setFont('times', 'normal');

  doc.setFontSize(10);
  doc.setFont('times', 'bold');

  const marketing =
    employee === 0
      ? 'Semua'
      : employees.find((x) => Number(x.id) === Number(employee))?.name || '';

  doc.text(`Tahun: ${year}`, 14, 10);
  doc.text(`Nama Marketing: ${marketing}`, 14, 15);

  autoTable(doc, {
    startY: 18,
    head: [['#', 'Cawu 1', 'Cawu 2', 'Cawu 3']],
    theme: 'plain',
    body: [
      [
        'Total Harga Dasar',
        `Rp. ${dashboard[0].total_base_price?.toLocaleString('id-ID')}`,
        `Rp. ${dashboard[1].total_base_price?.toLocaleString('id-ID')}`,
        `Rp. ${dashboard[2].total_base_price?.toLocaleString('id-ID')}`,
      ],
      [
        'Total Penjualan',
        `Rp. ${dashboard[0].total_sales?.toLocaleString('id-ID')}`,
        `Rp. ${dashboard[1].total_sales?.toLocaleString('id-ID')}`,
        `Rp. ${dashboard[2].total_sales?.toLocaleString('id-ID')}`,
      ],
      [
        'Total Laba',
        `Rp. ${dashboard[0].total_profit?.toLocaleString('id-ID')}`,
        `Rp. ${dashboard[1].total_profit?.toLocaleString('id-ID')}`,
        `Rp. ${dashboard[2].total_profit?.toLocaleString('id-ID')}`,
      ],
      [
        'Total Diskon',
        `Rp. ${dashboard[0].total_discount?.toLocaleString('id-ID')}`,
        `Rp. ${dashboard[1].total_discount?.toLocaleString('id-ID')}`,
        `Rp. ${dashboard[2].total_discount?.toLocaleString('id-ID')}`,
      ],
      [
        'Total Ongkir',
        `Rp. ${dashboard[0].total_delivery_fee?.toLocaleString('id-ID')}`,
        `Rp. ${dashboard[1].total_delivery_fee?.toLocaleString('id-ID')}`,
        `Rp. ${dashboard[2].total_delivery_fee?.toLocaleString('id-ID')}`,
      ],
      [
        'Total Platform Fee Tagihan',
        `Rp. ${dashboard[0].total_platform_fee_bill?.toLocaleString('id-ID')}`,
        `Rp. ${dashboard[1].total_platform_fee_bill?.toLocaleString('id-ID')}`,
        `Rp. ${dashboard[2].total_platform_fee_bill?.toLocaleString('id-ID')}`,
      ],
      [
        'Total Platform Fee Nota',
        `Rp. ${dashboard[0].total_platform_fee_invoice?.toLocaleString(
          'id-ID',
        )}`,
        `Rp. ${dashboard[1].total_platform_fee_invoice?.toLocaleString(
          'id-ID',
        )}`,
        `Rp. ${dashboard[2].total_platform_fee_invoice?.toLocaleString(
          'id-ID',
        )}`,
      ],
      [
        'Estimasi Total Penjualan',
        `Rp. ${dashboardEstimate[0].estimate_total_sales?.toLocaleString(
          'id-ID',
        )}`,
        `Rp. ${dashboardEstimate[1].estimate_total_sales?.toLocaleString(
          'id-ID',
        )}`,
        `Rp. ${dashboardEstimate[2].estimate_total_sales?.toLocaleString(
          'id-ID',
        )}`,
      ],
      [
        'Estimasi Total Laba',
        `Rp. ${dashboardEstimate[0].estimate_profit?.toLocaleString('id-ID')}`,
        `Rp. ${dashboardEstimate[1].estimate_profit?.toLocaleString('id-ID')}`,
        `Rp. ${dashboardEstimate[2].estimate_profit?.toLocaleString('id-ID')}`,
      ],
    ],
    // Note that the "id" key below is the same as the column's dataKey used for
    // the head and body rows. If your data is entered in array form instead you have to
    // use the integer index instead i.e. `columnStyles: {5: {fillColor: [41, 128, 185], ...}}`
    // rgb(226,135,67)
    columnStyles: {
      0: { fontStyle: 'bold' },
    },
    headStyles: { fillColor: [47, 84, 150], textColor: 'white' },
  });

  doc.setProperties({
    title: `Dashboard Tahun ${year}`,
  });

  window.open(
    doc.output('bloburl', {
      filename: `Purchase Order O.pdf`,
    }),
  );

  // doc.save('Nota.pdf');
};

export default DashboardPDF;
