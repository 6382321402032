import React from 'react';
import { useMutation } from '@apollo/client';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
// import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import NumberFormat from 'react-number-format';

import {
  CREATE_PAYMENTS,
  CONFIRM_PAYMENT,
  UPDATE_PAYMENT,
  CREATE_OR_UPDATE_INSTALLMENT,
  ADJUST_NOMINAL_ORDER,
} from '../../graphql/Order';
import {
  generateReferenceDocument,
  showButtonNonCash,
  checkPaymentMethod,
  getTotalInstalments,
  checkInstallmentCashMethod,
  checkBtnNonCash,
  displayTextFee,
} from '../../utils';
import GlobalContext from '../../utils/GlobalContext';
const DialogDownPayment = React.lazy(() => import('./DialogDownPayment'));
const DialogLoan = React.lazy(() => import('./DialogLoan'));

const PaymentSection = (props) => {
  const { order, setOrder, role } = props;

  const isProcessPayment =
    order?.status === 'WAITING PAYMENT' ||
    order?.status === 'DONE' ||
    order?.status === 'PENDING PAYMENT' ||
    order?.status === 'WAITING CASH';
  const isDone = order?.status === 'DONE';
  const [paymentType, setPaymentType] = React.useState(
    isProcessPayment ? order?.payment_type : null,
  );
  const [statusPaid, setStatusPaid] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState(
    checkPaymentMethod(order),
  );
  const taxPeriod = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [totalPaymentFee, setTotalPaymentFee] = React.useState({
    value: isDone ? order?.total_payment_fee : 0,
    error: false,
    message: 'Harus diisi',
  });
  const [downPayment, setDownPayment] = React.useState([
    {
      id: 1,
      amount: {
        value: 0,
        error: false,
      },
      downPaymentAt: {
        value: 0,
        error: false,
      },
    },
  ]);
  const [loan, setLoan] = React.useState([
    {
      id: 1,
      amount: {
        value: 0,
        error: false,
      },
      loanAt: {
        value: 0,
        error: false,
      },
    },
  ]);
  const calculateDownPayment = () => {
    const existingDownPayment = order?.down_payments?.reduce(
      (n, { amount }) => Number(n) + Number(amount),
      0,
    );

    return (
      downPayment?.reduce(
        (n, { amount }) => Number(n) + Number(amount.value),
        0,
      ) + existingDownPayment
    );
  };

  const calculateLoan = () => {
    const existingLoan = order?.loans?.reduce(
      (n, { amount }) => Number(n) + Number(amount),
      0,
    );
    return (
      loan?.reduce((n, { amount }) => Number(n) + Number(amount.value), 0) +
      existingLoan
    );
  };
  const [cash, setCash] = React.useState({
    totalPaymentFee: isProcessPayment ? order?.total_payment_fee : 0,
    totalDownPayment: isProcessPayment
      ? order?.total_down_payment
      : calculateDownPayment(),
    // tax: isProcessPayment ? order?.tax : 0,
    tax: order?.tax,
    taxType: isProcessPayment ? order?.tax_type : '',
    loan: isProcessPayment ? order?.total_loan : 0,
    totalLoan: isProcessPayment ? order?.total_loan : calculateLoan(),
    taxPeriod: isProcessPayment ? order?.tax_period : new Date().getMonth() + 1,
  });
  const [nonCash, setNonCash] = React.useState([
    {
      id: 1,
      noReference: {
        value: null,
        error: false,
      },
      nominal: {
        value: 0,
        error: false,
      },
      tax: {
        value: 0,
        error: false,
      },
      mutation: {
        value: 0,
        error: false,
      },
      paymentAt: {
        value: null,
        error: false,
      },
    },
  ]);
  const [instalment, setInstalment] = React.useState([
    {
      id: 1,
      amount: {
        value: 0,
        error: false,
      },
      paymentAt: {
        value: null,
        error: false,
      },
    },
  ]);
  const [cashValidation, setCashValidation] = React.useState({
    taxType: false,
    taxTotal: false,
    loan: false,
    taxPeriod: false,
  });
  const [nonCashValidation, setNonCashValidation] = React.useState({
    taxType: false,
    tax: false,
  });
  const [openDownPayment, setOpenDownPayment] = React.useState(false);
  const [openLoan, setOpenLoan] = React.useState(false);
  const { setSnack, setGlobalLoading } = React.useContext(GlobalContext);

  const isDisabled = isProcessPayment ? true : false;
  const listOption = [
    {
      name: 'Dibayar Customer',
      value: 'CUSTOMER',
    },
    {
      name: 'Dibayar Perusahaan',
      value: 'COMPANY',
    },
  ];

  const [insertPaymentOrder] = useMutation(CREATE_PAYMENTS, {
    onCompleted: (d) => {
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data Pembayaran berhasil disimpan',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);

      window.location.reload();
    },
    onError: (e) => {
      console.log('error insert unit: ', e);
      let message = 'Data pembayaran gagal disimpan';
      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const [insertOrUpdateInstallment] = useMutation(
    CREATE_OR_UPDATE_INSTALLMENT,
    {
      onCompleted: (d) => {
        const result = { ...d.createOrUpdateInstallment };
        setOrder(result);

        setSnack({
          variant: 'success',
          message: 'Data Cicilan berhasil disimpan',
          opened: true,
        });
        setGlobalLoading(false);
        setOpen(false);
      },
      onError: (e) => {
        console.log('error insert unit: ', e);
        let message = 'Data Cicilan gagal disimpan';
        setGlobalLoading(false);
        setSnack({
          variant: 'error',
          message,
          opened: true,
        });
      },
    },
  );

  const [updatePaymentOrder] = useMutation(UPDATE_PAYMENT, {
    onCompleted: (d) => {
      const result = { ...d.updatePayment };
      setOrder(result);
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data Pembayaran berhasil disimpan',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);
      window.location.reload();
    },
    onError: (e) => {
      console.log('error insert unit: ', e);
      let message = 'Data pembayaran gagal disimpan';
      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const [confirmPayment] = useMutation(CONFIRM_PAYMENT, {
    onCompleted: (d) => {
      const result = { ...d.confirmPayment };
      setOrder(result);
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Konfirmasi Pembayaran berhasil!',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);
    },
    onError: (e) => {
      console.log('error insert unit: ', e);
      let message = 'Konfirmasi pembayaran gagal!';
      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const [adjustNominalOrder] = useMutation(ADJUST_NOMINAL_ORDER, {
    onCompleted: (d) => {
      const result = { ...d.adjustNominalOrder };
      setOrder(result);
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Konfirmasi Pembayaran berhasil!',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);
      window.location.reload();
    },
    onError: (e) => {
      console.log('error insert unit: ', e);
      let message = 'Konfirmasi pembayaran gagal!';
      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  // TIPE PEMBAYARAN TUNAI -> METODE PEMBAYARAN TUNAI
  const handleProcessBillCash = () => {
    if (
      !cash?.taxType ||
      cash?.loan === null ||
      cash?.tax === null ||
      !cash?.taxPeriod
    ) {
      if (!cash?.taxType) {
        setCashValidation((prev) => ({
          ...prev,
          taxType: true,
        }));
      }

      if (cash?.loan === null) {
        setCashValidation((prev) => ({
          ...prev,
          loan: true,
        }));
      }

      if (cash?.tax === null) {
        setCashValidation((prev) => ({
          ...prev,
          tax: true,
        }));
      }

      if (!cash?.taxPeriod) {
        setCashValidation((prev) => ({
          ...prev,
          taxPeriod: true,
        }));
      }

      return;
    }

    if (cash?.taxType === 'Dibayar Perusahaan' && cash?.tax === 0) {
      setCashValidation((prev) => ({
        ...prev,
        tax: true,
      }));

      return;
    }

    setOpen(true);
  };

  // TIPE PEMBAYARAN TUNAI -> METODE PEMBAYARAN CICIL
  const handleProcessBillInstallment = () => {
    setGlobalLoading(true);

    // CHECK VALIDASI
    const check = instalment.map((x) => {
      if (!x.amount.value) {
        x.amount.error = true;
      }

      if (!x.paymentAt.value) {
        x.paymentAt.error = true;
      }

      return x;
    });

    const notValidate = check.filter(
      (y) => y.amount.error || y.paymentAt.error,
    );

    if (notValidate.length) {
      setInstalment([...check]);
      setGlobalLoading(false);

      return;
    }

    let order_payments = instalment.map((x, index) => ({
      payment_order:
        order?.status === 'PENDING PAYMENT'
          ? order?.order_payments?.length + 1
          : index + 1,
      payment_fee: x.amount?.value,
      payment_at: x.paymentAt?.value,
    }));

    if (order?.order_payments.length) {
      const existing_payments = order.order_payments?.map((x) => ({
        id: x.id,
      }));
      order_payments = order_payments.concat(existing_payments);
    }
    const data = {
      payment_method: paymentMethod,
      total_payment_fee: getTotalInstalments(instalment, order), // TOTAL PEMBAYARAN
      order_payments,
    };
    const base = [
      {
        id: 1,
        amount: {
          value: 0,
          error: false,
        },
        paymentAt: {
          value: 0,
          error: false,
        },
      },
    ];
    setInstalment([...base]);
    insertOrUpdateInstallment({
      variables: { oid: order?.oid, data },
    });
  };

  const handleClickOpen = (status) => {
    // SIMPAN SEMENTARA / TERBITKAN
    if (status === 'NON_CASH_PENDING' || status === 'NON_CASH_PAY') {
      // if (!cash?.taxType) {
      //   setNonCashValidation((prev) => ({
      //     ...prev,
      //     taxType: true,
      //   }));
      // }

      // if (cash?.tax === null) {
      //   setNonCashValidation((prev) => ({
      //     ...prev,
      //     tax: true,
      //   }));
      // }

      if (calculateNominalNonCash() < order?.total_sales) {
        if (!cash?.taxType) {
          setNonCashValidation((prev) => ({
            ...prev,
            taxType: true,
          }));
        }

        const check = nonCash.map((x) => {
          if (!x.noReference?.value) {
            x.noReference.error = true;
          }

          if (!x.nominal?.value) {
            x.nominal.error = true;
          }

          if (!x.mutation?.value) {
            x.mutation.error = true;
          }

          if (!x.paymentAt?.value) {
            x.paymentAt.error = true;
          }

          return x;
        });

        const notValidate = check.filter(
          (y) =>
            y.noReference.error ||
            y.nominal.error ||
            y.mutation.error ||
            y.paymentAt.error,
        );

        if (notValidate.length) {
          setNonCash([...check]);
          return;
        }

        if (nonCashValidation?.taxType) {
          return;
        }
      }
    }

    setStatusPaid(status);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const subTotal = (type) => {
    if (type === 'CASH') {
      return Number(cash.tax) + Number(order?.total_sales);
    } else if (type === 'NON_CASH') {
      const totalPaymentFee = calculateNominalNonCash();
      const tax = totalTax();
      const totalSales = order?.total_sales;
      const loan = calculateLoan();

      const subTotal = totalPaymentFee - tax - totalSales - loan;

      // COMMENTED, SUBTOTAL CAN MINUS
      // if (subTotal <= 0) {
      //   return 0;
      // }

      return subTotal;
    }

    return 0;
  };

  const adminFee = () => {
    if (order?.status === 'DONE') {
      return {
        admin: order?.admin_fee,
        tax: order?.tax_admin_fee,
      };
    }

    const budget = Number(cash?.totalPaymentFee);
    const total = Number(order?.total_sales);
    const tax = totalTax();
    const feeAdmin = Math.ceil((budget - tax - total) * 0.05);
    const feeTax = Math.ceil((budget - tax - total) * 0.02);
    debugger;
    // FREE ADMIN IF 40%
    if ((order?.total_sales / calculateNominalNonCash()) * 100 >= 40) {
      return {
        admin: 0,
        tax: 0,
      };
    }

    return {
      admin: feeAdmin <= 0 ? 0 : order?.admin_fee + feeAdmin,
      tax: feeTax <= 0 ? 0 : order?.tax_admin_fee + feeTax,
    };
  };

  const changeAndTotal = (type) => {
    if (type === 'CASH') {
      if (!order?.payment_type) {
        return (
          subTotal('CASH') + Number(cash?.loan) - Number(order?.total_discount)
        );
      } else {
        return order?.grand_total;
      }
    } else if (type === 'NON_CASH') {
      const a = subTotal('NON_CASH');
      const b = adminFee().admin;
      const c = Number(order?.total_discount);

      return {
        change: a - b,
        total: a - b + c + c,
      };
    }

    return 0;
  };

  const totalTax = () => {
    const current = nonCash.reduce(
      (n, { tax }) => Number(n) + Number(tax.value),
      0,
    );
    const existing = order?.tax;
    return current + existing;
  };

  const handleSubmit = () => {
    if (paymentType === 'TUNAI') {
      // PROSES TAGIHAN TUNAI
      const data = {
        payment_type: paymentType,
        invoice_number: generateReferenceDocument(order, 'INVOICE'),
        tax_type: cash?.taxType,
        tax: cash?.tax ?? 0,
        tax_period: cash?.taxPeriod,
        total_loan: cash?.loan,
        sub_total: subTotal('CASH'),
        grand_total: changeAndTotal('CASH'),
      };

      if (cash?.loan) data.loans = [{ amount: cash?.loan }];

      insertPaymentOrder({
        variables: { oid: order?.oid, data },
      });
    } else if (paymentType === 'NON_TUNAI') {
      // PROSES TAGIHAN NON TUNAI
      const order_payments = nonCash
        .map((x, index) => ({
          payment_order: index + 1,
          payment_fee: x.nominal?.value,
          mutation: x.mutation?.value,
          difference: x.nominal?.value - x.mutation?.value,
          payment_reference: x.noReference?.value,
          payment_at: x.paymentAt?.value,
          tax: x?.tax?.value,
        }))
        .filter(
          (y) =>
            y.payment_fee || y.mutation || y.payment_reference || y.payment_at,
        );

      let loans = loan
        .map((x) => ({
          amount: x.amount?.value,
        }))
        .filter((y) => y.amount);
      let down_payments = downPayment
        .map((x) => ({
          amount: x.amount?.value,
        }))
        .filter((y) => y.amount);

      if (order?.status === 'PENDING PAYMENT') {
        const existingDownPayment = order?.down_payments?.map((x) => ({
          id: x.id,
          amount: x.amount,
        }));
        const existingLoan = order?.loans?.map((x) => ({
          id: x.id,
          amount: x.amount,
        }));
        down_payments = [...down_payments, ...existingDownPayment];
        loans = [...loans, ...existingLoan];
      }

      const data = {
        payment_type: paymentType,
        invoice_number: generateReferenceDocument(order, 'INVOICE'),
        tax_type: cash?.taxType,
        tax: totalTax() ?? 0,
        tax_period: cash?.taxPeriod,
        admin_fee: adminFee()?.admin,
        tax_admin_fee: adminFee()?.tax,
        total_loan: calculateLoan(),
        total_down_payment: calculateDownPayment(),
        total_payment_fee: calculateNominalNonCash(),
        sub_total: subTotal('NON_CASH'),
        grand_total: totalNonCash(),
        refund: refund(),
        order_payments: order_payments,
        type: statusPaid,
        loans,
        down_payments,
      };

      setNonCash([
        {
          id: Math.random(),
          noReference: {
            value: 0,
            error: false,
          },
          nominal: {
            value: 0,
            error: false,
          },
          tax: {
            value: 0,
            error: false,
          },
          mutation: {
            value: 0,
            error: false,
          },
          paymentAt: {
            value: 0,
            error: false,
          },
        },
      ]);

      if (
        order?.status === 'WAITING PAYMENT' ||
        order?.status === 'WAITING INPUT BILL'
      ) {
        insertPaymentOrder({
          variables: { oid: order?.oid, data },
        });
      }

      if (order?.status === 'PENDING PAYMENT') {
        updatePaymentOrder({
          variables: { oid: order?.oid, data },
        });
      }
    }
  };

  const handleConfirm = (method) => {
    if (method === 'TUNAI') {
      confirmPayment({
        variables: {
          oid: order?.oid,
          total_payment_fee: totalPaymentFee?.value,
          method,
        },
      });
    } else if (method === 'CICIL') {
    } else {
      if (!totalPaymentFee?.value) {
        setTotalPaymentFee((prev) => ({
          ...prev,
          error: true,
          message: 'Harus diisi',
        }));

        return;
      }

      if (totalPaymentFee?.value !== order?.grand_total) {
        setTotalPaymentFee((prev) => ({
          ...prev,
          error: true,
          message: 'Nominal pembayaran harus sesuai dengan tagihan',
        }));

        return;
      }
    }
  };

  const handleAddForm = (type) => {
    if (type === 'CICIL') {
      const oldInstalment = instalment;
      setInstalment([
        ...oldInstalment,
        {
          id: oldInstalment.length + 1,
          amount: {
            value: 0,
            error: false,
          },
          paymentAt: {
            value: null,
            error: false,
          },
        },
      ]);
    } else if (type === 'DOWN_PAYMENT') {
      const oldDownPayment = downPayment;
      setDownPayment([
        ...oldDownPayment,
        {
          id: oldDownPayment.length + 1,
          amount: {
            value: 0,
            error: false,
          },
          downPaymentAt: {
            value: null,
            error: false,
          },
        },
      ]);
    } else if (type === 'LOAN') {
      const oldLoan = loan;
      setLoan([
        ...oldLoan,
        {
          id: oldLoan.length + 1,
          amount: {
            value: 0,
            error: false,
          },
          loanAt: {
            value: null,
            error: false,
          },
        },
      ]);
    } else {
      const oldNonCash = nonCash;
      setNonCash([
        ...oldNonCash,
        {
          id: oldNonCash.length + 1,
          noReference: {
            value: null,
            error: false,
          },
          nominal: {
            value: 0,
            error: false,
          },
          tax: {
            value: 0,
            error: false,
          },
          mutation: {
            value: 0,
            error: false,
          },
          paymentAt: {
            value: null,
            error: false,
          },
        },
      ]);
    }
  };

  const handleRemoveForm = (i, type) => {
    if (type === 'CICIL') {
      let values = instalment;

      values.splice(i, 1);
      setInstalment([...values]);
    } else if (type === 'DOWN_PAYMENT') {
      let values = downPayment;

      values.splice(i, 1);
      setDownPayment([...values]);
    } else if (type === 'LOAN') {
      let values = loan;

      values.splice(i, 1);
      setLoan([...values]);
    } else {
      let values = nonCash;

      values.splice(i, 1);
      setNonCash([...values]);
    }
  };

  const calculateNominalNonCash = () => {
    const splitPayment = order?.order_payments?.length;
    let historyPayments = 0;

    if (splitPayment) {
      historyPayments = order?.order_payments?.reduce(
        (n, { payment_fee }) => Number(n) + Number(payment_fee),
        0,
      );
    }

    const currentPayment = nonCash.reduce(
      (n, { nominal }) => Number(n) + Number(nominal.value),
      0,
    );

    if (splitPayment) {
      return historyPayments + currentPayment + calculateDownPayment();
    }

    return currentPayment + calculateDownPayment();
  };

  const refund = () => {
    const sTotal = subTotal('NON_CASH');
    const platformFee = adminFee().admin;

    return sTotal - platformFee;
  };

  const totalNonCash = () => {
    const rfund = refund();
    const discount = order?.total_discount;

    return rfund + discount;
  };

  const adjustPaymentOrder = () => {
    adjustNominalOrder({
      variables: {
        oid: order?.oid,
      },
    });
  };

  const CashForm = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          {/* <Autocomplete
            id="taxType"
            freeSolo
            value={cash?.taxType}
            disabled={isDisabled}
            onChange={(e, value) => {
              const tax = value === 'Dibayar Customer' ? 0 : null;
              setCash((prev) => ({
                ...prev,
                taxType: value,
                tax,
              }));
              setCashValidation((prev) => ({
                ...prev,
                taxType: false,
              }));
            }}
            options={listOption.map((option) => option.name)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipe Pajak"
                value={cash.taxType}
                onChange={(e) => {
                  setCash((prev) => ({
                    ...prev,
                    taxType: e.target.value,
                  }));
                  setCashValidation((prev) => ({
                    ...prev,
                    taxType: false,
                  }));
                }}
              />
            )}
          />
          {cashValidation?.taxType && (
            <FormHelperText error>Harus diisi.</FormHelperText>
          )} */}
          <FormControl fullWidth>
            <InputLabel id='taxType'>Tipe Pajak</InputLabel>
            <Select
              labelId='taxType'
              id='taxType'
              name='taxType'
              label='Masa Pajak'
              value={cash?.taxType}
              disabled={isDisabled}
              onChange={(e) => {
                const tax = e.target.value === 'Dibayar Customer' ? 0 : null;
                setCash((prev) => ({
                  ...prev,
                  taxType: e.target.value,
                  tax,
                }));
                setCashValidation((prev) => ({
                  ...prev,
                  taxType: false,
                }));
              }}
            >
              <MenuItem disabled selected value=''>
                Pilih
              </MenuItem>
              {listOption.map((x) => (
                <MenuItem value={x.name} key={x.name}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
            {cashValidation?.taxType && (
              <FormHelperText error>Harus diisi.</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <FormControl fullWidth>
            <InputLabel id='taxPeriod'>Masa Pajak</InputLabel>
            <Select
              labelId='taxPeriod'
              id='taxPeriod'
              name='taxPeriod'
              label='Masa Pajak'
              value={cash?.taxPeriod}
              disabled={isDisabled}
              onChange={(e) => {
                setCash((prev) => ({
                  ...prev,
                  taxPeriod: e.target.value,
                }));
                setCashValidation((prev) => ({
                  ...prev,
                  taxPeriod: false,
                }));
              }}
            >
              <MenuItem disabled selected value=''>
                Pilih
              </MenuItem>
              {taxPeriod.map((x) => (
                <MenuItem value={x} key={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
            {cashValidation?.taxPeriod && (
              <FormHelperText error>Harus diisi.</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <NumberFormat
            key='tax'
            customInput={TextField}
            label='Total Pajak'
            variant='outlined'
            isNumericString
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix='Rp. '
            fullWidth
            name='tax'
            id='tax'
            disabled={isDisabled || cash?.taxType === 'Dibayar Customer'}
            value={cash.tax}
            error={cashValidation?.tax}
            helperText={cashValidation?.tax && 'Harus Diisi'}
            onValueChange={(values) => {
              const { floatValue } = values;
              setCash((prev) => ({
                ...prev,
                tax: floatValue ? floatValue : 0,
              }));
              setCashValidation((prev) => ({
                ...prev,
                tax: false,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <NumberFormat
            key='loan'
            customInput={TextField}
            label='Pinjaman'
            variant='outlined'
            isNumericString
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix='Rp. '
            fullWidth
            name='loan'
            id='loan'
            disabled={isDisabled}
            value={cash.loan}
            onValueChange={(values) => {
              const { floatValue } = values;
              setCash((prev) => ({
                ...prev,
                loan: floatValue,
              }));
              setCashValidation((prev) => ({
                ...prev,
                loan: false,
              }));
            }}
          />
          {cashValidation?.loan && (
            <FormHelperText error>Harus diisi.</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <TextField
            fullWidth
            id='totalSales'
            name='totalSales'
            label='Total Jual'
            type='text'
            value={`Rp. ${order?.total_sales.toLocaleString('id-ID')}`}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <TextField
            fullWidth
            id='subTotal'
            name='subTotal'
            label='Sub Total'
            type='text'
            value={`Rp. ${subTotal('CASH').toLocaleString('id-ID')}`}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <TextField
            fullWidth
            id='totalDiscount'
            name='totalDiscount'
            label='Total Diskon'
            type='text'
            value={`Rp. ${order?.total_discount?.toLocaleString('id-ID')}`}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <TextField
            fullWidth
            id='total'
            name='total'
            label='Total Tagihan'
            type='text'
            value={`Rp. ${changeAndTotal('CASH').toLocaleString('id-ID')}`}
            disabled
          />
        </Grid>
        {isDisabled && (
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <FormControl fullWidth>
              <InputLabel id='paymentMethod'>Metode Pembayaran</InputLabel>
              <Select
                labelId='paymentMethod'
                id='paymentMethod'
                name='paymentMethod'
                label='Metode Pembayaran'
                value={paymentMethod}
                disabled={isDone || order?.status === 'PENDING PAYMENT'}
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                <MenuItem disabled selected value=''>
                  Pilih
                </MenuItem>
                <MenuItem value='TUNAI'>TUNAI</MenuItem>
                <MenuItem value='CICIL'>CICIL</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {paymentMethod === 'TUNAI' && (
          <React.Fragment>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <NumberFormat
                key='totalPaymentFee_'
                customInput={TextField}
                label='Total Pembayaran'
                variant='outlined'
                isNumericString
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix='Rp. '
                fullWidth
                name='totalPaymentFee_'
                id='totalPaymentFee_'
                disabled={isDone}
                error={totalPaymentFee?.error}
                helperText={totalPaymentFee?.error && totalPaymentFee?.message}
                value={totalPaymentFee?.value}
                onValueChange={(values) => {
                  const { floatValue } = values;
                  setTotalPaymentFee({
                    value: floatValue,
                    error: false,
                    message: '',
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignContent: 'center',
                }}
              >
                <Button
                  onClick={() => handleConfirm(paymentMethod)}
                  variant='contained'
                  color='primary'
                  disabled={
                    isDone || totalPaymentFee?.value !== changeAndTotal('CASH')
                  }
                >
                  Konfirmasi Pembayaran
                </Button>
              </Box>
            </Grid>
          </React.Fragment>
        )}
        {paymentMethod === 'CICIL' && (
          <React.Fragment>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                fullWidth
                id='totalInstalment'
                name='totalInstalment'
                label='Total Pembayaran'
                type='text'
                value={`Rp. ${getTotalInstalments(
                  instalment,
                  order,
                ).toLocaleString('id-ID')}`}
                disabled
              />
            </Grid>
            {order?.order_payments?.length ? (
              <React.Fragment>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextField
                    fullWidth
                    id='insufficientPayment'
                    name='insufficientPayment'
                    label='Kekurangan Pembayaran'
                    type='text'
                    value={`Rp. ${(
                      order?.grand_total -
                      getTotalInstalments(instalment, order)
                    ).toLocaleString('id-ID')}`}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}></Grid>
              </React.Fragment>
            ) : (
              <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
            )}
          </React.Fragment>
        )}

        {/* HISTORY TUNAI CICILAN */}
        {paymentMethod === 'CICIL' &&
          order?.order_payments?.map((x, i) => (
            <React.Fragment>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  autoComplete={`historyNomina${i}`}
                  name={`historyNomina${i}`}
                  fullWidth
                  key={`historyNomina${i}`}
                  id={`historyNomina${i}`}
                  label={`Pembayaran ${i + 1}`}
                  disabled
                  placeholder={`Pembayaran ${i + 1}`}
                  value={`Rp. ${x?.payment_fee?.toLocaleString('id-ID')}`}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  autoComplete={`historyPaymentAt${i}`}
                  name={`historyPaymentAt${i}`}
                  fullWidth
                  key={`historyPaymentAt${i}`}
                  id={`historyPaymentAt${i}`}
                  label='Tanggal Input'
                  disabled
                  placeholder='Tanggal Input'
                  value={x?.payment_at}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
            </React.Fragment>
          ))}

        {paymentMethod === 'CICIL' &&
          order?.status !== 'DONE' &&
          role !== 'MONITORING' &&
          instalment.map((x, i) => (
            <React.Fragment>
              {i === 0 && (
                <React.Fragment>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <NumberFormat
                      key={`amount${i}`}
                      customInput={TextField}
                      label={`Total Pembayaran ${
                        i + 1 + order?.order_payments?.length
                      }`}
                      variant='outlined'
                      isNumericString
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix='Rp. '
                      fullWidth
                      name={`amount${i}`}
                      id={`amount${i}`}
                      value={x.amount?.value}
                      error={x.amount?.error}
                      helperText={x.amount.error && 'Harus Diisi'}
                      onValueChange={(values) => {
                        const { floatValue } = values;

                        let newArr = [...instalment];
                        newArr[i] = {
                          ...newArr[i],
                          amount: {
                            value: floatValue ? floatValue : 0,
                            error: false,
                          },
                        };

                        setInstalment([...newArr]);
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <TextField
                      fullWidth
                      id={`paymentAt${i}`}
                      name={`paymentAt${i}`}
                      label='Tanggal Pembayaran'
                      type='date'
                      value={x.paymentAt?.value}
                      error={x.paymentAt?.error}
                      helperText={x.paymentAt.error && 'Harus Diisi'}
                      onChange={(e) => {
                        let newArr = [...instalment];
                        newArr[i] = {
                          ...newArr[i],
                          paymentAt: { value: e.target.value, error: false },
                        };

                        setInstalment([...newArr]);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Button
                      variant='outlined'
                      onClick={() => handleAddForm('CICIL')}
                      sx={{ mt: 2, mr: 1 }}
                      disabled={nonCash.length > 1}
                    >
                      Tambah
                    </Button>
                    {i === instalment.length - 1 && (
                      <Button
                        variant='contained'
                        // onClick={() => handleConfirm(paymentMethod)}
                        onClick={handleProcessBillInstallment}
                        sx={{ mt: 2, mr: 1 }}
                        disabled={checkInstallmentCashMethod(
                          order?.grand_total,
                          getTotalInstalments(instalment, order),
                        )}
                      >
                        Simpan
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}></Grid>
                </React.Fragment>
              )}
              {i > 0 && (
                <React.Fragment>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <NumberFormat
                      key={`amount${i}`}
                      customInput={TextField}
                      label='Total Pembayaran'
                      variant='outlined'
                      isNumericString
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix='Rp. '
                      fullWidth
                      name={`amount${i}`}
                      id={`amount${i}`}
                      value={x.amount.value}
                      error={x.amount.error}
                      helperText={x.amount.error && 'Harus Diisi'}
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        let newArr = [...instalment];
                        newArr[i] = {
                          ...newArr[i],
                          amount: { value: floatValue, error: false },
                        };

                        setInstalment([...newArr]);
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <TextField
                      fullWidth
                      id={`paymentAt${i}`}
                      name={`paymentAt${i}`}
                      label='Tanggal Pembayaran'
                      type='date'
                      value={x.paymentAt.value}
                      error={x.paymentAt.error}
                      helperText={x.paymentAt.error && 'Harus Diisi'}
                      onValueChange={(e) => {
                        let newArr = [...instalment];
                        newArr[i] = {
                          ...newArr[i],
                          paymentAt: { value: e.target.value, error: false },
                        };

                        setInstalment([...newArr]);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Button
                      variant='outlined'
                      onClick={() => handleAddForm('CICIL')}
                      sx={{ mt: 2, mr: 1 }}
                      disabled={nonCash.length > 1}
                    >
                      Tambah
                    </Button>
                    <Button
                      variant='text'
                      onClick={() => handleRemoveForm(i, 'CICIL')}
                      sx={{ mt: 2, mr: 1 }}
                    >
                      Hapus
                    </Button>
                    {i === instalment.length - 1 && (
                      <Button
                        variant='contained'
                        // onClick={() => handleConfirm(paymentMethod)}
                        onClick={handleProcessBillInstallment}
                        sx={{ mt: 2, mr: 1 }}
                        disabled={checkInstallmentCashMethod(
                          order?.grand_total,
                          getTotalInstalments(instalment, order),
                        )}
                      >
                        Simpan
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
        {order?.status === 'WAITING INPUT BILL' && role !== 'MONITORING' && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                alignContent: 'center',
              }}
            >
              <Button
                onClick={handleProcessBillCash}
                variant='contained'
                color='primary'
              >
                Proses Tagihan
              </Button>
            </Box>
          </Grid>
        )}
      </React.Fragment>
    );
  };

  const NonCashForm = () => {
    return (
      <React.Fragment>
        {order?.order_payments?.map((x, i) => (
          <React.Fragment>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <TextField
                autoComplete={`historyReference${i}`}
                name={`historyReference${i}`}
                fullWidth
                key={`historyReference${i}`}
                id={`historyReference${i}`}
                label='No Referensi'
                disabled
                placeholder='No Referensi'
                value={x?.payment_reference}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <TextField
                autoComplete={`historyNominal${i}`}
                name={`historyNominal${i}`}
                fullWidth
                key={`historyNominal${i}`}
                id={`historyNominal${i}`}
                label='Nominal'
                disabled
                placeholder='Nominal'
                value={`Rp. ${x?.payment_fee?.toLocaleString('id-ID')}`}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <TextField
                autoComplete={`historyTax${i}`}
                name={`historyTax${i}`}
                fullWidth
                key={`historyTax${i}`}
                id={`historyTax${i}`}
                label='Pajak'
                disabled
                placeholder='Pajak'
                value={`Rp. ${x?.tax?.toLocaleString('id-ID')}`}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <TextField
                autoComplete={`historyMutation${i}`}
                name={`historyMutation${i}`}
                fullWidth
                key={`historyMutation${i}`}
                id={`historyMutation${i}`}
                label='Mutasi'
                disabled
                placeholder='Mutation'
                value={`Rp. ${x?.mutation?.toLocaleString('id-ID')}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Tooltip
                        title={`Selisih Rp. ${Number(
                          x.payment_fee - x.mutation,
                        ).toLocaleString('id-ID')}`}
                      >
                        <IconButton aria-label='expand row' size='small'>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <TextField
                autoComplete={`historyPaymentAt${i}`}
                name={`historyPaymentAt${i}`}
                fullWidth
                key={`historyPaymentAt${i}`}
                id={`historyPaymentAt${i}`}
                label='Tanggal Input'
                disabled
                placeholder='Tanggal Input'
                value={x?.payment_at}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} />
          </React.Fragment>
        ))}
        {/* DYNAMIC FORM SIPLAH */}
        {order?.status !== 'DONE' &&
          order?.status !== 'WAITING CASH' &&
          nonCash.map((x, i) => (
            <React.Fragment>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <TextField
                  autoComplete={`noReference${i}`}
                  name={`noReference${i}`}
                  fullWidth
                  id={`noReference${i}`}
                  label='No Referensi'
                  placeholder='No Referensi'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    let newArr = [...nonCash];
                    newArr[i] = {
                      ...newArr[i],
                      noReference: { value: e.target.value, error: false },
                    };

                    setNonCash([...newArr]);
                  }}
                  value={x.noReference.value}
                  error={x.noReference.error}
                  helperText={x.noReference.error && 'Harus Diisi'}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <NumberFormat
                  key={`nominal${i}`}
                  customInput={TextField}
                  label='Nominal'
                  variant='outlined'
                  isNumericString
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix='Rp. '
                  fullWidth
                  name={`nominal${i}`}
                  id={`nominal${i}`}
                  value={x.nominal.value}
                  error={x.nominal.error}
                  helperText={x.nominal.error && 'Harus Diisi'}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    let newArr = [...nonCash];
                    newArr[i] = {
                      ...newArr[i],
                      nominal: {
                        value: floatValue ? floatValue : 0,
                        error: false,
                      },
                    };

                    setNonCash([...newArr]);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <NumberFormat
                  key={`tax${i}`}
                  customInput={TextField}
                  label='Pajak'
                  variant='outlined'
                  isNumericString
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix='Rp. '
                  fullWidth
                  name={`tax${i}`}
                  id={`tax${i}`}
                  value={x.tax.value}
                  error={x.tax.error}
                  helperText={x.tax.error && 'Harus Diisi'}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    let newArr = [...nonCash];
                    newArr[i] = {
                      ...newArr[i],
                      tax: {
                        value: floatValue ? floatValue : 0,
                        error: false,
                      },
                    };

                    setNonCash([...newArr]);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <NumberFormat
                  key={`mutation${i}`}
                  customInput={TextField}
                  label='Mutasi'
                  variant='outlined'
                  isNumericString
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix='Rp. '
                  fullWidth
                  name={`mutation${i}`}
                  id={`mutation${i}`}
                  value={x.mutation.value}
                  allowLeadingZeros={false}
                  error={x.mutation.error}
                  helperText={x.mutation.error && 'Harus Diisi'}
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    let newArr = [...nonCash];
                    newArr[i] = {
                      ...newArr[i],
                      mutation: {
                        value: floatValue ? floatValue : 0,
                        error: false,
                      },
                    };
                    console.log('floatValue', floatValue);
                    console.log('values', values);
                    setNonCash([...newArr]);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Tooltip
                          title={`Selisih Rp. ${Number(
                            x.nominal?.value - x.mutation?.value,
                          ).toLocaleString('id-ID')}`}
                        >
                          <IconButton aria-label='expand row' size='small'>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <TextField
                  fullWidth
                  id={`paymentAt${i}`}
                  name={`paymentAt${i}`}
                  label='Tanggal Pembayaran'
                  type='date'
                  value={x.paymentAt?.value}
                  error={x.paymentAt?.error}
                  helperText={x.paymentAt.error && 'Harus Diisi'}
                  onChange={(e) => {
                    let newArr = [...nonCash];
                    newArr[i] = {
                      ...newArr[i],
                      paymentAt: { value: e.target.value, error: false },
                    };

                    setNonCash([...newArr]);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {i === 0 && (
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  {role !== 'MONITORING' && (
                    <Button
                      variant='outlined'
                      onClick={() => handleAddForm()}
                      sx={{ mt: 2, mr: 1 }}
                      disabled={nonCash.length > 1}
                      size='small'
                    >
                      Tambah
                    </Button>
                  )}
                </Grid>
              )}
              {i > 0 && (
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <Button
                    variant='outlined'
                    onClick={() => handleAddForm()}
                    sx={{ mt: 2, mr: 1 }}
                    size='small'
                  >
                    Tambah
                  </Button>
                  <Button
                    variant='text'
                    onClick={() => handleRemoveForm(i)}
                    sx={{ mt: 2, mr: 1 }}
                    size='small'
                  >
                    Hapus
                  </Button>
                </Grid>
              )}
            </React.Fragment>
          ))}
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <NumberFormat
            key='totalPaymentFee'
            customInput={TextField}
            label='Uang Masuk'
            variant='outlined'
            isNumericString
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix='Rp. '
            fullWidth
            name='totalPaymentFee'
            id='totalPaymentFee'
            disabled
            value={calculateNominalNonCash()}
            onValueChange={(values) => {
              const { floatValue } = values;
              setCash((prev) => ({
                ...prev,
                totalPaymentFee: floatValue,
              }));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip title={displayTextFee('UANG_MASUK')}>
                    <IconButton aria-label='expand row' size='small'>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <NumberFormat
            key='totalDownPayment'
            customInput={TextField}
            label='Down Payment'
            variant='outlined'
            isNumericString
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix='Rp. '
            fullWidth
            name='totalDownPayment'
            id='totalDownPayment'
            disabled
            value={calculateDownPayment()}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip title={'Info Down Payment'}>
                    <IconButton
                      aria-label='expand row'
                      size='small'
                      onClick={() => setOpenDownPayment(true)}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <NumberFormat
            key='loan'
            customInput={TextField}
            label='Pinjaman'
            variant='outlined'
            isNumericString
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix='Rp. '
            fullWidth
            name='loan'
            id='loan'
            disabled
            value={calculateLoan()}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip title={'Info Pinjaman'}>
                    <IconButton
                      aria-label='expand row'
                      size='small'
                      onClick={() => setOpenLoan(true)}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          {/* <Autocomplete
            id="taxType"
            freeSolo
            value={cash?.taxType}
            disabled={isDisabled}
            onChange={(e, value) => {
              const tax = value === 'Dibayar Customer' ? 0 : null;
              setCash((prev) => ({
                ...prev,
                taxType: value,
                tax,
              }));
              setNonCashValidation((prev) => ({
                ...prev,
                taxType: false,
              }));
            }}
            options={listOption.map((option) => option.name)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipe Pajak"
                value={cash.taxType}
                onChange={(e) => {
                  setCash((prev) => ({
                    ...prev,
                    taxType: e.target.value,
                  }));
                  setNonCashValidation((prev) => ({
                    ...prev,
                    taxType: false,
                  }));
                }}
              />
            )}
          />
          {nonCashValidation?.taxType && (
            <FormHelperText error>Harus diisi.</FormHelperText>
          )} */}
          <FormControl fullWidth>
            <InputLabel id='taxType'>Tipe Pajak</InputLabel>
            <Select
              labelId='taxType'
              id='taxType'
              name='taxType'
              label='Tipe Pajak'
              value={cash?.taxType}
              disabled={isDisabled}
              onChange={(e) => {
                const tax = e.target.value === 'Dibayar Customer' ? 0 : null;
                setCash((prev) => ({
                  ...prev,
                  taxType: e.target.value,
                  tax,
                }));
                setNonCashValidation((prev) => ({
                  ...prev,
                  taxType: false,
                }));
              }}
            >
              <MenuItem disabled selected value=''>
                Pilih
              </MenuItem>
              {listOption.map((x) => (
                <MenuItem value={x.name} key={x.name}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
            {nonCashValidation?.taxType && (
              <FormHelperText error>Harus diisi.</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <FormControl fullWidth>
            <InputLabel id='taxPeriod'>Masa Pajak</InputLabel>
            <Select
              labelId='taxPeriod'
              id='taxPeriod'
              name='taxPeriod'
              label='Masa Pajak'
              value={cash?.taxPeriod}
              disabled={isDisabled}
              onChange={(e) => {
                setCash((prev) => ({
                  ...prev,
                  taxPeriod: e.target.value,
                }));
                setCashValidation((prev) => ({
                  ...prev,
                  taxPeriod: false,
                }));
              }}
            >
              <MenuItem disabled selected value=''>
                Pilih
              </MenuItem>
              {taxPeriod.map((x) => (
                <MenuItem value={x} key={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
            {cashValidation?.taxPeriod && (
              <FormHelperText error>Harus diisi.</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          {/* <NumberFormat
            key='tax'
            customInput={TextField}
            label='Total Pajak'
            variant='outlined'
            isNumericString
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix='Rp. '
            fullWidth
            name='taxTotal'
            id='taxTotal'
            disabled={isDone || order?.status === 'WAITING CASH'}
            value={cash.tax}
            error={nonCashValidation?.tax}
            helperText={nonCashValidation?.tax && 'Harus Diisi'}
            onValueChange={(values) => {
              const { floatValue } = values;
              setCash((prev) => ({
                ...prev,
                tax: floatValue ? floatValue : 0,
              }));
              setNonCashValidation((prev) => ({
                ...prev,
                tax: false,
              }));
            }}
          /> */}
          <TextField
            fullWidth
            id='totalTax'
            name='totalTax'
            label='Total Pajak'
            type='text'
            value={`Rp. ${totalTax().toLocaleString('id-ID')}`}
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3}>
          <TextField
            fullWidth
            id='adminFee1'
            name='adminFee1'
            label='Platform Fee'
            type='text'
            value={`Rp. ${adminFee()?.admin?.toLocaleString('id-ID')}`}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip title={displayTextFee('PLATFORM')}>
                    <IconButton aria-label='expand row' size='small'>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <TextField
            fullWidth
            id='adminFee2'
            name='adminFee2'
            label='Expense Fee'
            type='text'
            value={`Rp. ${adminFee()?.tax?.toLocaleString('id-ID')}`}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip title={displayTextFee('EXPENSE')}>
                    <IconButton aria-label='expand row' size='small'>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <TextField
            fullWidth
            id='subTotal'
            name='subTotal'
            label='Sub Total'
            type='text'
            value={`Rp. ${subTotal('NON_CASH').toLocaleString('id-ID')}`}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip title={displayTextFee('SUB_TOTAL')}>
                    <IconButton aria-label='expand row' size='small'>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <TextField
            fullWidth
            id='totalSales'
            name='totalSales'
            label='Total Jual'
            type='text'
            value={`Rp. ${order?.total_sales.toLocaleString('id-ID')}`}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <TextField
            fullWidth
            id='refund'
            name='refund'
            label='Pengembalian'
            type='text'
            value={`Rp. ${refund()?.toLocaleString('id-ID')}`}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip title={displayTextFee('REFUND')}>
                    <IconButton aria-label='expand row' size='small'>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <TextField
            fullWidth
            id='total'
            name='total'
            label='Total'
            type='text'
            value={`Rp. ${totalNonCash()?.toLocaleString('id-ID')}`}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip title={displayTextFee('TOTAL')}>
                    <IconButton aria-label='expand row' size='small'>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {order?.status === 'WAITING CASH' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                alignContent: 'center',
              }}
            >
              <Button
                onClick={() => adjustPaymentOrder()}
                variant='outlined'
                color='primary'
              >
                Selesaikan Pesanan
              </Button>
            </Box>
          )}
          {showButtonNonCash(order?.status) && role !== 'MONITORING' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                alignContent: 'center',
              }}
            >
              <Button
                onClick={() => handleClickOpen('NON_CASH_PAY')}
                variant='contained'
                color='primary'
                disabled={checkBtnNonCash(
                  calculateNominalNonCash(),
                  order?.total_sales,
                )}
              >
                Terbitkan
              </Button>
              &nbsp;
              <Button
                onClick={() => handleClickOpen('NON_CASH_PENDING')}
                variant='outlined'
                color='primary'
                disabled={
                  !checkBtnNonCash(
                    calculateNominalNonCash(),
                    order?.total_sales,
                  )
                }
              >
                Simpan Sementara
              </Button>
            </Box>
          )}
          {order?.payment_type === 'TUNAI' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                alignContent: 'center',
              }}
            >
              <Button
                onClick={handleConfirm}
                variant='contained'
                color='primary'
                disabled={isDone}
              >
                Konfirmasi Pembayaran
              </Button>
            </Box>
          )}
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} xs={12} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <FormControl fullWidth>
            <InputLabel id='paymentType'>Tipe Pembayaran</InputLabel>
            <Select
              labelId='paymentType'
              id='paymentType'
              name='paymentType'
              label='Tipe Pembayaran'
              value={paymentType}
              onChange={(e) => setPaymentType(e.target.value)}
              disabled={isDisabled}
            >
              <MenuItem disabled selected value={null}>
                Pilih
              </MenuItem>
              <MenuItem value='TUNAI'>TUNAI</MenuItem>
              <MenuItem value='NON_TUNAI'>NON TUNAI</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {paymentType === 'TUNAI' && CashForm()}
      </Grid>
      <Grid container spacing={2} xs={12} sx={{ mb: 2 }}>
        {paymentType === 'NON_TUNAI' && NonCashForm()}
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Konfirmasi Tagihan</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Silahkan cek kembali isian Informasi Tagihan Anda. Setelah tombol
            Cetak Tagihan ditekan, data tidak dapat diubah.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Batal</Button>
          <Button variant='contained' onClick={handleSubmit} autoFocus>
            Cetak Tagihan
          </Button>
        </DialogActions>
      </Dialog>

      <React.Suspense fallback={<div></div>}>
        <DialogDownPayment
          open={openDownPayment}
          setOpen={setOpenDownPayment}
          downPayment={downPayment}
          setDownPayment={setDownPayment}
          handleAddForm={handleAddForm}
          handleRemoveForm={handleRemoveForm}
          order={order}
        />
      </React.Suspense>

      <React.Suspense fallback={<div></div>}>
        <DialogLoan
          open={openLoan}
          setOpen={setOpenLoan}
          loan={loan}
          setLoan={setLoan}
          handleAddForm={handleAddForm}
          handleRemoveForm={handleRemoveForm}
          order={order}
        />
      </React.Suspense>
    </React.Fragment>
  );
};

export default PaymentSection;
