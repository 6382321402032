import * as React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Container from '@mui/material/Container';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Autocomplete from '@mui/material/Autocomplete';
import NumberFormat from 'react-number-format';

import { EMPLOYEES } from '../../graphql/Employee';
import { CLIENTS } from '../../graphql/Client';
import { UNITS } from '../../graphql/Unit';
import { TYPES } from '../../graphql/Type';
import { CREATE_ORDER } from '../../graphql/Order';
import GlobalContext from '../../utils/GlobalContext';
import { generateArrayOfYears } from '../../utils';

export default function ListOrderForm() {
  const { setSnack, setGlobalLoading } = React.useContext(GlobalContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [employees, setEmployees] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [client, setClient] = React.useState({
    value: null,
    name: '',
    error: false,
  });
  const [employee, setEmployee] = React.useState({
    value: null,
    name: '',
    error: false,
  });
  const [units, setUnits] = React.useState([]);
  const [types, setTypes] = React.useState([]);
  const [quarterly, setQuarterly] = React.useState({ value: 1, error: false });
  const [company, setCompany] = React.useState({ value: '', error: false });
  const [year, setYear] = React.useState({
    value: new Date().getFullYear(),
    error: false,
  });
  const [products, setProducts] = React.useState([
    {
      id: 1,
      unit: {
        value: '',
        name: '',
        error: false,
      },
      type: {
        value: '',
        name: '',
        error: false,
      },
      name: {
        value: '',
        error: false,
      },
      qty: {
        value: 0,
        error: false,
      },
      budget: {
        value: 0,
        error: false,
      },
      description: '',
    },
  ]);
  const [validateName, setValidateName] = React.useState({
    required: true,
    validate: false,
  });
  const [isSubmit, setIsSubmit] = React.useState(false);
  let history = useHistory();
  const years = generateArrayOfYears();

  /* FETCH DATA EMPLOYEES */
  const { data: dataEmployees } = useQuery(EMPLOYEES, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  React.useEffect(() => {
    if (dataEmployees) {
      setEmployees(dataEmployees.employees);
    }
  }, [dataEmployees]);

  /* FETCH DATA CLIENTS */
  const { data: dataClients } = useQuery(CLIENTS, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  React.useEffect(() => {
    if (dataClients) {
      setClients(dataClients.clients);
    }
  }, [dataClients]);

  /* FETCH DATA UNITS */
  const { data: dataUnits } = useQuery(UNITS, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  /* FETCH DATA TYPES */
  const { data: dataTypes } = useQuery(TYPES, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  React.useEffect(() => {
    if (dataTypes) {
      setTypes(dataTypes.types);
    }
  }, [dataTypes]);

  React.useEffect(() => {
    if (dataUnits) {
      setUnits(dataUnits.units);
    }
  }, [dataUnits]);

  const [insertOrder] = useMutation(CREATE_ORDER, {
    onCompleted: (d) => {
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data Belanja berhasil disimpan',
        opened: true,
      });
      setGlobalLoading(false);
      history.push(`/admin/data_order/${d.createOrder.oid}`);
    },
    onError: (e) => {
      console.log('error insert order: ', e);
      let message = 'Data Belanja gagal disimpan';
      setIsSubmit(false);

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const handleNext = () => {
    if (!client?.value || !employee?.value || !company?.value) {
      if (!client?.value) {
        setClient({ value: null, name: '', error: true });
      }

      if (!employee?.value) {
        setEmployee({ value: null, name: '', error: true });
      }

      if (!company?.value) {
        setCompany({ value: null, name: '', error: true });
      }

      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleAddForm = (type) => {
    if (type === 'products') {
      const oldProducts = products;
      setProducts([
        ...oldProducts,
        {
          id: oldProducts.length + 1,
          unit: {
            value: '',
            error: false,
          },
          type: {
            value: '',
            error: false,
          },
          name: {
            value: '',
            error: false,
          },
          qty: {
            value: 0,
            error: false,
          },
          budget: {
            value: 0,
            error: false,
          },
          description: '',
        },
      ]);
    }
  };

  const handleRemoveForm = (type, i) => {
    if (type === 'products') {
      let values = products;

      values.splice(i, 1);
      setProducts([...values]);
    }
  };

  const handleCheckForm = () => {
    const check = products.map((x) => {
      if (!x.name.value) {
        x.name.error = true;
      }

      if (!x.unit.value) {
        x.unit.error = true;
      }

      if (!x.type.value) {
        x.type.error = true;
      }

      if (!x.qty.value) {
        x.qty.error = true;
      }

      // if (!x.budget.value) {
      //   x.budget.error = true;
      // }

      return x;
    });

    const notValidate = check.filter(
      (y) => y.name.error || y.unit.error || y.type.error || y.qty.error
      // y.budget.error
    );

    if (notValidate.length) {
      setProducts([...check]);
      return;
    }

    handleNext();
  };

  const handleSubmit = (status) => {
    setIsSubmit(true);
    setGlobalLoading(true);
    const total_budget = products.reduce(
      (n, { budget, qty }) =>
        Number(n) + Number(budget.value) * Number(qty.value),
      0
    );
    const order_products = products.map((x) => ({
      unit_id: x.unit?.value,
      type_id: x.type?.value,
      name: x.name?.value,
      budget: ~~x.budget?.value,
      qty: ~~x.qty?.value,
      description: x.description,
    }));

    const total_discount = 0;
    const data = {
      client_id: client.value,
      employee_id: employee.value,
      company: company.value,
      quarterly: quarterly.value,
      total_budget,
      status,
      total_discount,
      order_products,
      year: year.value,
    };

    insertOrder({
      variables: { data },
    });
  };

  const formProducts = () => {
    return products.map((x, i) => {
      return (
        <React.Fragment>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              autoComplete={`name_${i}`}
              name={`name_${i}`}
              fullWidth
              id={`name_${i}`}
              label="Nama Produk"
              placeholder="Nama Produk"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                let newArr = [...products];
                newArr[i] = {
                  ...newArr[i],
                  name: { value: e.target.value, error: false },
                };

                setProducts([...newArr]);
              }}
              value={x.name.value}
              error={x.name.error}
              helperText={x.name.error && 'Harus Diisi'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <FormControl fullWidth>
              <InputLabel id={`unit_${i}`}>Satuan</InputLabel>
              <Select
                labelId={`unit_${i}`}
                id={`unit_${i}`}
                name={`unit_${i}`}
                label="Satuan"
                onChange={(e) => {
                  let newArr = [...products];
                  const unitName = units.find(
                    (x) => x.id === e.target.value
                  )?.name;
                  newArr[i] = {
                    ...newArr[i],
                    unit: {
                      value: e.target.value,
                      error: false,
                      name: unitName,
                    },
                  };

                  setProducts([...newArr]);
                }}
                value={x.unit.value}
                error={x.unit.error}
              >
                <MenuItem disabled selected value={''}>
                  Pilih
                </MenuItem>
                {units.map((x) => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
              {x.unit.error && (
                <FormHelperText error>Harus diisi.</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <FormControl fullWidth>
              <InputLabel id={`type_${i}`}>Jenis</InputLabel>
              <Select
                labelId={`type_${i}`}
                id={`type_${i}`}
                name={`type_${i}`}
                label="Satuan"
                onChange={(e) => {
                  let newArr = [...products];
                  const typeName = types.find(
                    (x) => x.id === e.target.value
                  )?.name;
                  newArr[i] = {
                    ...newArr[i],
                    type: {
                      value: e.target.value,
                      error: false,
                      name: typeName,
                    },
                  };

                  setProducts([...newArr]);
                }}
                value={x.type.value}
                error={x.type.error}
              >
                <MenuItem disabled selected value={''}>
                  Pilih
                </MenuItem>
                {types.map((x) => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
              {x.type.error && (
                <FormHelperText error>Harus diisi.</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <NumberFormat
              customInput={TextField}
              label="Anggaran"
              variant="outlined"
              isNumericString
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix="Rp. "
              name={`budget${i}`}
              fullWidth
              id={`budget${i}`}
              value={x.budget.value}
              error={x.budget.error}
              helperText={x.budget.error && 'Harus Diisi'}
              onValueChange={(values) => {
                const { floatValue } = values;
                // handleChangeForm(floatValue, index, 'base_price');
                let newArr = [...products];
                newArr[i] = {
                  ...newArr[i],
                  budget: { value: floatValue, error: false },
                };

                setProducts([...newArr]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <TextField
              autoComplete={`qty${i}`}
              name={`qty${i}`}
              fullWidth
              id={`qty${i}`}
              label="Jumlah"
              type="number"
              placeholder="Jumlah"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                let newArr = [...products];
                newArr[i] = {
                  ...newArr[i],
                  qty: { value: e.target.value, error: false },
                };

                setProducts([...newArr]);
              }}
              value={x.qty.value}
              error={x.qty.error}
              helperText={x.qty.error && 'Harus Diisi'}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={10} lg={10}>
            <TextField
              autoComplete={`description_${i}`}
              name={`description_${i}`}
              fullWidth
              id={`description_${i}`}
              label="Deskripsi Produk"
              placeholder="Deskripsi Produk"
              multiline
              rows={3}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                let newArr = [...products];
                newArr[i] = { ...newArr[i], description: e.target.value };

                setProducts([...newArr]);
                setValidateName({ required: true, validate: false });
              }}
              value={x.description}
              error={validateName.required && validateName.validate}
              helperText={
                validateName.required && validateName.validate && 'Harus Diisi'
              }
            />
          </Grid>
          {i === 0 && (
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <Button
                variant="outlined"
                onClick={() => handleAddForm('products')}
                sx={{ mt: 2, mr: 1 }}
                disabled={products.length > 1}
              >
                Tambah
              </Button>
            </Grid>
          )}
          {i > 0 && (
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <Button
                variant="outlined"
                onClick={() => handleAddForm('products')}
                sx={{ mt: 2, mr: 1 }}
              >
                Tambah
              </Button>
              <Button
                variant="text"
                onClick={() => handleRemoveForm('products', i)}
                sx={{ mt: 2, mr: 1 }}
              >
                Hapus
              </Button>
            </Grid>
          )}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </React.Fragment>
      );
    });
  };

  const steps = [
    {
      label: 'Data Pelanggan',
      description: (
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Autocomplete
                disableClearable
                options={clients.map((option) => option.name)}
                value={client?.name}
                onInputChange={(_, v) => {
                  const client = clients.find((x) => x.name === v);

                  if (client) {
                    setClient({
                      value: client.id,
                      error: false,
                      name: v,
                    });
                  }
                }}
                onClick
                renderInput={(params) => (
                  <TextField {...params} label="Nama Pelanggan" />
                )}
              />
              {client?.error && (
                <FormHelperText error>Harus diisi.</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <FormControl fullWidth>
                <InputLabel id="employee">PIC</InputLabel>
                <Select
                  labelId="employee"
                  id="employee"
                  name="employee"
                  label="PIC"
                  value={employee?.value}
                  onChange={(e) => {
                    const { name } = employees.find(
                      (x) => x.id === e.target.value
                    );
                    setEmployee({ value: e.target.value, error: false, name });
                  }}
                >
                  <MenuItem disabled selected value={null}>
                    Pilih
                  </MenuItem>
                  {employees.map((x) => (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
                {employee?.error && (
                  <FormHelperText error>Harus diisi.</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <FormControl fullWidth>
                <InputLabel id="company">Perusahaan</InputLabel>
                <Select
                  labelId="company"
                  id="company"
                  name="company"
                  label="Perusahaan"
                  value={company?.value}
                  onChange={(e) =>
                    setCompany({ value: e.target.value, error: false })
                  }
                >
                  <MenuItem disabled selected value="">
                    Pilih
                  </MenuItem>
                  <MenuItem value="GJI">Global Jaya Indo</MenuItem>
                  <MenuItem value="GJA">Ganesha Jaya Artha</MenuItem>
                </Select>
                {company?.error && (
                  <FormHelperText error>Harus diisi.</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <FormControl fullWidth>
                <InputLabel id="quarter">Cawu</InputLabel>
                <Select
                  labelId="quarter"
                  id="quarter"
                  name="quarter"
                  label="Cawu"
                  value={quarterly?.value}
                  onChange={(e) =>
                    setQuarterly({ value: e.target.value, error: true })
                  }
                >
                  <MenuItem disabled selected value={null}>
                    Pilih
                  </MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <FormControl fullWidth>
                <InputLabel id="year">Tahun</InputLabel>
                <Select
                  labelId="year"
                  id="year"
                  name="year"
                  label="Tahun"
                  value={year?.value}
                  onChange={(e) =>
                    setYear({ value: e.target.value, error: true })
                  }
                >
                  {years.map((x, i) => (
                    <MenuItem key={i} value={x}>
                      {x}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            onClick={handleNext}
            sx={{ mt: 1, mr: 1 }}
          >
            Lanjut
          </Button>
        </React.Fragment>
      ),
    },
    {
      label: 'Data Belanja',
      description: (
        <React.Fragment>
          <Grid container spacing={2}>
            {formProducts()}
          </Grid>
          <Button
            variant="contained"
            onClick={handleCheckForm}
            sx={{ mt: 2, mr: 1 }}
          >
            Lanjut
          </Button>
          <Button onClick={handleBack} sx={{ mt: 2, mr: 1 }}>
            Kembali
          </Button>
        </React.Fragment>
      ),
    },
    {
      label: 'Konfirmasi',
      description: (
        <React.Fragment>
          <Typography variant="subtitle2">PIC: {employee?.name}</Typography>
          <Typography variant="subtitle2">
            Perusahaan:{' '}
            {company?.value === 'GJI'
              ? 'CV Global Jaya Indo'
              : 'CV Ganesha Jaya Arta'}
          </Typography>
          <Typography variant="subtitle2">Pelanggan: {client?.name}</Typography>
          <Typography variant="subtitle2">Tahun: {year?.value}</Typography>
          <Typography variant="subtitle2">Cawu: {quarterly?.value}</Typography>
          <Typography variant="subtitle2">
            Total Anggaran: Rp.{' '}
            {products
              .reduce(
                (n, { budget, qty }) =>
                  Number(n) + Number(budget.value) * Number(qty.value),
                0
              )
              .toLocaleString('id-ID')}
          </Typography>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nomor</TableCell>
                  <TableCell>Nama Produk</TableCell>
                  <TableCell>Satuan</TableCell>
                  <TableCell>Jenis</TableCell>
                  <TableCell>Anggaran</TableCell>
                  <TableCell>Jumlah</TableCell>
                  <TableCell>Deskripsi</TableCell>
                  <TableCell>Subtotal</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell>{row.name?.value}</TableCell>
                    <TableCell>{row.unit?.name}</TableCell>
                    <TableCell>{row.type?.name}</TableCell>
                    <TableCell>
                      Rp. {Number(row.budget?.value).toLocaleString('id-ID')}
                    </TableCell>
                    <TableCell>{row.qty?.value}</TableCell>
                    <TableCell>{row.description || '-'}</TableCell>
                    <TableCell>
                      Rp.{' '}
                      {(row.qty.value * row.budget.value).toLocaleString(
                        'id-ID'
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={() => handleSubmit('NEW')}
            variant="contained"
            sx={{ mt: 2, mr: 1 }}
            disabled={isSubmit}
          >
            Simpan
          </Button>
          <Button onClick={handleBack} sx={{ mt: 2, mr: 1 }}>
            Kembali
          </Button>
        </React.Fragment>
      ),
    },
  ];

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 4,
          display: 'block',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 2 ? (
                    <Typography variant="caption">Data Order</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </Box>
    </Container>
  );
}
