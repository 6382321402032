import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const headTable = () => {
  return [
    {
      name: 'Nama Produk',
      selling_price: 'Harga',
      qty: 'Jumlah',
      sub_total: 'Sub Total',
    },
  ];
};

const OrderReportPDF = (data) => {
  let doc = new jsPDF({
    putOnlyUsedFonts: true,
    orientation: 'portrait',
    format: [210, 330],
  });

  // const result = data.map((x) => ({
  //   content: x.client?.name,
  //   colSpan: 4,
  //   styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
  // }));
  const fieldPayment = ['name', 'selling_price', 'qty', 'sub_total'];

  const result = data
    .filter((a) => a.status === 'DONE')
    .map((x) => {
      const aa = x.order_products?.map((y) => {
        const obj = fieldPayment.map((z) => {
          const child = {};

          if (z === 'selling_price' || z === 'sub_total') {
            child.content = `Rp. ${y[z]?.toLocaleString('id-ID')}`;
          } else {
            child.content = y[z];
          }

          return child;
        });

        return obj;
      });

      return [
        [
          {
            content: x.client?.name,
            colSpan: 2,
            styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: `Total: Rp. ${x.total_sales.toLocaleString('id-ID')}`,
            colSpan: 2,
            styles: { halign: 'right', valign: 'middle', fontStyle: 'bold' },
          },
        ],
        ...aa,
      ];
    });
  // []
  // var pageWidth =
  //   doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  // COMPANY_NAME
  doc.setFontSize(13);
  doc.setFont('times', 'bold');
  doc.setFont('times', 'normal');

  doc.setFontSize(10);
  doc.setFont('times', 'bold');
  doc.text(`Cawu: ${data[0].quarterly}`, 10, 10);

  autoTable(doc, {
    startY: 18,
    theme: 'grid',
    headStyles: { fillColor: [47, 84, 150], textColor: 'white' },
    head: headTable(),
    body: [...result.flat()],
    columnWidth: 'wrap',
    tableWidth: 'auto',
    styles: {
      overflow: 'linebreak',
      overflowColumns: 'linebreak',
      textColor: 'black',
    },
  });

  doc.setProperties({
    title: `Purchase Order `,
  });

  window.open(
    doc.output('bloburl', {
      filename: `Purchase Order O.pdf`,
    })
  );

  // doc.save('Nota.pdf');
};

export default OrderReportPDF;
