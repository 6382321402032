import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import * as yup from 'yup';
import NumberFormat from 'react-number-format';
import dayjs from 'dayjs';

const validationSchema = yup.object({
  contractValue: yup.number().required('Nilai Kontrak harus diisi'),
});

const RentalEdit = (props) => {
  const { open, setOpen, handleSubmit, data, setData } = props;
  console.log('data', data);
  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      contractDate: dayjs(data?.contract_date_origin).format('YYYY-MM-DD'),
      contractPeriode: data?.contract_periode,
      contractValue: data?.contract_value,
      freePrintable: data?.free_printable,
      pricePerPrintable: data?.price_per_printable,
      tax: data?.tax,
      installationFee: data?.installation_fee,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values);
      // resetForm();
    },
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Edit Kontrak</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <Box
              sx={{
                '& > :not(style)': { m: 1 },
              }}
            >
              <Grid container spacing={2} sx={{ pb: 3 }}>
                <Grid xs={12} container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id='contractDate'
                      name='contractDate'
                      label='Tanggal Kontrak'
                      type='date'
                      value={formik.values.contractDate}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.contractDate &&
                        Boolean(formik.errors.contractDate)
                      }
                      helperText={
                        formik.touched.contractDate &&
                        formik.errors.contractDate
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id='company'>Masa Kontrak</InputLabel>
                      <Select
                        labelId='contractPeriode'
                        id='contractPeriode'
                        name='contractPeriode'
                        label='Masa Kontrak'
                        value={formik.values.contractPeriode}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.contractPeriode &&
                          Boolean(formik.errors.contractPeriode)
                        }
                        helperText={
                          formik.touched.contractPeriode &&
                          formik.errors.contractPeriode
                        }
                      >
                        <MenuItem disabled selected value=''>
                          Pilih
                        </MenuItem>
                        <MenuItem value={6}>6 Bulan</MenuItem>
                        <MenuItem value={12}>12 Bulan</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id='endOfContract'
                      name='endOfContract'
                      label='Tanggal Berakhir Kontrak'
                      type='date'
                      value={dayjs(formik.values.contractDate)
                        .add(formik.values.contractPeriode, 'months')
                        .format('YYYY-MM-DD')}
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberFormat
                      customInput={TextField}
                      label='Nilai Kontrak'
                      variant='outlined'
                      isNumericString
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix='Rp. '
                      name='contractValue'
                      fullWidth
                      id='contractValue'
                      value={formik.values.contractValue}
                      error={
                        formik.touched.contractValue &&
                        Boolean(formik.errors.contractValue)
                      }
                      helperText={
                        formik.touched.contractValue &&
                        formik.errors.contractValue
                      }
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        formik.setFieldValue('contractValue', floatValue);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      id='freePrintable'
                      name='freePrintable'
                      type='number'
                      label='Free Cetak'
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      onChange={formik.handleChange}
                      value={formik.values.freePrintable}
                      error={
                        formik.touched.contractValue &&
                        Boolean(formik.errors.contractValue)
                      }
                      helperText={
                        formik.touched.contractValue &&
                        formik.errors.contractValue
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberFormat
                      customInput={TextField}
                      label='Nilai Kontrak'
                      variant='outlined'
                      isNumericString
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix='Rp. '
                      name='pricePerPrintable'
                      fullWidth
                      id='pricePerPrintable'
                      value={formik.values.pricePerPrintable}
                      error={
                        formik.touched.pricePerPrintable &&
                        Boolean(formik.errors.pricePerPrintable)
                      }
                      helperText={
                        formik.touched.pricePerPrintable &&
                        formik.errors.pricePerPrintable
                      }
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        formik.setFieldValue('pricePerPrintable', floatValue);
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel id='tax'>PPN 11%</InputLabel>
                      <Select
                        labelId='tax'
                        id='tax'
                        name='tax'
                        label='PPN 11%'
                        value={formik.values.tax}
                        onChange={formik.handleChange}
                      >
                        <MenuItem disabled selected value=''>
                          Pilih
                        </MenuItem>
                        <MenuItem value={true}>Ya</MenuItem>
                        <MenuItem value={false}>Tidak</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel id='installationFee'>PPN 11%</InputLabel>
                      <Select
                        labelId='installationFee'
                        id='installationFee'
                        name='installationFee'
                        label='PPN 11%'
                        value={formik.values.installationFee}
                        onChange={formik.handleChange}
                      >
                        <MenuItem disabled selected value=''>
                          Pilih
                        </MenuItem>
                        <MenuItem value={true}>Ya</MenuItem>
                        <MenuItem value={false}>Tidak</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={handleClose}>
              Batal
            </Button>
            <Button variant='contained' type='submit'>
              Edit Kontrak
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default RentalEdit;
