import { gql } from '@apollo/client';

export const EMPLOYEES = gql`
  query employees {
    employees {
      id
      eid
      name
      phone
      email
      role
      created_at
      updated_at
    }
  }
`;

export const SEARCH_EMPLOYEE = gql`
  query searchEmployees($name: String) {
    searchEmployees(name: $name) {
      id
      eid
      name
      phone
      email
      role
      created_at
      updated_at
    }
  }
`;

export const CREATE_EMPLOYEE = gql`
  mutation createEmployee($data: InEmployee!) {
    createEmployee(data: $data) {
      id
      eid
      name
      phone
      email
      role
      created_at
      updated_at
    }
  }
`;

export const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee($eid: String!, $data: InEmployee!) {
    updateEmployee(eid: $eid, data: $data) {
      id
      eid
      name
      phone
      email
      role
      created_at
      updated_at
    }
  }
`;
