import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import { useQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as yup from 'yup';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { EMPLOYEES } from '../../graphql/Employee';
import { CREATE_EXTERNAL_ORDER } from '../../graphql/ExternalOrder';
import { CLIENTS } from '../../graphql/Client';
import MyBreadcrumbs from '../MyBreadcrumbs';
import GlobalContext from '../../utils/GlobalContext';

const validationSchema = yup.object({
  employee_id: yup.string().required('Marketing harus diisi'),
  client_id: yup.string().required('Pelanggan harus diisi'),
  quarterly: yup.number().required('Cawu harus diisi'),
  total_sales: yup.number().required('Nominal Pembelanjaan harus diisi'),
  // tax: yup.number().required('Pajak harus diisi'),
  mutation_date: yup.string().required('Tanggal Mutasi harus diisi'),
  type: yup.string().required('Tipe harus diisi'),
  company: yup.string().required('Perusahaan harus diisi'),
  additional: yup.boolean(),
  additional_fee_percentage: yup
    .number()
    .when('additional', {
      is: true,
      then: yup.number().min(1, 'Persentase minimal 1%'),
    })
    .max(100, 'Persentase minimal 100%'),
});

const ExternalOrderFormNew = () => {
  const { setSnack, setGlobalLoading } = React.useContext(GlobalContext);
  const [employees, setEmployees] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [invoices, setInvoices] = React.useState([
    {
      id: 1,
      noReference: {
        value: '',
        error: false,
      },
      nominal: {
        value: 0,
        error: false,
      },
      tax: {
        value: 0,
        error: false,
      },
      transactionAt: {
        value: '',
        error: false,
      },
    },
  ]);

  /* FETCH DATA EMPLOYEES */
  const { data: dataEmployees } = useQuery(EMPLOYEES, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  React.useEffect(() => {
    if (dataEmployees) {
      setEmployees(dataEmployees.employees);
    }
  }, [dataEmployees]);

  /* FETCH DATA CLIENTS */
  const { data: dataClients } = useQuery(CLIENTS, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  React.useEffect(() => {
    if (dataClients) {
      setClients(dataClients.clients);
    }
  }, [dataClients]);

  const [insertExternalOrder] = useMutation(CREATE_EXTERNAL_ORDER, {
    onCompleted: (d) => {
      // const data = externalOrder;
      // const baseData = baseExternalOrder;
      // setExternalOrder([...data, d.createExternalOrder]);
      // setBaseExternalOrder([...baseData, d.createExternalOrder]);

      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data Nota berhasil disimpan',
        opened: true,
      });
      setGlobalLoading(false);
    },
    onError: (e) => {
      console.log('error insert external order: ', e);
      let message = 'Data Data Nota gagal disimpan';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const formik = useFormik({
    initialValues: {
      employee_id: '',
      client_id: '',
      client_name: '',
      quarterly: '',
      total_sales: 0,
      tax: 0,
      type: '',
      mutation_date: '',
      company: '',
      additional: false,
      additional_fee: 0,
      additional_fee_percentage: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const check = invoices.map((x) => {
        if (!x.noReference.value) {
          x.noReference.error = true;
        }

        if (!x.nominal.value) {
          x.nominal.error = true;
        }

        if (!x.transactionAt.value) {
          x.transactionAt.error = true;
        }

        return x;
      });

      const notValidate = check.filter(
        (y) => y.noReference.error || y.nominal.error || y.transactionAt.error,
      );

      if (notValidate.length) {
        setInvoices([...check]);

        return;
      }

      const fee = calculateChangeAndFee();
      debugger
      const externalOrderDetails = invoices.map((x) => ({
        no_reference: x.noReference?.value,
        nominal: x.nominal?.value,
        tax: x.tax?.value,
        transaction_at: x.transactionAt?.value,
      }));

      const data = {
        client_id: values.client_id,
        employee_id: values.employee_id,
        quarterly: values.quarterly,
        type: values.type,
        total_payment_fee: calculateNominal(),
        total_sales: values.total_sales,
        tax: calculateTax(),
        admin_fee: fee.platform,
        tax_admin_fee: fee.expense,
        refund: fee.change,
        mutation_at: values.mutation_date,
        external_order_details: externalOrderDetails,
        company: values.company,
        additional: values?.additional,
        additional_fee: calculateChangeAndFee().additional_fee,
      };

      insertExternalOrder({
        variables: { data },
      });

      resetForm();
      setInvoices([
        {
          id: 1,
          noReference: {
            value: '',
            error: false,
          },
          nominal: {
            value: 0,
            error: false,
          },
          tax: {
            value: 0,
            error: false,
          },
          transactionAt: {
            value: '',
            error: false,
          },
        },
      ]);
    },
  });

  const handleAddForm = () => {
    const oldProducts = invoices;
    setInvoices([
      ...oldProducts,
      {
        id: oldProducts.length + 1,
        noReference: {
          value: '',
          error: false,
        },
        nominal: {
          value: 0,
          error: false,
        },
        tax: {
          value: 0,
          error: false,
        },
        transactionAt: {
          value: '',
          error: false,
        },
      },
    ]);
  };

  const handleRemoveForm = (i) => {
    let values = invoices;

    values.splice(i, 1);
    setInvoices([...values]);
  };

  const calculateNominal = () => {
    return invoices.reduce(
      (n, { nominal }) => Number(n) + Number(nominal.value),
      0,
    );
  };

  const calculateChangeAndFee = () => {
    const totalNominal = calculateNominal();
    const nominalTransaction = formik.values.total_sales;
    const tax = calculateTax();
    const result = {
      change: totalNominal - nominalTransaction - tax,
    };
    const totalPercentage = ((nominalTransaction + tax) / totalNominal) * 100;

    if (totalPercentage >= 40) {
      result.expense = 0;
      result.platform = 0;
      result.additional_fee = 0;
    } else {
      // result.platform = Math.round(result.change * 0.03 * 100) / 100;
      // result.expense = Math.round(result.change * 0.025 * 100) / 100;
      // CHANGE FORM 2.5% to 2% GOVERNMENT POLICY & SIPLAH
      // CHANGE 13-03-2023 FROM 0.03 TO 0.05
      result.platform = Math.floor(result.change * 0.05);
      result.expense = Math.floor(result.change * 0.02);

      if (formik?.values?.additional) {
        result.additional_fee = Math.floor(
          result.change * (formik?.values?.additional_fee_percentage / 100),
        );
        result.change = result.change - result.platform - result.additional_fee;
      } else {
        result.additional_fee = 0;
        result.change = result.change - result.platform;
      }
    }

    return result;
  };

  const calculateTax = () => {
    return invoices.reduce(
      (n, { tax }) => Number(n) + Number(tax.value),
      0,
    );
  }

  const FormInvoices = () => {
    return invoices.map((x, i) => {
      return (
        <>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              autoComplete={`noReference${i}`}
              name={`noReference${i}`}
              fullWidth
              id={`noReference${i}`}
              label='No Referensi'
              placeholder='No Referensi'
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                let newArr = [...invoices];
                newArr[i] = {
                  ...newArr[i],
                  noReference: { value: e.target.value, error: false },
                };

                setInvoices([...newArr]);
              }}
              value={x.noReference.value}
              error={x.noReference.error}
              helperText={x.noReference.error && 'Harus Diisi'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <NumberFormat
              key={`nominal${i}`}
              customInput={TextField}
              label='Nominal'
              variant='outlined'
              isNumericString
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix='Rp. '
              fullWidth
              name={`nominal${i}`}
              id={`nominal${i}`}
              value={x.nominal.value}
              error={x.nominal.error}
              helperText={x.nominal.error && 'Harus Diisi'}
              onValueChange={(values) => {
                const { floatValue } = values;
                let newArr = [...invoices];
                newArr[i] = {
                  ...newArr[i],
                  nominal: { value: floatValue || 0, error: false },
                };

                setInvoices([...newArr]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <NumberFormat
              key={`tax${i}`}
              customInput={TextField}
              label='Pajak'
              variant='outlined'
              isNumericString
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix='Rp. '
              fullWidth
              name={`tax${i}`}
              id={`tax${i}`}
              value={x.tax.value}
              error={x.tax.error}
              helperText={x.tax.error && 'Harus Diisi'}
              onValueChange={(values) => {
                const { floatValue } = values;
                let newArr = [...invoices];
                newArr[i] = {
                  ...newArr[i],
                  tax: { value: floatValue || 0, error: false },
                };

                setInvoices([...newArr]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <TextField
              fullWidth
              id={`transactionAt${i}`}
              name={`transactionAt${i}`}
              label='Tanggal Transaksi'
              type='date'
              value={x.transactionAt.value}
              error={x.transactionAt.error}
              helperText={x.transactionAt.error && 'Harus Diisi'}
              onChange={(e) => {
                let newArr = [...invoices];
                newArr[i] = {
                  ...newArr[i],
                  transactionAt: { value: e.target.value, error: false },
                };

                setInvoices([...newArr]);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {i === 0 && (
            <Grid item xs={2} sm={2} md={1} lg={1}>
              <Button
                variant='outlined'
                onClick={() => handleAddForm()}
                sx={{ mt: 2, mr: 1 }}
                disabled={invoices.length > 1}
                size='small'
              >
                Tambah
              </Button>
            </Grid>
          )}
          {i > 0 && (
            <Grid item xs={2} sm={2} md={1} lg={1}>
              <Button
                variant='outlined'
                onClick={() => handleAddForm()}
                size='small'
              >
                Tambah
              </Button>
              <Button
                variant='text'
                onClick={() => handleRemoveForm(i)}
                size='small'
              >
                Hapus
              </Button>
            </Grid>
          )}
        </>
      );
    });
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <MyBreadcrumbs data={['Nota', 'Tambah Data']} />
        </Box>
      </Box>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item xs={12} sm={12} md={10} lg={10} spacing={2}>
          <form onSubmit={formik.handleSubmit}>
            {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}> */}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Autocomplete
                  disableClearable
                  options={clients.map((option) => option.name)}
                  id='client_id'
                  name='client_id'
                  label='Marketing'
                  autoFocus
                  value={formik.values.client_name}
                  onInputChange={(_, v) => {
                    const client = clients.find((x) => x.name === v);

                    if (client) {
                      formik.setFieldValue('client_id', client.id);
                      formik.setFieldValue('client_name', client.name);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label='Nama Pelanggan' />
                  )}
                />
                {formik.touched.client_id &&
                Boolean(formik.errors.client_id) ? (
                  <FormHelperText error>
                    {formik.errors.client_id}
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <FormControl fullWidth>
                  <InputLabel id='employee_id'>Marketing</InputLabel>
                  <Select
                    labelId='employee_id'
                    id='employee_id'
                    name='employee_id'
                    label='Marketing'
                    value={formik.values.employee_id}
                    onChange={formik.handleChange}
                  >
                    <MenuItem disabled selected value={''}>
                      Pilih
                    </MenuItem>
                    {employees?.map((x) => (
                      <MenuItem key={x.id} value={x.id}>
                        {x.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.employee_id &&
                  Boolean(formik.errors.employee_id) ? (
                    <p
                      style={{
                        color: '#d32f2f',
                        fontSize: '0.75rem',
                        margin: '3px 14px 0 14px',
                        textAlign: 'left',
                        fontWeight: 400,
                      }}
                    >
                      {formik.errors.employee_id}
                    </p>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id='company'>Perusahaan</InputLabel>
                  <Select
                    labelId='company'
                    id='company'
                    name='company'
                    label='Perusahaan'
                    value={formik.values.company}
                    onChange={formik.handleChange}
                  >
                    <MenuItem disabled selected value=''>
                      Pilih
                    </MenuItem>
                    <MenuItem value='GJI'>Global Jaya Indo</MenuItem>
                    <MenuItem value='GJA'>Ganesha Jaya Artha</MenuItem>
                  </Select>
                  {formik.touched.company && Boolean(formik.errors.company) ? (
                    <p
                      style={{
                        color: '#d32f2f',
                        fontSize: '0.75rem',
                        margin: '3px 14px 0 14px',
                        textAlign: 'left',
                        fontWeight: 400,
                      }}
                    >
                      {formik.errors.company}
                    </p>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <FormControl fullWidth>
                  <InputLabel id='quarterly'>Cawu</InputLabel>
                  <Select
                    labelId='quarterly'
                    id='quarterly'
                    name='quarterly'
                    label='Cawu'
                    value={formik.values.quarterly}
                    onChange={formik.handleChange}
                  >
                    <MenuItem disabled selected value=''>
                      Pilih
                    </MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                  </Select>
                  {formik.touched.quarterly &&
                  Boolean(formik.errors.quarterly) ? (
                    <p
                      style={{
                        color: '#d32f2f',
                        fontSize: '0.75rem',
                        margin: '3px 14px 0 14px',
                        textAlign: 'left',
                        fontWeight: 400,
                      }}
                    >
                      {formik.errors.quarterly}
                    </p>
                  ) : null}
                </FormControl>
              </Grid>
              {FormInvoices()}
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <NumberFormat
                  key='totalNominal'
                  customInput={TextField}
                  label='Uang Masuk'
                  variant='outlined'
                  isNumericString
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix='Rp. '
                  fullWidth
                  name='totalNominal'
                  id='totalNominal'
                  value={calculateNominal()}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <NumberFormat
                  key='total_sales'
                  customInput={TextField}
                  label='Nominal Pembelanjaan'
                  variant='outlined'
                  isNumericString
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix='Rp. '
                  fullWidth
                  id='total_sales'
                  name='total_sales'
                  value={formik.values.total_sales}
                  error={
                    formik.touched.total_sales &&
                    Boolean(formik.errors.total_sales)
                  }
                  helperText={
                    formik.touched.total_sales && formik.errors.total_sales
                  }
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    formik.setFieldValue(
                      'total_sales',
                      floatValue ? floatValue : 0,
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <NumberFormat
                  key='tax'
                  customInput={TextField}
                  label='Total Pajak'
                  variant='outlined'
                  isNumericString
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix='Rp. '
                  fullWidth
                  id='tax'
                  name='tax'
                  value={calculateTax()}
                  disabled
                  // error={formik.touched.tax && Boolean(formik.errors.tax)}
                  // helperText={formik.touched.tax && formik.errors.tax}
                  // onValueChange={(values) => {
                  //   const { floatValue } = values;
                  //   formik.setFieldValue('tax', floatValue ? floatValue : 0);
                  // }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  fullWidth
                  id='mutation_date'
                  name='mutation_date'
                  label='Tanggal Mutasi'
                  type='date'
                  value={formik.values.mutation_date}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.mutation_date &&
                    Boolean(formik.errors.mutation_date)
                  }
                  helperText={
                    formik.touched.mutation_date && formik.errors.mutation_date
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id='type'>Tipe</InputLabel>
                  <Select
                    labelId='type'
                    id='type'
                    name='type'
                    label='Tipe'
                    value={formik.values.type}
                    onChange={formik.handleChange}
                  >
                    <MenuItem disabled selected value=''>
                      Pilih
                    </MenuItem>
                    <MenuItem value='SIPLAH'>SIPLAH</MenuItem>
                    <MenuItem value='BOSDA'>BOSDA</MenuItem>
                    <MenuItem value='OFFLINE'>OFFLINE</MenuItem>
                  </Select>
                  {formik.touched.type && Boolean(formik.errors.type) ? (
                    <p
                      style={{
                        color: '#d32f2f',
                        fontSize: '0.75rem',
                        margin: '3px 14px 0 14px',
                        textAlign: 'left',
                        fontWeight: 400,
                      }}
                    >
                      {formik.errors.type}
                    </p>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id='additional'>Extra Fee</InputLabel>
                  <Select
                    labelId='additional'
                    id='additional'
                    name='additional'
                    label='Extra Fee'
                    value={formik.values.additional}
                    onChange={formik.handleChange}
                  >
                    <MenuItem disabled selected value=''>
                      Pilih
                    </MenuItem>
                    <MenuItem value={true}>Ya</MenuItem>
                    <MenuItem value={false}>Tidak</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {formik?.values?.additional && (
                <React.Fragment>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      autoComplete='additional_fee_percentage'
                      name='additional_fee_percentage'
                      fullWidth
                      id='additional_fee_percentage'
                      label='Fee Persentase'
                      placeholder='Fee Persentase'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={formik.handleChange}
                      type='number'
                      error={
                        formik.touched.additional &&
                        Boolean(formik.values.additional)
                      }
                      helperText={
                        formik.touched.additional_fee_percentage &&
                        formik.errors.additional_fee_percentage
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <NumberFormat
                      customInput={TextField}
                      label='Additional Fee'
                      variant='outlined'
                      isNumericString
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix='Rp. '
                      fullWidth
                      name='additional_fee'
                      id='additional_fee'
                      value={calculateChangeAndFee().additional_fee}
                      disabled
                    />
                  </Grid>
                </React.Fragment>
              )}
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <NumberFormat
                  customInput={TextField}
                  label='Platform Fee'
                  variant='outlined'
                  isNumericString
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix='Rp. '
                  fullWidth
                  name='plaformFee'
                  id='plaformFee'
                  value={calculateChangeAndFee().platform}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <NumberFormat
                  customInput={TextField}
                  label='Expense Fee'
                  variant='outlined'
                  isNumericString
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix='Rp. '
                  fullWidth
                  name='expenseFee'
                  id='expenseFee'
                  value={calculateChangeAndFee().expense}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <NumberFormat
                  customInput={TextField}
                  label='Kembalian'
                  variant='outlined'
                  isNumericString
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix='Rp. '
                  fullWidth
                  name='change'
                  id='change'
                  value={calculateChangeAndFee().change}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Button
                  component={Link}
                  to={'/admin/external_order/'}
                  variant='outlined'
                  size='small'
                >
                  Kembali
                </Button>
                &emsp;
                <Button variant='contained' type='submit' size='small'>
                  Simpan
                </Button>
              </Grid>
            </Grid>
          </form>

          {/* </Paper> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ExternalOrderFormNew;
