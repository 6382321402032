/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import Collapse from '@mui/material/Collapse';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import InputAdornment from '@mui/material/InputAdornment';
import MenuList from '@mui/material/MenuList';
import Autocomplete from '@mui/material/Autocomplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';

import {
  ORDER_BY_OID,
  CREATE_TRAVEL_DOC,
  UPDATE_ORDER,
  UPDATE_INVOICE_DATE,
  ADD_ADDITIONAL_ITEMS,
  DELETE_ORDER,
} from '../../graphql/Order';
import { SEARCH_PRODUCT } from '../../graphql/Product';
import GlobalContext from '../../utils/GlobalContext';
import {
  generateReferenceDocument,
  checkDisabledStatusOrder,
  selectedStatusItems,
  checkStatusOrder,
  chipColor,
  isShowBill,
  orderDocuments,
  getExtraBasePrice,
  displayEstimateProfit,
} from '../../utils';
import TravelDocument from '../PDF/TravelDocument';
import NewInvoicePDF from '../PDF/NewInvoicePDF';
import InformationProductPDF from '../PDF/InformationProductPDF';
import PurchaseOrderDocument from '../PDF/PurchaseOrderDocument';
import { Tooltip } from '@mui/material';
import PaymentSection from './PaymentSection';
const TravelDocumentForm = React.lazy(() =>
  import('../PDF/TravelDocumentForm'),
);
const DialogAdditionalItem = React.lazy(() => import('./DialogAdditionalItem'));

const DetailDataOrderPage = (props) => {
  const { oid } = props?.match?.params;
  const { role } = props;
  let history = useHistory();

  const { setSnack, setGlobalLoading } = React.useContext(GlobalContext);
  const [order, setOrder] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [paymentSection, setPaymentSection] = React.useState(false);
  const [openDetail, setOpenDetail] = React.useState({});
  const [traveDocsData, setTraveDocsData] = React.useState({
    driver_name: '',
    driver_phone: '',
    shipped_with: '',
    licence_plat: '',
  });
  const options = orderDocuments(order);
  const anchorRef = React.useRef(null);
  const [openButton, setOpenButton] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [checked, setChecked] = React.useState(false);
  const [dialogAddItem, setDialogAddItem] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  // const [isChecked, setIsChecked] = React.useState([]);
  const [isChecked, setIsChecked] = React.useState([]);
  const [tempData, setTempData] = React.useState([
    {
      id: 1,
      unit: {
        value: '',
        name: '',
        error: false,
      },
      type: {
        value: '',
        name: '',
        error: false,
      },
      name: {
        value: '',
        error: false,
      },
      qty: {
        value: 0,
        error: false,
      },
      budget: {
        value: 0,
        error: false,
      },
      description: '',
    },
  ]);
  const [percentage, setPercentage] = React.useState(0);

  const isCheckboxChecked = (index, id, checked) => {
    setIsChecked((isChecked) => {
      return isChecked.map((c, i) => {
        if (i === index) {
          if (!c) {
            return id;
          }

          return false;
        }
        return c;

        // if (c) return false;
        // return id;
      });
    });
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClick = () => {
    if (options[selectedIndex] === 'Cetak Rencana Belanja') {
      PurchaseOrderDocument(order);
    }

    if (options[selectedIndex] === 'Cetak Surat Jalan') {
      setOpen(true);
    }

    if (options[selectedIndex] === 'Cetak Tagihan') {
      const printed_at = new Date();
      updateInvoiceDate({
        variables: { oid: order.oid, printed_at },
      });

      // InvoicePDF(order, printed_at);
      NewInvoicePDF(order, printed_at);
    }

    if (options[selectedIndex] === 'Cetak Tagihan Custom') {
      const printed_at = new Date();
      updateInvoiceDate({
        variables: { oid: order.oid, printed_at },
      });

      // InvoicePDF(order, printed_at);
      NewInvoicePDF(order, printed_at, true);
    }

    if (options[selectedIndex] === 'Tambah Item Rencana Belanja') {
      setDialogAddItem(true);
    }

    if (options[selectedIndex] === 'Cetak Informasi Produk') {
      const printed_at = new Date();
      InformationProductPDF(order, printed_at);
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpenButton(false);
  };

  const handleToggle = () => {
    setOpenButton((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenButton(false);
  };

  /* FETCH DATA PRODUCTS */
  const {
    loading: loadOrder,
    data: dataOrder,
    refetch,
  } = useQuery(ORDER_BY_OID, {
    variables: { oid },
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  React.useEffect(() => {
    if (loadOrder) {
      setGlobalLoading(true);
    }
  }, []);

  React.useEffect(() => {
    if (dataOrder) {
      setOrder(dataOrder.findOrderByOid);

      const isPayment = isShowBill(dataOrder.findOrderByOid?.order_products);

      setPaymentSection(isPayment);
      setChecked(dataOrder.findOrderByOid?.is_custom);
      setIsChecked(
        dataOrder?.findOrderByOid?.order_products?.map((i) =>
          i.printed ? null : i.printed,
        ),
      );
      setGlobalLoading(false);
    }
  }, [dataOrder]);

  const handleChangeStatus = (id, whichValue, value) => {
    const data = { ...order };

    const index = data.order_products.findIndex((x) => x.id === id);
    if (index !== -1) {
      let temporaryarray = data.order_products.slice();
      temporaryarray[index][whichValue] = value;
      data.order_products = temporaryarray;

      setOrder({ ...data });
    } else {
      console.log('no match');
    }
  };

  /* CREATE SURAT JALAN */
  /* MUTATE DATA */
  const [insertTravelDocument] = useMutation(CREATE_TRAVEL_DOC, {
    onCompleted: (d) => {
      const data = order;
      const result = { ...data, travel_document: d.createTravelDocument };
      setOrder({ ...result });
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data Surat Jalan berhasil disimpan',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);

      TravelDocument(
        result,
        isChecked.filter((p) => p),
      );
      refetch();
    },
    onError: (e) => {
      console.log('error insert unit: ', e);
      let message = 'Data satuan gagal disimpan';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  /* UPDATE ORDER */
  const [updateOrder] = useMutation(UPDATE_ORDER, {
    onCompleted: (d) => {
      const data = order;

      setOrder({ ...data });
      setSnack({
        variant: 'success',
        message: 'Data Belanja berhasil diperbaruhi',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);
    },
    onError: (e) => {
      console.log('error insert unit: ', e);
      let message = e.message || 'Data Belanja gagal diperbaruhi';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  /* UPDATE ORDER */
  const [deleteOrder] = useMutation(DELETE_ORDER, {
    onCompleted: (d) => {
      setSnack({
        variant: 'success',
        message: 'Data Belanja berhasil dihapus',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);
      history.push('/admin/data_order');
    },
    onError: (e) => {
      console.log('error insert unit: ', e);
      let message = e.message || 'Data Belanja gagal dihapus';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  /* UPDATE INVOICE DATE */
  const [updateInvoiceDate] = useMutation(UPDATE_INVOICE_DATE, {
    onCompleted: (d) => {
      const result = { ...d.updateInvoiceOrderDate };
      setOrder(result);
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data cetak tagihan berhasil disimpan',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);
    },
    onError: (e) => {
      console.log('error update invoice: ', e);
      let message = 'Data cetak tagihan berhasil ';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  /* HANDLE ADD ITEMS ORDER */
  const [addItems] = useMutation(ADD_ADDITIONAL_ITEMS, {
    onCompleted: (d) => {
      const data = order;

      setOrder({ ...data });
      setSnack({
        variant: 'success',
        message: 'Data Belanja berhasil diperbaruhi',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);
    },
    onError: (e) => {
      console.log('error insert unit: ', e);
      let message = e.message || 'Data Belanja gagal diperbaruhi';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  // SUBMIT SURAT JALAN
  const handleSubmit = () => {
    const reference_number = generateReferenceDocument(order, 'SURAT_JALAN');
    const data = {
      order_id: order.id,
      reference_number,
      ...traveDocsData,
      document_products: isChecked.filter((o) => o),
    };

    insertTravelDocument({
      variables: { data },
    });
  };

  const handleChangeForm = (e, index, column) => {
    const newObj = { ...order };
    let newObjProducts = [...order.order_products];

    newObjProducts[index] = {
      ...newObjProducts[index],
      [column]: Number(e),
    };
    newObj.order_products = newObjProducts;

    setOrder({ ...newObj });
  };

  const debounce2 = (func) => {
    let timer;

    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };
  const optimizedFn2 = React.useCallback(debounce2(handleChangeForm), [order]);

  const handleUpdate = async () => {
    setGlobalLoading(true);
    let total_base_price = 0;
    let total_sales = 0;
    let total_profit = 0;
    let total_discount = 0;
    let total_custom_sales = 0;
    let total_extra_base_price = 0;
    let total_delivery_fee = 0;
    const statusOrder = checkStatusOrder(order.order_products);

    const data = {
      id: order.id,
      client_id: order.client_id,
      employee_id: order.employee_id,
      company: order.company,
      quarterly: order.quarterly,
      status: statusOrder,
      order_products: order.order_products
        ?.filter((y) => !['BATAL', 'RETUR'].includes(y.status))
        .map((x) => {
          const obj = {};
          obj.id = x.id;
          obj.base_price = x.base_price;
          obj.extra_base_price = x.base_price * 0.01;
          obj.total_extra_base_price = obj.base_price + obj.extra_base_price;
          obj.delivery_fee = x.delivery_fee | 0;

          const subTotalBasePrice = obj.total_extra_base_price;

          obj.selling_price = x.selling_price;
          obj.sub_total = x.selling_price * x.qty;
          obj.discount = x.discount | 0;
          obj.profit =
            (x.selling_price - subTotalBasePrice - obj.discount) * x.qty -
            obj.delivery_fee;
          obj.profit_percentage = Math.floor(
            ((x.selling_price -
              subTotalBasePrice -
              obj.discount -
              obj.delivery_fee) /
              subTotalBasePrice) *
              100,
          );

          obj.status = x.status;

          total_base_price += x.base_price * x.qty;
          total_sales += obj.sub_total;
          total_profit += obj.profit;
          total_discount += obj.discount * x.qty;
          total_extra_base_price += Math.floor(
            total_base_price * 0.01 + total_base_price,
          );
          total_delivery_fee += obj.delivery_fee;

          return obj;
        }),
      is_custom: checked,
    };

    data.total_base_price = total_base_price;
    data.total_sales = total_sales;
    data.total_profit = total_profit;
    data.total_discount = total_discount;
    data.total_custom_sales = total_custom_sales;
    data.total_extra_base_price = total_extra_base_price;
    data.total_delivery_fee = total_delivery_fee;
    debugger;
    if (statusOrder === 'CANCEL') {
      deleteOrder({ variables: { id: order.id } });
    } else {
      updateOrder({ variables: { id: order.id, data } });
    }
  };

  const handleCustomInvoice = async () => {
    setGlobalLoading(true);
    let total_base_price = 0;
    let total_sales = 0;
    let total_profit = 0;
    let total_discount = 0;
    let total_custom_sales = 0;
    let total_extra_base_price = 0;
    let total_delivery_fee = 0;

    const data = {
      id: order.id,
      client_id: order.client_id,
      employee_id: order.employee_id,
      company: order.company,
      quarterly: order.quarterly,
      status: 'DONE',
      order_products: order.order_products
        ?.filter((y) => !['BATAL', 'RETUR'].includes(y.status))
        .map((x) => {
          const obj = {};
          obj.id = x.id;
          obj.base_price = x.base_price;
          obj.extra_base_price = x.base_price * 0.01;
          obj.total_extra_base_price = obj.base_price + obj.extra_base_price;
          obj.delivery_fee = x.delivery_fee | 0;

          const subTotalBasePrice = obj.total_extra_base_price;

          obj.selling_price = x.selling_price;
          obj.sub_total = x.selling_price * x.qty;
          obj.discount = x.discount | 0;
          obj.profit =
            (x.selling_price - subTotalBasePrice - obj.discount) * x.qty -
            obj.delivery_fee;
          obj.profit_percentage = Math.floor(
            ((x.selling_price -
              subTotalBasePrice -
              obj.discount -
              obj.delivery_fee) /
              subTotalBasePrice) *
              100,
          );
          obj.status = x.status;

          if (checked) {
            obj.markup_price = x.markup_price | 0;
            obj.markuped = checked;
            total_custom_sales += obj.markup_price * x.qty;
          }

          total_base_price += x.base_price * x.qty;
          total_sales += obj.sub_total;
          total_profit += obj.profit;
          total_discount += obj.discount * x.qty;
          total_extra_base_price += Math.floor(
            total_base_price * 0.01 + total_base_price,
          );
          total_delivery_fee += obj.delivery_fee;

          return obj;
        }),
      is_custom: checked,
    };

    data.total_base_price = total_base_price;
    data.total_sales = total_sales;
    data.total_profit = total_profit;
    data.total_discount = total_discount;
    data.total_custom_sales = total_custom_sales;
    data.total_extra_base_price = total_extra_base_price;
    data.total_delivery_fee = total_delivery_fee;

    updateOrder({
      variables: { id: order.id, data },
    });
  };

  const handleCloseDialogItems = () => {
    setDialogAddItem(false);
  };

  const handleAddItems = async (rawData) => {
    const check = rawData.map((x) => {
      if (!x.name.value) {
        x.name.error = true;
      }

      if (!x.unit.value) {
        x.unit.error = true;
      }

      if (!x.type.value) {
        x.type.error = true;
      }

      if (!x.qty.value) {
        x.qty.error = true;
      }

      // if (!x.budget.value) {
      //   x.budget.error = true;
      // }

      return x;
    });

    const notValidate = check.filter(
      (y) => y.name.error || y.unit.error || y.type.error || y.qty.error,
      // y.budget.error
    );

    if (notValidate.length) {
      setTempData([...check]);
      return false;
    }

    const data = {
      oid: order?.oid,
      data: rawData.map((x) => ({
        unit_id: x?.unit?.value,
        type_id: x?.type?.value,
        name: x?.name?.value,
        qty: Number(x?.qty?.value),
        budget: x?.budget?.value,
        description: x?.description,
        status: 'NEW',
        base_price: 0,
        delivery_fee: 0,
        discount: 0,
        sub_total: 0,
      })),
    };

    addItems({
      variables: data,
    });
    handleCloseDialogItems(true);
    setTempData([
      {
        id: 1,
        unit: {
          value: '',
          name: '',
          error: false,
        },
        type: {
          value: '',
          name: '',
          error: false,
        },
        name: {
          value: '',
          error: false,
        },
        qty: {
          value: 0,
          error: false,
        },
        budget: {
          value: 0,
          error: false,
        },
        description: '',
      },
    ]);
  };

  const doSearch = async (name) => {
    const data = await getProducts({
      variables: { name },
    });

    setProducts([...data.data?.searchProducts]);
  };

  const [getProducts] = useLazyQuery(SEARCH_PRODUCT, {
    fetchPolicy: 'network-only',
  });

  const debounce = (func) => {
    let timer;

    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };

  const handeP = async (a) => {
    if (a) doSearch(a);
    else return;
  };

  const optimizedFn = React.useCallback(debounce(handeP), []);

  const handleCheckAll = (status) => {
    const data = { ...order };

    const order_products = data.order_products.map((x) => {
      const obj = { ...x };

      if (status === 'TERBELI') {
        if (x.status === 'NEW' && x.selling_price) {
          obj.status = status;
        }
      } else if (status === 'TERKIRIM') {
        if (x.status === 'TERBELI' && x?.previousStatus === 'TERBELI') {
          obj.status = status;
        }
      }
      return obj;
    });

    data.order_products = order_products;
    setOrder({ ...data });
  };

  const handleUpdatePrice = () => {
    const orderProducts = order?.order_products?.map((x) => {
      const obj = { ...x };
      const _percentage = 1 + percentage / 100;
      debugger;
      if (x?.status === 'NEW') {
        const price = Math.round(x?.base_price * _percentage);
        obj.selling_price = price;
      }

      return obj;
    });

    const _order = { ...order, order_products: orderProducts };

    setOrder({ ..._order });
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant='h6' mb={2} gutterBottom>
            Rincian Pembelanjaan &nbsp;
            <Chip
              label={order?.company}
              variant='outlined'
              color={order?.company === 'GJI' ? 'primary' : 'success'}
              size='small'
            />
            &nbsp;
            {order?.travel_document && (
              <Tooltip
                title={`Tanggal Cetak: ${order?.travel_document?.created_at}`}
              >
                <Chip
                  variant='outlined'
                  color='info'
                  size='small'
                  label='Surat Jalan'
                  icon={<CheckIcon />}
                />
              </Tooltip>
            )}
            &nbsp;
            {order?.payment_type === 'TUNAI' && (
              <Tooltip title={`Tanggal Cetak: ${order?.printed_at}`}>
                <Chip
                  variant='outlined'
                  color='info'
                  size='small'
                  label='Tagihan'
                  icon={<CheckIcon />}
                />
              </Tooltip>
            )}
            &nbsp;
            {order?.payment_type === 'NON_TUNAI' &&
              order?.order_payments?.length && (
                <Tooltip title={`Tanggal Cetak: ${order?.printed_at}`}>
                  <Chip
                    variant='outlined'
                    color='info'
                    size='small'
                    label='Tagihan'
                    icon={<CheckIcon />}
                  />
                </Tooltip>
              )}
            &nbsp;
            <Chip
              label={order?.status}
              variant='filled'
              color={chipColor(order?.status)}
              size='small'
            />
          </Typography>
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
          }}
        >
          <Button
            component={Link}
            to={'/admin/data_order'}
            variant='text'
            size='small'
          >
            Kembali
          </Button>{' '}
          &nbsp;
          {order?.status !== 'CANCEL' && (
            <ButtonGroup
              variant='outlined'
              ref={anchorRef}
              aria-label='split button'
            >
              <Button
                size='small'
                onClick={handleClick}
                // disabled={order?.status === 'NEW'}
              >
                {options[selectedIndex]}
              </Button>
              <Button
                size='small'
                aria-controls={openButton ? 'split-button-menu' : undefined}
                aria-expanded={openButton ? 'true' : undefined}
                aria-label='select merge strategy'
                aria-haspopup='menu'
                onClick={handleToggle}
                // disabled={order?.status === 'NEW'}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
          )}
          <Popper
            open={openButton}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 1000000 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id='split-button-menu' autoFocusItem>
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            Nama Pelanggan
          </Typography>
          <Typography variant='body2'>{order.client?.name}</Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            Nama PIC
          </Typography>
          <Typography variant='body2'>{order.employee?.name}</Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            Cawu
          </Typography>
          <Typography variant='body2'>{order.quarterly}</Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            Tahun
          </Typography>
          <Typography variant='body2'>{order.year}</Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            Total Item
          </Typography>
          <Typography variant='body2'>
            {order.order_products?.length} Item
          </Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            Total Ongkir
          </Typography>
          <Typography variant='body2'>
            Rp. {order.total_delivery_fee?.toLocaleString('id-ID')}
          </Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            <Tooltip
              title={`Harga Dasar + 1%: Rp. ${order.total_extra_base_price?.toLocaleString(
                'id-ID',
              )}`}
              placement='top-start'
            >
              Total Harga Dasar
            </Tooltip>
          </Typography>
          <Typography variant='body2'>
            Rp. {order.total_base_price?.toLocaleString('id-ID')}
          </Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            Total Diskon
          </Typography>
          <Typography variant='body2'>
            Rp. {order.total_discount?.toLocaleString('id-ID')}
          </Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            Total Jual
          </Typography>
          <Typography variant='body2'>
            Rp. {order.total_sales?.toLocaleString('id-ID')}
          </Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 2 }}>
          <Typography variant='caption' style={{ fontWeight: 'bold' }}>
            <Tooltip
              // PROFI PER ITEM title='Rumus: ((Harga Jual - Harga Dasar + 1% - Diskon) x Jumlah) - Ongkir'
              title='Rumus: Total Jual - Total Harga Dasar 1% - Total Ongkir - Total Diskon'
              placement='top-start'
            >
              Total Laba
            </Tooltip>
          </Typography>
          <Typography variant='body2'>
            Rp. {order?.total_profit?.toLocaleString('id-ID')}
          </Typography>
        </Box>
        {(order?.status === 'DONE' || order?.status === 'WAITING CASH') && (
          <Box sx={{ mb: 3 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  size='small'
                />
              }
              label='Harga Custom'
              sx={{ fontSize: '10px' }}
            />
          </Box>
        )}
      </Box>
      {checked && (
        <Box
          sx={{
            display: 'flex',
            bgcolor: 'background.paper',
            borderRadius: 1,
            alignItems: 'center',
          }}
        >
          <Box sx={{ mb: 3, mr: 2 }}>
            <Typography variant='caption' style={{ fontWeight: 'bold' }}>
              Total Jual Custom
            </Typography>
            <Typography variant='body2'>
              Rp. {order.total_custom_sales?.toLocaleString('id-ID')}
            </Typography>
          </Box>
          <Box sx={{ mb: 3, mr: 2 }}>
            <Typography variant='caption' style={{ fontWeight: 'bold' }}>
              Selisih
            </Typography>
            <Typography variant='body2'>
              Rp.{' '}
              {(order.total_custom_sales - order.total_sales)?.toLocaleString(
                'id-ID',
              )}
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Box sx={{ mb: 3, mr: 3, width: `calc(35%)` }}>
          <Autocomplete
            disablePortal
            id='combo-box-demo'
            options={products}
            getOptionLabel={(option) =>
              `${option.name} - Rp. ${option.selling_price?.toLocaleString(
                'id-ID',
              )}`
            }
            renderInput={(params) => (
              <TextField {...params} label='Cari Barang ...' />
            )}
            onInputChange={(e) => optimizedFn(e.target.value)}
            disabledItemsFocusable
          />
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <TextField
            fullWidth
            id='percentage'
            name='percentage'
            type='number'
            label='Mark Up Harga Jual (%)'
            value={percentage}
            onChange={(e) => setPercentage(Number(e?.target?.value))}
            inputProps={{
              min: 0,
            }}
          />
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Button variant='text' onClick={handleUpdatePrice}>
            Terapkan
          </Button>
        </Box>
      </Box>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                Informasi Produk
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {['NEW', 'PROCESS'].includes(order?.status) && (
                <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                  <Box>
                    <Button
                      onClick={() => handleCheckAll('TERBELI')}
                      variant='outlined'
                    >
                      Tandai semua terbeli
                    </Button>
                    &nbsp;
                    <Button
                      onClick={() => handleCheckAll('TERKIRIM')}
                      variant='outlined'
                    >
                      Tandai semua terkirim
                    </Button>
                  </Box>
                </Box>
              )}
              <TableContainer sx={{ maxHeight: 600 }}>
                <Table id='userTable' aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell
                        align='left'
                        style={{
                          position: 'sticky',
                          left: 0,
                          background: 'white',
                          zIndex: 800,
                          minWidth: '200px',
                        }}
                      >
                        Nama Produk
                      </TableCell>
                      <TableCell>Deskripsi</TableCell>
                      <TableCell>Jumlah</TableCell>
                      <TableCell>Satuan</TableCell>
                      <TableCell>Jenis</TableCell>
                      <TableCell
                        sx={{
                          minWidth: '175px',
                        }}
                      >
                        Anggaran
                      </TableCell>
                      <TableCell>Harga Dasar</TableCell>
                      <TableCell>Harga Dasar + 1%</TableCell>
                      <TableCell>Ongkir</TableCell>
                      <TableCell>Harga Jual</TableCell>
                      {checked && <TableCell>Harga Custom</TableCell>}
                      <TableCell>Diskon</TableCell>
                      <TableCell
                        sx={{
                          minWidth: '150px',
                        }}
                      >
                        Status
                      </TableCell>
                      {['NEW', 'PROCESS'].includes(order?.status) && (
                        <TableCell>Cetak</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order?.order_products?.map((row, index) => (
                      <>
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                          <TableCell>
                            {/* {row.status !== 'NEW' && ( */}
                            {row.previousStatus !== 'NEW' && (
                              <Tooltip title='Detail Produk'>
                                <IconButton
                                  aria-label='expand row'
                                  size='small'
                                  onClick={() =>
                                    setOpenDetail((prev) => ({
                                      ...prev,
                                      [row.id]: !prev[row.id],
                                    }))
                                  }
                                >
                                  {openDetail[row.id] ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell
                            align='left'
                            style={{
                              position: 'sticky',
                              left: 0,
                              background: 'white',
                              zIndex: 800,
                            }}
                            component='th'
                            scope='row'
                          >
                            {row.name}
                          </TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell>{row.qty}</TableCell>
                          <TableCell>{row.unit?.name}</TableCell>
                          <TableCell>{row.type?.name}</TableCell>
                          <TableCell>
                            Rp. {row.budget?.toLocaleString('id-ID')}
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              customInput={TextField}
                              variant='outlined'
                              isNumericString
                              sx={{ width: 150 }}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              disabled={checkDisabledStatusOrder(row.status)}
                              prefix='Rp. '
                              name={`base_price${index}`}
                              id={`base_price${index}`}
                              value={row.base_price}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                optimizedFn2(floatValue, index, 'base_price');
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              customInput={TextField}
                              variant='outlined'
                              isNumericString
                              sx={{ width: 200 }}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix='Rp. '
                              disabled
                              name={`extra_base_price${index}`}
                              id={`extra_base_price${index}`}
                              value={getExtraBasePrice(row)?.total}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <Tooltip
                                      title={`Extra 1% Rp. ${
                                        getExtraBasePrice(row)?.extra
                                      }`}
                                    >
                                      <IconButton
                                        aria-label='expand row'
                                        size='small'
                                      >
                                        <InfoIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              customInput={TextField}
                              variant='outlined'
                              isNumericString
                              sx={{ width: 150 }}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              disabled={checkDisabledStatusOrder(row.status)}
                              prefix='Rp. '
                              name={`delivery_fee${index}`}
                              id={`delivery_fee${index}`}
                              value={row.delivery_fee}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                optimizedFn2(floatValue, index, 'delivery_fee');
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              customInput={TextField}
                              variant='outlined'
                              isNumericString
                              sx={{ width: 200 }}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              disabled={checkDisabledStatusOrder(row.status)}
                              prefix='Rp. '
                              name={`selling_price${index}`}
                              id={`selling_price${index}`}
                              value={row.selling_price}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                optimizedFn2(
                                  floatValue,
                                  index,
                                  'selling_price',
                                );
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <Tooltip title={displayEstimateProfit(row)}>
                                      <IconButton
                                        aria-label='expand row'
                                        size='small'
                                      >
                                        <InfoIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                          {checked && (
                            <TableCell>
                              <NumberFormat
                                customInput={TextField}
                                variant='outlined'
                                isNumericString
                                sx={{ width: 150 }}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                prefix='Rp. '
                                name={`markup_price${index}`}
                                id={`markup_price${index}`}
                                value={row.markup_price}
                                onValueChange={(values) => {
                                  const { floatValue } = values;
                                  const val = floatValue ? floatValue : 0;
                                  optimizedFn2(val, index, 'markup_price');
                                }}
                              />
                            </TableCell>
                          )}
                          <TableCell>
                            <NumberFormat
                              customInput={TextField}
                              variant='outlined'
                              isNumericString
                              sx={{ width: 150 }}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              disabled={checkDisabledStatusOrder(row.status)}
                              prefix='Rp. '
                              name={`discount${index}`}
                              id={`discount${index}`}
                              value={row.discount}
                              onValueChange={(values) => {
                                const { floatValue } = values;
                                const val = floatValue ? floatValue : 0;
                                optimizedFn2(val, index, 'discount');
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <FormControl>
                              <Select
                                labelId='status'
                                id={`status${index}`}
                                name={`status${index}`}
                                value={row.status}
                                disabled={
                                  order?.status === 'WAITING PAYMENT' ||
                                  role === 'MONITORING'
                                }
                                onChange={(e) =>
                                  handleChangeStatus(
                                    row.id,
                                    'status',
                                    e.target.value,
                                  )
                                }
                              >
                                <MenuItem disabled selected value={''}>
                                  Pilih
                                </MenuItem>
                                {selectedStatusItems(row.previousStatus).map(
                                  (x, i) => (
                                    <MenuItem key={i} value={x}>
                                      {x}
                                    </MenuItem>
                                  ),
                                )}
                              </Select>
                            </FormControl>
                          </TableCell>
                          {['NEW', 'PROCESS'].includes(order?.status) && (
                            <TableCell>
                              {/* <FormControlLabel
                                control={
                                  <Checkbox
                                    key={index}
                                    id={`${index}`}
                                    // checked={row?.printed}
                                    disabled={row?.printed}
                                    checked={
                                      row?.printed || isChecked === index
                                    }
                                    onClick={() => isCheckboxChecked(index)}
                                  />
                                }
                              /> */}
                              <FormControlLabel
                                key={index}
                                className='twocolelement'
                                control={
                                  <Checkbox
                                    value={row?.id}
                                    id={`${index}`}
                                    checked={row?.printed || isChecked[index]}
                                    disabled={row?.printed}
                                    color='primary'
                                    onChange={(e) =>
                                      isCheckboxChecked(
                                        index,
                                        row?.id,
                                        e.target.checked,
                                      )
                                    }
                                  />
                                }
                              />
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={12}
                          >
                            <Collapse
                              in={openDetail[row.id]}
                              timeout='auto'
                              unmountOnExit
                            >
                              <Box sx={{ margin: 1 }}>
                                <Table
                                  size='small'
                                  aria-label='detailOrder'
                                  border={0}
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        style={{ borderBottom: 'none' }}
                                      ></TableCell>
                                      <TableCell
                                        style={{ borderBottom: 'none' }}
                                      >
                                        Sub Total Harga Dasar
                                      </TableCell>
                                      <TableCell
                                        style={{ borderBottom: 'none' }}
                                      >
                                        Diskon Per Item
                                      </TableCell>
                                      <TableCell
                                        style={{ borderBottom: 'none' }}
                                      >
                                        Total Diskon Per Item
                                      </TableCell>
                                      <TableCell
                                        style={{ borderBottom: 'none' }}
                                      >
                                        Laba
                                      </TableCell>
                                      <TableCell
                                        style={{ borderBottom: 'none' }}
                                      >
                                        Persentase
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        style={{ borderBottom: 'none' }}
                                        component='th'
                                        scope='row'
                                      ></TableCell>
                                      <TableCell
                                        style={{ borderBottom: 'none' }}
                                        component='th'
                                        scope='row'
                                      >{`Rp. ${Number(
                                        row.base_price + row.delivery_fee,
                                      ).toLocaleString('id-ID')}`}</TableCell>
                                      <TableCell
                                        style={{ borderBottom: 'none' }}
                                        component='th'
                                        scope='row'
                                      >{`Rp. ${row.discount?.toLocaleString(
                                        'id-ID',
                                      )}`}</TableCell>
                                      <TableCell
                                        style={{ borderBottom: 'none' }}
                                        component='th'
                                        scope='row'
                                      >{`Rp. ${Number(
                                        row.discount * row.qty,
                                      ).toLocaleString('id-ID')}`}</TableCell>
                                      <TableCell
                                        style={{ borderBottom: 'none' }}
                                        component='th'
                                        scope='row'
                                      >{`Rp. ${row.profit?.toLocaleString(
                                        'id-ID',
                                      )}`}</TableCell>
                                      <TableCell
                                        style={{ borderBottom: 'none' }}
                                        component='th'
                                        scope='row'
                                      >{`${row.profit_percentage}%`}</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {role !== 'MONITORING' && !checked && (
                        <TableCell colSpan={14} align='right'>
                          <Button
                            onClick={handleUpdate}
                            variant='contained'
                            color='primary'
                            disabled={
                              order?.status === 'WAITING PAYMENT' ||
                              order?.status === 'PENDING PAYMENT' ||
                              order?.status === 'DONE' ||
                              order?.status === 'CANCEL'
                            }
                          >
                            Update Data
                          </Button>
                        </TableCell>
                      )}
                      {role !== 'MONITORING' && checked && (
                        <TableCell colSpan={14} align='right'>
                          <Button
                            onClick={handleCustomInvoice}
                            variant='contained'
                            color='primary'
                          >
                            Simpan Tagihan Custom
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
          {paymentSection && (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel2a-content'
                id='panel2a-header'
              >
                <Typography sx={{ fontWeight: 'bold' }}>
                  Informasi Tagihan
                  {order?.payment_type === 'TUNAI' && order?.status === 'DONE'
                    ? ` | Tanggal Konfirmasi ${order?.confirmed_at}`
                    : ''}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PaymentSection order={order} setOrder={setOrder} role={role} />
              </AccordionDetails>
            </Accordion>
          )}
        </Grid>
      </Grid>
      <React.Suspense fallback={<div></div>}>
        <TravelDocumentForm
          open={open}
          setOpen={setOpen}
          traveDocsData={traveDocsData}
          setTraveDocsData={setTraveDocsData}
          data={order}
          handleSubmit={handleSubmit}
        />
      </React.Suspense>
      <React.Suspense fallback={<div></div>}>
        <DialogAdditionalItem
          open={dialogAddItem}
          setOpen={setDialogAddItem}
          handleAddItems={handleAddItems}
          handleClose={handleCloseDialogItems}
          setTempData={setTempData}
          tempData={tempData}
        />
      </React.Suspense>
    </React.Fragment>
  );
};

export default DetailDataOrderPage;
