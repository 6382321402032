/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import MyBreadcrumbs from '../MyBreadcrumbs';
import { EMPLOYEES } from '../../graphql/Employee';
import { EXPORT_REPORT } from '../../graphql/Dashboard';
import ReportPDF from '../PDF/ReportPDF';
import { generateArrayOfYears } from '../../utils';

export default function ReportPage(props) {
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [quarterly, setQuarterly] = React.useState(1);
  const [employees, setEmployees] = React.useState([]);
  const [employee, setEmployee] = React.useState(0);
  const [type, setType] = React.useState('-');
  const [error, setError] = React.useState(false);
  const years = generateArrayOfYears();

  /* FETCH DATA EMPLOYEES */
  const { data: dataEmployees } = useQuery(EMPLOYEES, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  React.useEffect(() => {
    if (dataEmployees) {
      setEmployees(dataEmployees.employees);
    }
  }, [dataEmployees]);

  const [generateReport] = useLazyQuery(EXPORT_REPORT, {
    fetchPolicy: 'network-only',
  });

  const handleSearch = async () => {
    if (type === '-') {
      setError(true);
      return;
    }

    const data = await generateReport({
      variables: {
        year,
        quarterly,
        employee_id: employee,
        type,
      },
    });

    let employeeName = 'Semua';

    if (employee !== 0) {
      employeeName = employees?.find(
        (e) => Number(e.id) === Number(employee),
      )?.name;
    }

    ReportPDF(data?.data?.exportReport, type, year, quarterly, employeeName);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ flexGrow: 1, mb: 3 }}>
          <MyBreadcrumbs data={['Laporan', 'Cetak Laporan']} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
          width: '100vh',
        }}
      >
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
            width: '15%',
          }}
        >
          <FormControl fullWidth>
            <InputLabel id='year'>Tahun</InputLabel>
            <Select
              labelId='year'
              id='year'
              name='year'
              label='Tahun'
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              {years.map((x, i) => (
                <MenuItem key={i} value={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
            width: '15%',
          }}
        >
          <FormControl fullWidth>
            <InputLabel id='quarter'>Cawu</InputLabel>
            <Select
              labelId='quarter'
              id='quarter'
              name='quarter'
              label='Cawu'
              value={quarterly}
              onChange={(e) => setQuarterly(e.target.value)}
            >
              <MenuItem disabled selected value={null}>
                Pilih
              </MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
            width: '25%',
          }}
        >
          <FormControl fullWidth>
            <InputLabel id='marketing'>Marketing</InputLabel>
            <Select
              labelId='employee'
              id='employee'
              name='employee'
              label='Marketing'
              value={employee}
              onChange={(e) => setEmployee(Number(e.target.value))}
            >
              <MenuItem selected value={'0'}>
                Semua
              </MenuItem>
              {employees.map((x) => (
                <MenuItem key={x.id} value={Number(x.id)}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
            width: '25%',
          }}
        >
          <FormControl fullWidth>
            <InputLabel id='type'>Tipe</InputLabel>
            <Select
              labelId='type'
              id='type'
              name='type'
              label='Tipe'
              value={type}
              onChange={(e) => {
                setType(e.target.value);
                setError(false);
              }}
            >
              <MenuItem disabled selected value='-'>
                Pilih
              </MenuItem>
              <MenuItem value='ONGKIR'>Total Ongkos Kirim</MenuItem>
              <MenuItem value='PROFIT'>Total Laba</MenuItem>
            </Select>
            {error && <FormHelperText error>Harus diisi.</FormHelperText>}
          </FormControl>
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
            width: '25%',
          }}
        >
          <Button variant='contained' onClick={handleSearch}>
            Download
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
}
