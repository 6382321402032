import React from 'react';
import {
  Button,
  TextField,
  Box,
  Grid,
  Typography,
  Container,
} from '@mui/material';
import NumberFormat from 'react-number-format';
import { useLazyQuery, useMutation } from '@apollo/client';

// import MonevCopyright from './MonevCopyright';
// import { AUTHENTICATE } from '../graphql/User';
import { FIND_RENTAL_BY_INVOICE, CREATE_CLAIM } from '../../graphql/Rental';
import GlobalContext from '../../utils/GlobalContext';

const ClaimForm = () => {
  const { setSnack, setGlobalLoading } = React.useContext(GlobalContext);
  const [invoice, setInvoice] = React.useState('');
  const [claim, setClaim] = React.useState({
    value: 0,
    error: false,
  });
  const [technician, setTechnician] = React.useState({
    value: '',
    error: false,
  });
  const [feedback, setFeedback] = React.useState({
    value: '',
    error: false,
  });
  const [image, setImage] = React.useState({
    value: [],
    error: false,
  });
  const [invoiceError, setInvoiceError] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [isSubmit, setIsSubmit] = React.useState(false);

  const [rental] = useLazyQuery(FIND_RENTAL_BY_INVOICE);

  const [createClaim] = useMutation(CREATE_CLAIM, {
    onCompleted: (d) => {
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data klaim berhasil disimpan',
        opened: true,
      });
      setGlobalLoading(false);
      setIsSubmit(false);
      handleReset();
      return d?.createClaim;
    },
    onError: (e) => {
      console.log('error update invoice: ', e);
      let message = 'Data cetak tagihan berhasil ';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const handleSearch = async () => {
    if (!invoice) {
      setInvoiceError(true);
      return;
    }
    const data = await rental({
      variables: { invoice },
    });

    if (!data?.data?.findRentalByInvoiceId) {
      setSnack({
        variant: 'error',
        message: 'Data tidak ditemukan',
        opened: true,
      });
      return;
    }

    setData(data?.data?.findRentalByInvoiceId);
    return;
  };

  const handleReset = () => {
    setData(null);
    setInvoice('');
    setClaim({ value: 0, error: false });
    setTechnician({ value: '', error: false });
    setFeedback({ value: '', error: false });
    setImage({ value: [], error: false });
  };

  const handleUploadFile = async (e) => {
    const files = e.target.files;
    let counter = 0;
    let fulfillFiles = [];

    while (counter < files.length) {
      fulfillFiles.push(files[counter]);
      counter += 1;
    }

    // const result = await Promise.all(
    //   fulfillFiles.map(async (file) => {
    //     return new Promise((resolve, reject) => {
    //       let newImage = { url: file };
    //       let reader = new FileReader();

    //       reader.onload = function (e) {
    //         newImage.preview = e.target.result;
    //         resolve(newImage);
    //       };

    //       reader.readAsDataURL(file);
    //     });
    //   }),
    // );

    let result = await Promise.all(
      fulfillFiles.map((item) => {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => {
            resolve({ url: item });
          };
          reader.readAsDataURL(item);
        });
      }),
    );

    setImage(result.map((x) => x.url) ?? []);
  };

  const handleSubmit = async () => {
    setIsSubmit(true);
    setGlobalLoading(true);
    const _invoice = data?.invoices?.find((x) => x?.invoice_number === invoice);
    const variables = {
      rental_id: data?.id,
      claim_cost: claim?.value,
      suggestion_to_customer: feedback?.value,
      technician_name: technician.value,
      images: image?.length ? image : [],
      invoice_id: _invoice?.id,
    };

    const result = await createClaim({ variables: { data: variables } });

    return result;
  };

  return (
    <Container component='main' maxWidth='md'>
      <Box
        sx={{
          marginTop: 2,
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          src='/logo.png'
          alt='logo'
          style={{
            width: '10%',
            marginBottom: '5px',
          }}
        />
        <center>
          <Typography component='h1' variant='h6'>
            Halaman Klaim <br />
            CV Global Jaya Indo
          </Typography>
        </center>
      </Box>
      <Grid container spacing={2} justify='flex-end' alignItems='center'>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            autoComplete='invoice'
            name='invoice'
            required
            fullWidth
            autoFocus
            id='invoice'
            label='Nomor Invoice'
            onChange={(e) => {
              setInvoice(e?.target?.value);
              setInvoiceError(false);
            }}
            value={invoice}
            error={invoiceError}
            helperText={invoiceError && 'Nomor Invoice harus diisi'}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Button onClick={handleSearch} fullWidth variant='contained'>
            Cari
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Button fullWidth variant='outlined' onClick={handleReset}>
            Reset
          </Button>
        </Grid>
      </Grid>

      {data && (
        <Grid
          container
          spacing={2}
          justify='flex-end'
          alignItems='center'
          sx={{ pt: 5 }}
        >
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              autoComplete='companyName'
              name='companyName'
              fullWidth
              id='companyName'
              label='Nama Perusahaan'
              placeholder='Perusahaan'
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              value={data?.company?.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              autoComplete='address'
              name='address'
              fullWidth
              id='address'
              label='Alamat'
              placeholder='Alamat'
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              value={data?.company?.address}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              autoComplete='pic'
              name='pic'
              fullWidth
              id='pic'
              label='PIC'
              placeholder='PIC'
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              value={data?.company?.pic}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              autoComplete='phone'
              name='phone'
              fullWidth
              id='phone'
              label='No. Telpon'
              placeholder='No. Telpon'
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              value={data?.company?.phone}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              autoComplete='contract_date'
              name='contract_date'
              fullWidth
              id='contract_date'
              label='Tanggal Kontrak'
              placeholder='Tanggal Kontrak'
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              value={data?.contract_date}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              autoComplete='contract_periode'
              name='contract_periode'
              fullWidth
              id='contract_periode'
              label='Masa Kontrak (Bulan)'
              placeholder='Masa Kontrak'
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              value={data?.contract_periode}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              autoComplete='photocopy'
              name='photocopy'
              fullWidth
              id='photocopy'
              label='Jenis Mesin'
              placeholder='Jenis Mesin'
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              value={data?.photocopy?.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <TextField
              autoComplete='technician_name'
              name='technician_name'
              fullWidth
              id='technician_name'
              label='Nama Teknisi'
              placeholder='Nama Teknisi'
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              value={data?.technician_name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <NumberFormat
              customInput={TextField}
              label='Biaya Klaim'
              variant='outlined'
              isNumericString
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix='Rp. '
              name='contractValue'
              fullWidth
              id='contractValue'
              value={claim?.value}
              error={claim?.error}
              helperText={claim?.error && 'Harus Diisi'}
              onValueChange={(values) => {
                const { floatValue } = values;
                setClaim({ ...claim, value: floatValue });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              autoComplete='technician'
              name='technician'
              fullWidth
              id='technician'
              label='Teknisi Visit'
              placeholder='Teknisi Visit'
              InputLabelProps={{
                shrink: true,
              }}
              value={technician?.value}
              onChange={(e) => {
                setTechnician({
                  ...technician,
                  value: e?.target?.value,
                  error: false,
                });
              }}
              error={Boolean(technician?.error)}
              helperText={Boolean(technician?.error) && 'Harus diisi'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <input
              accept='image/*'
              type='file'
              onChange={(e) => handleUploadFile(e)}
              multiple
            />
            {/* <TextField
              variant='standard'
              type='text'
              InputProps={{
                endAdornment: (
                  <IconButton component='label'>
                    <FileUploadOutlined />
                    <input
                      styles={{ display: 'none' }}
                      type='file'
                      hidden
                      // onChange={handleUpload}
                      name='[licenseFile]'
                    />
                  </IconButton>
                ),
              }}
            /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              autoComplete='feedback'
              name='feedback'
              fullWidth
              id='feedback'
              label='Saran Untuk Pelanggan'
              placeholder='Saran Untuk Pelanggan'
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              rows={3}
              value={feedback?.value}
              onChange={(e) => {
                setFeedback({
                  ...feedback,
                  value: e?.target?.value,
                  error: false,
                });
              }}
              error={Boolean(feedback?.error)}
              helperText={Boolean(feedback?.error) && 'Harus diisi'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Button
              onClick={handleSubmit}
              disabled={isSubmit}
              fullWidth
              variant='contained'
            >
              Simpan
            </Button>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default ClaimForm;
