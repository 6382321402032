import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, IconButton, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import InfoIcon from '@mui/icons-material/Info';

const ClaimPage = (props) => {
  const { rental } = props;
  console.log('rental', rental?.claims);
  const columns = [
    {
      field: 'id',
      headerName: 'Nomor',
      width: 90,
      renderCell: (params) => `${params.api.getRowIndex(params.row.id) + 1}.`,
    },
    {
      field: 'technician_name',
      headerName: 'Teknisi Visit',
      flex: 1,
    },
    {
      field: 'claim_cost',
      headerName: 'Biaya Claim',
      flex: 1,
      valueGetter: (params) =>
        `Rp. ${params.row?.claim_cost?.toLocaleString('id-ID')}`,
    },
    {
      field: 'suggestion_to_customer',
      headerName: 'Saran Untuk Customer',
      flex: 1,
    },
    {
      field: 'created_at',
      headerName: 'Tanggal Dibuat',
      flex: 1,
      valueGetter: (params) =>
        dayjs(params?.row?.created_at)?.format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      field: 'images',
      headerName: 'Gambar',
      flex: 1,
      renderCell: (params) => {
        if (params?.row?.images?.length) {
          return params?.row?.images?.map((x, id) => (
            <>
              <Tooltip title='Detail' placement='top'>
                <IconButton
                  aria-label='detail'
                  size='small'
                  id={id}
                  href={x}
                  target='_blank'
                >
                  <InfoIcon fontSize='inherit' />
                </IconButton>
              </Tooltip>
            </>
          ));
        }

        return null;
      },
    },
  ];
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid
          experimentalFeatures={{ newEditingApi: true }}
          rows={rental?.claims ?? []}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
        />
      </div>
    </Paper>
  );
};

export default ClaimPage;
