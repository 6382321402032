import { gql } from '@apollo/client';

export const COMPANIES = gql`
  query companies {
    companies {
      id
      name
      phone
      pic
      uid
      updated_at
      created_at
      address
      notes
    }
  }
`;

export const FIND_COMPANY_BY_UID = gql`
  query findCompanyByUid($uid: String!) {
    findCompanyByUid(uid: $uid) {
      address
      notes
      created_at
      id
      name
      phone
      pic
      uid
      updated_at
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation createCompany($data: InCompany!) {
    createCompany(data: $data) {
      id
      name
      address
      notes
      created_at
      phone
      pic
      uid
      updated_at
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany($uid: String!, $data: InCompany!) {
    updateCompany(uid: $uid, data: $data) {
      address
      notes
      created_at
      id
      name
      phone
      pic
      uid
      updated_at
    }
  }
`;

export const SEARCH_COMPANY = gql`
  query searchCompanies($name: String) {
    searchCompanies(name: $name) {
      address
      notes
      created_at
      id
      name
      phone
      pic
      uid
      updated_at
    }
  }
`;

export const UPLOAD_PHOTO = gql`
  mutation uploadPhoto($photo: Upload) {
    uploadPhoto(photo: $photo)
  }
`;
