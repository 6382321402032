import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import dayjs from 'dayjs';
import logo_gji from './logo-gji.png';
import logo_gja from './logo-gja.png';
import { companyInfo } from '../../utils';

function addWaterMark(doc, image, x, y) {
  var totalPages = doc.internal.getNumberOfPages();

  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    doc.saveGraphicsState();
    doc.setGState(new doc.GState({ opacity: 0.2 }));
    doc.addImage(image, 'PNG', x, y - 15, 100, 100);

    doc.restoreGraphicsState();
  }

  return doc;
}

const headTable = () => {
  return [
    {
      name: 'Nama Barang',
      discount: 'Diskon',
      selling_price: 'Harga',
      qty: 'Jumlah',
      sub_total: 'Sub Total',
    },
  ];
};

const InvoicePDF = (data, datetime, custom) => {
  const printDate = data?.printed_at || dayjs(datetime).format('DD-MM-YYYY');
  const fields = ['name', 'discount', 'selling_price', 'qty', 'sub_total'];

  const body = data.order_products
    ?.filter((y) => y.status === 'TERKIRIM' || y.status === 'TERBAYAR')
    .map((x, index) => {
      const obj = fields.map((z) => {
        const child = {};

        if (['discount', 'selling_price', 'sub_total'].includes(z)) {
          if (custom && z === 'selling_price') {
            child.content = `Rp. ${x.markup_price?.toLocaleString('id-ID')}`;
          } else if (custom && z === 'sub_total') {
            const sTotal = x.markup_price * x.qty;
            child.content = `Rp. ${sTotal.toLocaleString('id-ID')}`;
          } else {
            child.content = `Rp. ${x[z]?.toLocaleString('id-ID')}`;
          }

          if (z === 'sub_total') {
            child.styles = {
              fontStyle: 'bold',
            };
          }
        } else {
          child.content = x[z];
        }

        if (z === 'name') {
          const text = x.description ? `${x.name} (${x.description})` : x.name;
          child.content = text;
        }

        return child;
      });

      return obj;
    });

  let doc = new jsPDF({
    putOnlyUsedFonts: true,
    orientation: 'portrait',
    format: [210, 330],
  });

  const image = new Image();
  const company = companyInfo(data.company);
  const address = data.client?.address || '';

  if (data.company === 'GJI') {
    image.src = logo_gji;
  } else if (data.company === 'GJA') {
    image.src = logo_gja;
  }

  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const COMPANY_NAME = company?.name;
  const TITLE = 'I N V O I C E';
  doc.addImage(image, 'PNG', 10, 5, 16, 16);

  // COMPANY_NAME
  doc.setFontSize(13);
  doc.setFont('times', 'bold');
  doc.text(COMPANY_NAME, 28, 9);
  doc.setFont('times', 'normal');

  // ADDRESS
  doc.setFontSize(10);
  // doc.text(company?.address, 28, 15);
  var addressText = company?.address;
  var splittedTextAddress = doc.splitTextToSize(addressText, pageWidth / 2);
  doc.text(28, 14, splittedTextAddress);
  doc.text(company?.phone, 28, 22);

  doc.setFontSize(24);
  doc.text(TITLE, pageWidth - 57, 15);
  doc.setDrawColor(47, 84, 150);
  doc.setLineWidth(1);
  doc.line(10, 24, pageWidth - 10, 24);
  doc.setLineWidth(1.5);
  doc.line(10, 26, pageWidth - 10, 26);

  const name = custom ? `${data?.employee?.name}*` : data?.employee?.name;

  // NO INVOICE
  doc.setFontSize(10);
  doc.setFont('times', 'bold');
  doc.text(`Tanggal Cetak: ${printDate}`, pageWidth - 54, 32);
  doc.text(`Marketing: ${name}`, pageWidth - 54, 38);
  doc.setFontSize(10);
  doc.text('Kepada:', 10, 32);
  doc.setFont('times');
  doc.setFontSize(10);
  var text = `${data.client?.name} ${data.client?.regency} \n${address}`;
  var lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor;
  var splittedText = doc.splitTextToSize(text, pageWidth / 2);
  var lines = splittedText.length;
  var blockHeight = lines * lineHeight;
  var yPos = 38;
  var xPos = 10;
  doc.text(xPos, yPos, splittedText, { lineHeightFactor: 1.5 });
  yPos += blockHeight;

  const startY = yPos > 56 ? yPos : 60;
  let paymentInfo = [];

  if (data?.payment_type === 'TUNAI') {
    if (data?.payment_method === 'TUNAI') {
      // TOTAL JUAL
      const totalSales = custom
        ? data?.order_products?.reduce(
            (n, { markup_price, qty }) =>
              Number(n) + Number(markup_price) * Number(qty),
            0,
          )
        : data?.total_sales;

      // PLATFORM FEE
      // let platformFee = 0;
      // if (custom) {
      //   if ((totalSales / data?.total_payment_fee) * 100 >= 40) {
      //     platformFee = 0;
      //   } else {
      //     platformFee =
      //       (data?.total_payment_fee - data?.tax - totalSales) * 0.03;
      //   }
      // } else {
      //   platformFee = data?.admin_fee;
      // }
      // PLATFORM FEE TUNAI ALWAYS 0

      // PENGEMBALIAN
      // const refund = custom
      //   ? data?.total_payment_fee -
      //     data?.tax -
      //     totalSales -
      //     data?.total_loan -
      //     platformFee
      //   : data?.tax + data?.total_loan + data?.total_sales;
      // GRAND TOTAL
      const grandTotal =
        data?.tax + data?.total_loan + data?.total_sales - data?.total_discount;
      const change = grandTotal - data?.total_payment_fee;

      paymentInfo = [
        [
          {
            content: '',
            colSpan: 5,
          },
        ],
        [
          {
            content: 'Total Jual',
            colSpan: 4,
            styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: `Rp. ${totalSales.toLocaleString('id-ID')}`,
            styles: { fontStyle: 'bold' },
          },
        ],
        [
          {
            content: `Pajak (${data?.tax_type})`,
            colSpan: 4,
            styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: `Rp. ${data?.tax?.toLocaleString('id-ID')}`,
            styles: { fontStyle: 'bold' },
          },
        ],
        [
          {
            content: 'Pinjaman',
            colSpan: 4,
            styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: `Rp. ${data?.total_loan?.toLocaleString('id-ID')}`,
            styles: { fontStyle: 'bold' },
          },
        ],
        [
          {
            content: 'Total Diskon',
            colSpan: 4,
            styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: `Rp. ${data?.total_discount?.toLocaleString('id-ID')}`,
            styles: { fontStyle: 'bold' },
          },
        ],
        [
          {
            content: 'Total Tagihan',
            colSpan: 4,
            styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: `Rp. ${grandTotal.toLocaleString('id-ID')}`,
            styles: { fontStyle: 'bold' },
          },
        ],
        [
          {
            content: 'Total Pembayaran',
            colSpan: 4,
            styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: `Rp. ${data?.total_payment_fee.toLocaleString('id-ID')}`,
            styles: { fontStyle: 'bold' },
          },
        ],
        [
          {
            content: 'Kekurangan Pembayaran',
            colSpan: 4,
            styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: `Rp. ${change.toLocaleString('id-ID')}`,
            styles: { fontStyle: 'bold' },
          },
        ],
      ];
    } else {
      const fieldPayment = ['payment_order', 'payment_at', 'payment_fee'];
      const currentPayment = data?.order_payments?.reduce(
        (n, { payment_fee }) => Number(n) + Number(payment_fee),
        0,
      );
      const totalSales = custom
        ? data?.order_products?.reduce(
            (n, { markup_price, qty }) =>
              Number(n) + Number(markup_price) * Number(qty),
            0,
          )
        : data?.total_sales;

      // GRAND TOTAL
      const grandTotal = custom
        ? totalSales + data?.total_loan + data?.tax - data?.total_discount
        : data?.grand_total;
      const as = data?.order_payments?.map((x) => {
        const obj = fieldPayment.map((z) => {
          const child = {};

          if (z === 'payment_fee') {
            child.content = `Rp. ${x[z]?.toLocaleString('id-ID')}`;
          } else {
            child.content = x[z];
          }

          if (z === 'payment_at') {
            child.colSpan = 3;
          }

          return child;
        });

        return obj;
      });

      paymentInfo = [
        [
          {
            content: '',
            colSpan: 5,
          },
        ],
        [
          {
            content: 'Pembayaran Ke-',
            styles: {
              fontStyle: 'bold',
              fillColor: [47, 84, 150],
              textColor: 'white',
              fontSize: 8,
            },
          },
          {
            content: 'Tanggal Pembayaran',
            colSpan: 3,
            styles: {
              fontStyle: 'bold',
              fillColor: [47, 84, 150],
              textColor: 'white',
              fontSize: 8,
            },
          },
          {
            content: 'Jumlah Pembayaran',
            styles: {
              fontStyle: 'bold',
              fillColor: [47, 84, 150],
              textColor: 'white',
              fontSize: 8,
            },
          },
        ],
        ...as,
        [
          {
            content: 'Total Pembayaran',
            colSpan: 4,
            styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: `Rp. ${currentPayment?.toLocaleString('id-ID')}`,
            styles: { fontStyle: 'bold' },
          },
        ],
        [
          {
            content: 'Total Jual',
            colSpan: 4,
            styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: `Rp. ${totalSales.toLocaleString('id-ID')}`,
            styles: { fontStyle: 'bold' },
          },
        ],
        [
          {
            content: `Pajak (${data?.tax_type})`,
            colSpan: 4,
            styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: `Rp. ${data?.tax?.toLocaleString('id-ID')}`,
            styles: { fontStyle: 'bold' },
          },
        ],
        [
          {
            content: 'Pinjaman',
            colSpan: 4,
            styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: `Rp. ${data?.total_loan?.toLocaleString('id-ID')}`,
            styles: { fontStyle: 'bold' },
          },
        ],
        [
          {
            content: 'Total Diskon',
            colSpan: 4,
            styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: `Rp. ${data?.total_discount?.toLocaleString('id-ID')}`,
            styles: { fontStyle: 'bold' },
          },
        ],
        [
          {
            content: 'Grand Total',
            colSpan: 4,
            styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: `Rp. ${grandTotal?.toLocaleString('id-ID')}`,
            styles: { fontStyle: 'bold' },
          },
        ],
        [
          {
            content: 'Kekurangan Pembayaran',
            colSpan: 4,
            styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
          },
          {
            content: `Rp. ${(grandTotal - currentPayment).toLocaleString(
              'id-ID',
            )}`,
            styles: { fontStyle: 'bold' },
          },
        ],
      ];
    }
  } else {
    const fieldPayment = ['payment_reference', 'payment_fee', 'payment_at'];
    const orderPayments = data?.order_payments?.map((x) => {
      const obj = fieldPayment.map((z) => {
        const child = {};

        if (z === 'payment_fee') {
          child.colSpan = 2;
          child.content = `Rp. ${x[z]?.toLocaleString('id-ID')}`;
        } else {
          child.content = x[z];
        }

        if (z === 'payment_reference') {
          child.colSpan = 2;
        }

        return child;
      });

      return obj;
    });

    // TOTAL JUAL
    const totalSales = custom
      ? data?.order_products?.reduce(
          (n, { markup_price, qty }) =>
            Number(n) + Number(markup_price) * Number(qty),
          0,
        )
      : data?.total_sales;

    // PLATFORM FEE
    let platformFee = 0;
    if (custom) {
      if ((totalSales / data?.total_payment_fee) * 100 >= 40) {
        platformFee = 0;
      } else {
        platformFee = (data?.total_payment_fee - data?.tax - totalSales) * 0.05;
      }
    } else {
      platformFee = data?.admin_fee;
    }

    // PENGEMBALIAN
    const refund = custom
      ? data?.total_payment_fee -
        data?.tax -
        totalSales -
        data?.total_loan -
        platformFee
      : data?.refund;

    // GRAND TOTAL
    const gTotal = refund + data?.total_discount;

    paymentInfo = [
      [
        {
          content: '',
          colSpan: 5,
        },
      ],
      [
        {
          content: 'No Referensi',
          colSpan: 2,
          styles: {
            fontStyle: 'bold',
            fillColor: [47, 84, 150],
            textColor: 'white',
            fontSize: 8,
          },
        },
        {
          content: 'Jumlah Pembayaran',
          colSpan: 2,
          styles: {
            fontStyle: 'bold',
            fillColor: [47, 84, 150],
            textColor: 'white',
            fontSize: 8,
          },
        },
        {
          content: 'Tanggal Pembayaran',
          styles: {
            fontStyle: 'bold',
            fillColor: [47, 84, 150],
            textColor: 'white',
            fontSize: 8,
          },
        },
      ],
      ...orderPayments,
      [
        {
          content: `Total Uang`,
          colSpan: 4,
          styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
        },
        {
          content: `Rp. ${data?.total_payment_fee?.toLocaleString('id-ID')}`,
          styles: { fontStyle: 'bold' },
        },
      ],
      [
        {
          content: 'Total Jual',
          colSpan: 4,
          styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
        },
        {
          content: `Rp. ${totalSales.toLocaleString('id-ID')}`,
          styles: { fontStyle: 'bold' },
        },
      ],
      [
        {
          content: `Total Down Payment`,
          colSpan: 4,
          styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
        },
        {
          content: `Rp. ${data?.total_down_payment?.toLocaleString('id-ID')}`,
          styles: { fontStyle: 'bold' },
        },
      ],
      [
        {
          content: `Total Pinjaman`,
          colSpan: 4,
          styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
        },
        {
          content: `Rp. ${data?.total_loan?.toLocaleString('id-ID')}`,
          styles: { fontStyle: 'bold' },
        },
      ],
      [
        {
          content: `Pajak ${data?.tax_type}`,
          colSpan: 4,
          styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
        },
        {
          content: `Rp. ${data?.tax?.toLocaleString('id-ID')}`,
          styles: { fontStyle: 'bold' },
        },
      ],
      [
        {
          content: `Platform Fee`,
          colSpan: 4,
          styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
        },
        {
          content: `Rp. ${platformFee.toLocaleString('id-ID')}`,
          styles: { fontStyle: 'bold' },
        },
      ],
      [
        {
          content: `Total Diskon`,
          colSpan: 4,
          styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
        },
        {
          content: `Rp. ${data?.total_discount?.toLocaleString('id-ID')}`,
          styles: { fontStyle: 'bold' },
        },
      ],
      [
        {
          content: `Pengembalian`,
          colSpan: 4,
          styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
        },
        {
          content: `Rp. ${refund.toLocaleString('id-ID')}`,
          styles: { fontStyle: 'bold' },
        },
      ],
      [
        {
          content: `Grand Total`,
          colSpan: 4,
          styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
        },
        {
          content: `Rp. ${gTotal.toLocaleString('id-ID')}`,
          styles: { fontStyle: 'bold' },
        },
      ],
    ];
  }

  autoTable(doc, {
    margin: { left: 10 },
    startY: startY,
    theme: 'grid',
    headStyles: { fillColor: [47, 84, 150], textColor: 'white', fontSize: 8 },
    head: headTable(),
    body: [...body, ...paymentInfo],
    columnStyles: {
      0: {
        cellWidth: 80,
      },
      1: {
        cellWidth: 25,
      },
      2: {
        cellWidth: 35,
      },
      3: {
        cellWidth: 15,
      },
      4: {
        cellWidth: 35,
      },
    },
    styles: {
      overflow: 'linebreak',
      overflowColumns: 'linebreak',
      textColor: 'black',
    },
  });

  // const lastTable = doc.lastAutoTable.finalY + 10;
  // doc.text('Total Pajak', 10, lastTable);

  // let multiply = 0;
  // let a = 0;
  // body.forEach((z) => {
  //   // NAME
  //   var splitTextName = doc.splitTextToSize(z.name, 50);
  //   // doc.text(z.name, 16, startY + 14 + (multiply += 14));
  //   doc.setFontSize(10);
  //   doc.text(16, startY + 14 + (multiply + 14), splitTextName, {
  //     lineHeightFactor: 1.5,
  //   });

  //   // UNIT
  //   doc.setFontSize(8);
  //   var splitTextUnit = doc.splitTextToSize(z.unit, 50);
  //   var lineHeightUnit = doc.getLineHeight(z.name) / doc.internal.scaleFactor;
  //   var linesUnit = splitTextUnit.length;
  //   var blockHeight = linesUnit * lineHeightUnit;

  //   if (splitTextName.length > 1) {
  //     doc.text(
  //       16,
  //       startY + 14 + blockHeight + (a += 14 + blockHeight + 2),
  //       splitTextUnit,
  //       {
  //         lineHeightFactor: 1.5,
  //       }
  //     );
  //   } else {
  //     doc.text(16, startY + 14 + (a += 14 - 1), splitTextUnit, {
  //       lineHeightFactor: 1.5,
  //     });
  //   }

  //   // KOMISI 1
  //   doc.setFontSize(10);
  //   doc.text(z.first_commision, 66, startY + 14 + (multiply + 14));

  //   multiply = +14;
  // });

  // autoTable(doc, {
  //   startY: doc.lastAutoTable.finalY + 5,
  //   theme: 'grid',
  //   headStyles: { fillColor: [47, 84, 150], textColor: 'white' },
  //   head: [
  //     {
  //       first_name: '',
  //       first_value: '',
  //       second_name: '',
  //       second_value: '',
  //     },
  //   ],
  //   body: [
  //     {
  //       first_name: 'Tipe Pembayaran',
  //       first_value: data?.payment_type,
  //       second_name: 'Total Pajak',
  //       second_value: `Rp. ${data?.tax.toLocaleString('id-ID')}`,
  //     },
  //     {
  //       first_name: 'Pinjaman',
  //       first_value: `Rp. ${data?.loan.toLocaleString('id-ID')}`,
  //       second_name: 'Total Jual',
  //       second_value: `Rp. ${data?.total_sales.toLocaleString('id-ID')}`,
  //     },
  //     {
  //       first_name: 'Total Komisi',
  //       first_value: `Rp. ${data?.total_commission.toLocaleString('id-ID')}`,
  //     },
  //     {
  //       first_name: 'Sub Total',
  //       first_value: `Rp. ${data?.sub_total.toLocaleString('id-ID')}`,
  //       second_name: 'Total Tagihan',
  //       second_value: `Rp. ${data?.grand_total.toLocaleString('id-ID')}`,
  //     },
  //   ],
  //   willDrawCell: (data) => {
  //     var rows = data.table.body;
  //     if (data.row.index === rows.length - 1) {
  //       doc.setFont(undefined, 'bold');
  //     }
  //   },
  //   columnWidth: 'wrap',
  //   tableWidth: 'auto',
  //   styles: {
  //     overflow: 'linebreak',
  //     overflowColumns: 'linebreak',
  //     textColor: 'black',
  //   },
  // });

  const lastTable = doc.lastAutoTable.finalY + 10;

  const halfPage = pageWidth / 2;
  const textSupervisor = 'Tanda Terima,';
  const textSupervisorLength = Math.round(doc.getTextWidth(textSupervisor));
  const textSubordinate = 'Hormat Kami,';
  const textSubordinateLength = Math.round(doc.getTextWidth(textSubordinate));

  const textSupervisorStartAt = (halfPage - textSupervisorLength) / 2;
  const textSubordinateStartAt =
    (halfPage - textSubordinateLength) / 2 + halfPage;

  doc.setFontSize(12);
  doc.text(textSupervisor, textSupervisorStartAt, lastTable + 5);
  doc.text(textSubordinate, textSubordinateStartAt, lastTable + 5);

  const supervisorName = '( ...................................... )';
  const supervisorNameLength = Math.round(doc.getTextWidth(supervisorName));
  const subordinateName = company?.name;
  const subordinateNameLength = Math.round(doc.getTextWidth(subordinateName));

  const textSupervisorNameStartAt = (halfPage - supervisorNameLength) / 2;
  const textSubordinateNameStartAt =
    (halfPage - subordinateNameLength) / 2 + halfPage;

  doc.setFontSize(12);
  doc.text(supervisorName, textSupervisorNameStartAt, lastTable + 40);
  doc.text(subordinateName, textSubordinateNameStartAt, lastTable + 40);

  doc = addWaterMark(doc, image, halfPage / 2, startY);
  doc.setProperties({
    title: `Tagihan ${data?.invoice_number}`,
  });
  // OPEN NEW TAB
  window.open(
    doc.output('bloburl', { filename: `Tagihan ${data?.invoice_number}.pdf` }),
  );

  // SAVE
  // doc.save(`Tagihan ${data?.invoice_number}.pdf`);
};

export default InvoicePDF;
