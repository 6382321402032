import { gql } from '@apollo/client';

export const PHOTOCOPIES = gql`
  query photocopies {
    photocopies {
      id
      uid
      name
      serial_number
      type
      description
      base_price
      vendor_fee
      created_at
      updated_at
      image
      notes
      purchase_date
      internal_serial_number
      specification
      url
    }
  }
`;

export const FIND_PHOTOCOPY_BY_UID = gql`
  query findPhotocopyByUid($uid: String!) {
    findPhotocopyByUid(uid: $uid) {
      id
      uid
      name
      serial_number
      type
      description
      base_price
      vendor_fee
      created_at
      updated_at
      image
      notes
      purchase_date
      internal_serial_number
      specification
      url
    }
  }
`;

export const CREATE_PHOTOCOPY = gql`
  mutation createPhotocopy($data: InPhotocopy!) {
    createPhotocopy(data: $data) {
      id
      uid
      name
      serial_number
      type
      description
      base_price
      vendor_fee
      created_at
      updated_at
      image
      notes
      purchase_date
      internal_serial_number
      specification
      url
    }
  }
`;

export const UPDATE_PHOTOCOPY = gql`
  mutation updatePhotocopy($uid: String!, $data: InPhotocopy!) {
    updatePhotocopy(uid: $uid, data: $data) {
      id
      uid
      name
      serial_number
      type
      description
      base_price
      vendor_fee
      created_at
      updated_at
      image
      purchase_date
      internal_serial_number
      notes
      specification
      url
    }
  }
`;

export const SEARCH_PHOTOCOPIES = gql`
  query searchPhotocopies($name: String) {
    searchPhotocopies(name: $name) {
      id
      uid
      name
      serial_number
      type
      description
      base_price
      vendor_fee
      created_at
      updated_at
      image
      notes
      purchase_date
      internal_serial_number
      specification
      url
    }
  }
`;
