import React from 'react';
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  concat,
} from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import Cookies from 'universal-cookie';
import MyRouter from './components/MyRouter';
import { GlobalProvider } from './utils/GlobalContext';

const uri =
  process.env.NODE_ENV !== 'production'
    ? process.env.REACT_APP_GRAPHQL_URL_LOCAL
    : process.env.REACT_APP_GRAPHQL_URL_PRODUCTION;

const httpLink = new createUploadLink({ uri });

const authMiddleware = new ApolloLink((operation, forward) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

const App = () => {
  const [snack, setSnack] = React.useState({
    variant: 'success',
    message: null,
    opened: false,
  });
  const [globalLoading, setGlobalLoading] = React.useState(false);

  return (
    <GlobalProvider value={{ setSnack, setGlobalLoading }}>
      <ApolloProvider client={client}>
        <MyRouter snack={snack} globalLoading={globalLoading} />
      </ApolloProvider>
    </GlobalProvider>
  );
};

export default App;
