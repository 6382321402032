import { gql } from '@apollo/client';

export const CREATE_OFFERING = gql`
  mutation createOffering($rental_uid: String!) {
    createOffering(rental_uid: $rental_uid) {
      id
      contract_date
      contract_periode
      end_of_contract
      contract_value
      employee {
        name
      }
      free_printable
      installation_fee
      initial_counter
      photocopy {
        id
        uid
        name
        serial_number
        type
        description
        image
        notes
        specification
        url
      }
      price_per_printable
      status
      tax
      technician_name
      uid
      company {
        id
        name
        phone
        pic
        uid
        updated_at
        created_at
        address
        notes
      }
      offering {
        offering_sequence
        offering_number
      }
    }
  }
`;
