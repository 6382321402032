import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';

const validationSchema = yup.object({
  generateMouDate: yup.string().required('Tanggal harus diisi'),
});

const GenerateMou = (props) => {
  const { open, setOpen, handleGenerateMou, date, setDate } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      generateMouDate: dayjs(date).format('YYYY-MM-DD'),
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleGenerateMou(values);
      resetForm();
    },
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Buat MoU</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <Box
              sx={{
                '& > :not(style)': { m: 1 },
              }}
            >
              <Grid container spacing={2} sx={{ pb: 3 }}>
                <Grid xs={12} container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id='generateMouDate'
                      name='generateMouDate'
                      label='Tanggal'
                      type='date'
                      value={formik.values.generateMouDate}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.generateMouDate &&
                        Boolean(formik.errors.generateMouDate)
                      }
                      helperText={
                        formik.touched.generateMouDate &&
                        formik.errors.generateMouDate
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={handleClose}>
              Batal
            </Button>
            <Button variant='contained' type='submit'>
              Simpan
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default GenerateMou;
