/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import { FIND_EXTERNAL_ORDER } from '../../graphql/ExternalOrder';
import Box from '@mui/material/Box';
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import Badge from '@mui/material/Badge';
import Chip from '@mui/material/Chip';

import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';
import MyBreadcrumbs from '../MyBreadcrumbs';
import GlobalContext from '../../utils/GlobalContext';
import DrawerFilter from './DrawerFilter';
import ExternalOrderPDF from '../PDF/ExternalOrderPDF';
import { chipColor } from '../../utils';
import dayjs from 'dayjs';

const ExternalOrderPage = (props) => {
  const { role } = props;
  const { setGlobalLoading } = React.useContext(GlobalContext);
  const [externalOrder, setExternalOrder] = React.useState([]);
  const [baseExternalOrder, setBaseExternalOrder] = React.useState([]);
  const [searched, setSearched] = React.useState('');
  const [isSearch, setIsSearch] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [isFilter, setIsFilter] = React.useState(false);
  const [quarterly, setQuarterly] = React.useState(1);
  const [year, setYear] = React.useState(new Date().getFullYear());

  const columns = [
    {
      field: 'id',
      headerName: 'Nomor',
      width: 90,
      renderCell: (params) => `${params.api.getRowIndex(params.row.id) + 1}.`,
    },
    {
      field: 'employee',
      headerName: 'Marketing',
      width: 90,
      valueGetter: (params) => params.row?.employee?.name,
    },
    {
      field: 'client',
      headerName: 'Pelanggan',
      flex: 1,
      valueGetter: (params) => params.row?.client?.name,
    },
    {
      field: 'total_payment_fee',
      headerName: 'Uang Masuk',
      flex: 1,
      valueGetter: (params) =>
        `Rp. ${params.row?.total_payment_fee?.toLocaleString('id-ID')}`,
    },
    {
      field: 'tax_admin_fee',
      headerName: 'Expense Fee',
      flex: 1,
      valueGetter: (params) =>
        `Rp. ${params.row?.tax_admin_fee?.toLocaleString('id-ID')}`,
    },
    {
      field: 'total_sales',
      headerName: 'Nominal Pembelanjaan',
      flex: 1,
      valueGetter: (params) =>
        `Rp. ${params.row?.total_sales?.toLocaleString('id-ID')}`,
    },
    {
      field: 'quarterly',
      headerName: 'Cawu & Tahun',
      width: 175,
      valueGetter: (params) =>
        `Cawu ${params?.row?.quarterly} - Tahun ${dayjs(
          params?.row?.mutation_at_default,
        ).format('YYYY')}`,
    },
    {
      field: 'company',
      headerName: 'CV',
      width: 70,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <Chip
              label={params.row?.company}
              variant='filled'
              color={chipColor(params.row?.company)}
              size='small'
            />
          </React.Fragment>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Tipe',
      width: 90,
    },
    {
      field: 'eoid',
      headerName: 'Aksi',
      width: 90,
      renderCell: (params) => {
        return (
          <Tooltip title='Cetak Nota' placement='top'>
            <IconButton
              aria-label='cetak'
              size='small'
              onClick={() => ExternalOrderPDF(params.row)}
            >
              <InfoIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const requestSearch = (value) => {
    setSearched(value);
  };

  const clearSearch = () => {
    setSearched('');
    setIsSearch(false);
    setExternalOrder([...baseExternalOrder]);
  };

  const doSearch = async () => {
    setIsSearch(true);
    let filteredRows;
    // eslint-disable-next-line array-callback-return
    filteredRows = externalOrder.filter((row) => {
      const search = searched.toLowerCase();
      let flag = false;

      if (row?.client?.name.toLowerCase().includes(search)) {
        flag = true;
      }

      if (flag) return row;
    });

    setExternalOrder(filteredRows);
  };

  /* FETCH DATA FIND_EXTERNAL_ORDER */
  const { loading: loadExternalOrder, data: dataExternalOrder } = useQuery(
    FIND_EXTERNAL_ORDER,
    {
      variables: { quarterly: 1, year },
      fetchPolicy: 'network-only',
      onError: (e) => console.log('error getting selor:', e),
    },
  );

  React.useEffect(() => {
    if (loadExternalOrder) {
      setGlobalLoading(true);
    }
  }, []);

  React.useEffect(() => {
    if (dataExternalOrder) {
      setBaseExternalOrder(dataExternalOrder.findExternalOrderByQuarterly);
      setExternalOrder(dataExternalOrder.findExternalOrderByQuarterly);
      setGlobalLoading(false);
    }
  }, [dataExternalOrder]);

  const [getFilterExternalOrders] = useLazyQuery(FIND_EXTERNAL_ORDER, {
    fetchPolicy: 'network-only',
    variables: { quarterly, year },
  });

  const doFilter = async () => {
    setIsFilter(true);
    setOpenDrawer(false);
    const data = await getFilterExternalOrders({
      variables: { quarterly, year },
    });

    setExternalOrder([...data.data?.findExternalOrderByQuarterly]);
  };

  const resetFilter = () => {
    setIsFilter(false);
    setOpenDrawer(false);
    setYear(new Date().getFullYear());
    setQuarterly(1);
    setExternalOrder([...baseExternalOrder]);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <MyBreadcrumbs data={['Transaksi', 'Nota']} />
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
          }}
        >
          <FormControl size='small' margin='dense'>
            <OutlinedInput
              name='search'
              margin='dense'
              id='search'
              placeholder='Cari No Referensi...'
              onChange={(e) => requestSearch(e.target.value)}
              value={searched}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  doSearch();
                }
              }}
              endAdornment={
                <InputAdornment position='end'>
                  {isSearch && (
                    <IconButton onClick={() => clearSearch()}>
                      <ClearIcon />
                    </IconButton>
                  )}
                  {!isSearch && (
                    <IconButton onClick={() => doSearch()}>
                      <SearchIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        {role !== 'MONITORING' && (
          <Box
            sx={{
              p: 0.5,
              m: 0.5,
            }}
          >
            <Tooltip title='Tambah' placement='top'>
              <IconButton
                aria-label='add'
                size='small'
                component={Link}
                to={'/admin/external_order/new'}
                style={{ backgroundColor: '#1976d2', color: 'white' }}
              >
                <AddIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
          }}
        >
          <Tooltip title='Filter' placement='top'>
            <IconButton
              aria-label='add'
              size='small'
              onClick={() => setOpenDrawer(true)}
              style={{ backgroundColor: '#1976d2', color: 'white' }}
            >
              <Badge invisible={!isFilter} color='error' variant='dot'>
                <FilterListIcon fontSize='inherit' />
              </Badge>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={externalOrder}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </div>
      </Paper>

      <DrawerFilter
        open={openDrawer}
        setOpen={setOpenDrawer}
        quarterly={quarterly}
        setQuarterly={setQuarterly}
        doFilter={doFilter}
        resetFilter={resetFilter}
        year={year}
        setYear={setYear}
      />
    </React.Fragment>
  );
};

export default ExternalOrderPage;
