import React from 'react';
import {
  Box,
  IconButton,
  Paper,
  Tooltip,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Chip,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';

import MyBreadcrumbs from '../MyBreadcrumbs';
import GlobalContext from '../../utils/GlobalContext';
import { FIND_RENTAL_BY_COMPANY_ID } from '../../graphql/Rental';
import { chipColor } from '../../utils';

const RentalDetailCompany = (props) => {
  const { cid } = props?.match?.params;
  let history = useHistory();
  const { setGlobalLoading } = React.useContext(GlobalContext);
  const [rentals, setRentals] = React.useState([]);
  const [baseRentals, setBaseRentals] = React.useState([]);
  const [searched, setSearched] = React.useState('');
  const [isSearch, setIsSearch] = React.useState(false);
  const [company, setCompany] = React.useState(null);

  const columns = [
    {
      field: 'id',
      headerName: 'Nomor',
      width: 90,
      renderCell: (params) => `${params.api.getRowIndex(params.row.id) + 1}.`,
    },
    {
      field: 'photocopy_name',
      headerName: 'Jenis Mesin',
      flex: 1,
      valueGetter: (params) => params.row?.photocopy?.name,
    },
    {
      field: 'contract_date',
      headerName: 'Tanggal Kontrak',
      flex: 1,
    },
    {
      field: 'contract_periode',
      headerName: 'Masa Kontrak',
      flex: 1,
      renderCell: (params) => `${params?.row?.contract_periode} Bulan`,
    },
    {
      field: 'end_of_contract',
      headerName: 'Tanggal Kontrak Berakhir',
      flex: 1,
    },
    {
      field: 'contract_value',
      headerName: 'Nilai Kontrak',
      width: 120,
      valueGetter: (params) =>
        `Rp. ${params?.row?.contract_value.toLocaleString('id-ID')}`,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => {
        return (
          <React.Fragment>
            {params.row?.client?.name}
            &nbsp;
            <Chip
              label={params.row.status}
              variant='filled'
              color={chipColor(params.row?.status)}
              size='small'
            />
          </React.Fragment>
        );
      },
    },
    {
      field: 'oid',
      headerName: 'Aksi',
      width: 90,
      renderCell: (params) => {
        return (
          <Tooltip title='Detail' placement='top'>
            <IconButton
              aria-label='detail'
              size='small'
              component={Link}
              to={`/admin/rental/${cid}/${params?.row?.uid}`}
              target='__blank'
            >
              <InfoIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const requestSearch = (value) => {
    setSearched(value);
  };

  const clearSearch = () => {
    setSearched('');
    setIsSearch(false);
    setRentals([...baseRentals]);
  };

  const doSearch = async () => {
    setIsSearch(true);
    // const data = await getCompanies({
    //   variables: { name: searched },
    // });

    // setCompany([...data.data?.searchCompanies]);
  };

  /* FETCH DATA COMPANIES */
  const { loading: loadRentals, data: dataRentals } = useQuery(
    FIND_RENTAL_BY_COMPANY_ID,
    {
      variables: { company_id: cid },
      fetchPolicy: 'network-only',
      onError: (e) => console.log('error getting selor:', e),
    },
  );

  React.useEffect(() => {
    if (loadRentals) {
      setGlobalLoading(true);
    }
  }, []);

  React.useEffect(() => {
    if (dataRentals) {
      setBaseRentals(dataRentals.findRentalByCompanyId);
      setRentals(dataRentals.findRentalByCompanyId);
      setCompany(dataRentals.findRentalByCompanyId[0]?.company);
      setGlobalLoading(false);
    }
  }, [dataRentals]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <MyBreadcrumbs
            data={['Transaksi', 'Mesin Fotokopi', company?.name ?? null]}
          />
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
          }}
        >
          <FormControl size='small' margin='dense'>
            <OutlinedInput
              name='search'
              margin='dense'
              id='search'
              placeholder='Cari'
              onChange={(e) => requestSearch(e.target.value)}
              value={searched}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  doSearch();
                }
              }}
              endAdornment={
                <InputAdornment position='end'>
                  {isSearch && (
                    <IconButton onClick={() => clearSearch()}>
                      <ClearIcon />
                    </IconButton>
                  )}
                  {!isSearch && (
                    <IconButton onClick={() => doSearch()}>
                      <SearchIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={rentals}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default RentalDetailCompany;
