import React from 'react';
import { useQuery } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InventoryIcon from '@mui/icons-material/Inventory';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DoneIcon from '@mui/icons-material/Done';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useHistory } from 'react-router-dom';
import {
  GET_DASHBOARD,
  GET_SUMMARY,
  GET_DASHBOARD_ESTIMATE,
} from '../graphql/Dashboard';
import { EMPLOYEES } from '../graphql/Employee';
import { Button } from '@mui/material';
import DashboardPDF from './PDF/DashboardPDF';
import { generateArrayOfYears } from '../utils';

const Dashboard = () => {
  const [dashboard, setDashboard] = React.useState([
    {
      total_base_price: 0,
      total_sales: 0,
      total_profit: 0,
      total_discount: 0,
      total_platform_fee_bill: 0,
      total_platform_fee_invoice: 0,
      quarterly: 1,
    },
    {
      total_base_price: 0,
      total_sales: 0,
      total_profit: 0,
      total_discount: 0,
      total_platform_fee_bill: 0,
      total_platform_fee_invoice: 0,
      quarterly: 2,
    },
    {
      total_base_price: 0,
      total_sales: 0,
      total_profit: 0,
      total_discount: 0,
      total_platform_fee_bill: 0,
      total_platform_fee_invoice: 0,
      quarterly: 3,
    },
  ]);
  const [summary, setSummary] = React.useState({
    total_order: 0,
    new_order: 0,
    process_order: 0,
    done_order: 0,
  });
  const [dashboardEstimate, setDashboardEstimate] = React.useState([
    {
      estimate_total_sales: 0,
      estimate_profit: 0,
      quarterly: 1,
    },
    {
      estimate_total_sales: 0,
      estimate_profit: 0,
      quarterly: 2,
    },
    {
      estimate_total_sales: 0,
      estimate_profit: 0,
      quarterly: 3,
    },
  ]);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [employees, setEmployees] = React.useState([]);
  const [employee, setEmployee] = React.useState(0);
  const years = generateArrayOfYears();

  const { data: dataDashboard } = useQuery(GET_DASHBOARD, {
    variables: { year, employee_id: employee },
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  const { data: dataSummary } = useQuery(GET_SUMMARY, {
    variables: { year, employee_id: employee },
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  const { data: dataDashboardEstimate } = useQuery(GET_DASHBOARD_ESTIMATE, {
    variables: { year, employee_id: employee },
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  React.useEffect(() => {
    if (dataDashboard && dataDashboard?.getTotalDashboard?.length) {
      setDashboard(dataDashboard.getTotalDashboard);
    }
  }, [dataDashboard]);

  React.useEffect(() => {
    if (dataSummary) {
      setSummary(dataSummary.getSummary);
    }
  }, [dataSummary]);

  React.useEffect(() => {
    if (dataDashboardEstimate) {
      setDashboardEstimate(dataDashboardEstimate.getDashboardEstimate);
    }
  }, [dataDashboardEstimate]);

  /* FETCH DATA EMPLOYEES */
  const { data: dataEmployees } = useQuery(EMPLOYEES, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  React.useEffect(() => {
    if (dataEmployees) {
      setEmployees(dataEmployees.employees);
    }
  }, [dataEmployees]);

  let history = useHistory();

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'right', mb: 1.5 }}>
        <Box sx={{ width: `calc(100vw - 10)`, mr: 1 }}>
          <FormControl fullWidth>
            <InputLabel id='marketing'>Marketing</InputLabel>
            <Select
              labelId='employee'
              id='employee'
              name='employee'
              label='Marketing'
              value={employee}
              onChange={(e) => setEmployee(Number(e.target.value))}
            >
              <MenuItem selected value={'0'}>
                Semua
              </MenuItem>
              {employees.map((x) => (
                <MenuItem key={x.id} value={Number(x.id)}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ width: `calc(100vw - 10)`, mr: 1 }}>
          <FormControl fullWidth>
            <InputLabel id='year'>Tahun</InputLabel>
            <Select
              labelId='year'
              id='year'
              name='year'
              label='Tahun'
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              {years.map((x, i) => (
                <MenuItem key={i} value={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            width: `calc(100vw - 10)`,
            display: 'grid',
            alignItems: 'center',
          }}
        >
          <Button
            variant='contained'
            onClick={() =>
              DashboardPDF(dashboard, dashboardEstimate, {
                year,
                employees,
                employee,
              })
            }
          >
            Export
          </Button>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Paper
            sx={{
              padding: 2,
              margin: 'auto',
              maxWidth: 500,
              height: 125,
            }}
          >
            <Grid item xs={12} sm container>
              <Grid item xs container direction='column' spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant='h6'>
                    {summary?.total_order}
                  </Typography>
                  <Typography variant='h6' gutterBottom>
                    Total Pesanan
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <InventoryIcon style={{ fontSize: 60 }} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Paper
            sx={{
              padding: 2,
              margin: 'auto',
              maxWidth: 500,
              height: 125,
            }}
          >
            <Grid
              item
              xs={12}
              sm
              container
              onClick={() =>
                history.push(`/admin/data_order?status=new&year=${year}`)
              }
              style={{
                cursor: 'pointer',
              }}
            >
              <Grid item xs container direction='column' spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant='h6'>
                    {summary?.new_order}
                  </Typography>
                  <Typography variant='h6' gutterBottom>
                    Pesanan Baru
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <ShoppingCartIcon style={{ fontSize: 60 }} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Paper
            sx={{
              padding: 2,
              margin: 'auto',
              maxWidth: 500,
              height: 125,
            }}
          >
            <Grid
              item
              xs={12}
              sm
              container
              onClick={() =>
                history.push(`/admin/data_order?status=process&year=${year}`)
              }
              style={{
                cursor: 'pointer',
              }}
            >
              <Grid item xs container direction='column' spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant='h6'>
                    {summary?.process_order}
                  </Typography>
                  <Typography variant='h6' gutterBottom>
                    Pesanan Diproses
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <AutorenewIcon style={{ fontSize: 60 }} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Paper
            sx={{
              padding: 2,
              margin: 'auto',
              maxWidth: 500,
              height: 125,
            }}
          >
            <Grid item xs={12} sm container>
              <Grid item xs container direction='column' spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant='h6'>
                    {summary?.done_order}
                  </Typography>
                  <Typography variant='h6' gutterBottom>
                    Total Selesai
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <DoneIcon style={{ fontSize: 60 }} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ResponsiveContainer id='2' width='95%' height={300}>
            <BarChart
              width={600}
              height={400}
              data={dashboard}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                tickFormatter={(value) => `Cawu ${value}`}
                dataKey='quarterly'
              />
              <YAxis
                tickFormatter={(value) =>
                  new Intl.NumberFormat('id', {
                    notation: 'compact',
                    compactDisplay: 'long',
                  }).format(value)
                }
              />
              <Tooltip
                labelFormatter={(value) => `Cawu ${value} Tahun ${year}`}
                formatter={(value, name, props) => {
                  if (name === 'total_base_price') {
                    return [
                      `Rp. ${new Intl.NumberFormat('id').format(value)}`,
                      'Total Harga Dasar',
                      props,
                    ];
                  }

                  if (name === 'total_sales') {
                    return [
                      `Rp. ${new Intl.NumberFormat('id').format(value)}`,
                      'Total Penjualan',
                      props,
                    ];
                  }

                  if (name === 'total_profit') {
                    return [
                      `Rp. ${new Intl.NumberFormat('id').format(value)}`,
                      'Total Laba',
                      props,
                    ];
                  }

                  if (name === 'total_discount') {
                    return [
                      `Rp. ${new Intl.NumberFormat('id').format(value)}`,
                      'Total Discount',
                      props,
                    ];
                  }

                  if (name === 'total_platform_fee_bill') {
                    return [
                      `Rp. ${new Intl.NumberFormat('id').format(value)}`,
                      'Total Platform Fee Tagihan',
                      props,
                    ];
                  }

                  if (name === 'total_platform_fee_invoice') {
                    return [
                      `Rp. ${new Intl.NumberFormat('id').format(value)}`,
                      'Total Platform Fee Nota',
                      props,
                    ];
                  }

                  if (name === 'total_delivery_fee') {
                    return [
                      `Rp. ${new Intl.NumberFormat('id').format(value)}`,
                      'Total Ongkir',
                      props,
                    ];
                  }
                }}
              />
              <Bar dataKey='total_base_price' fill='#8884d8' />
              <Bar dataKey='total_sales' fill='#82ca9d' />
              <Bar dataKey='total_profit' fill='#84d8c7' />
              <Bar dataKey='total_discount' fill='#d8d884' />
              <Bar dataKey='total_platform_fee_bill' fill='#CBA0AE' />
              <Bar dataKey='total_platform_fee_invoice' fill='#0096FF' />
              <Bar dataKey='total_delivery_fee' fill='#37B828' />
              <Legend
                payload={[
                  {
                    id: '1',
                    type: 'circle',
                    value: 'Total Harga Dasar',
                    color: '#8884d8',
                  },
                  {
                    id: '2',
                    type: 'circle',
                    value: 'Total Penjualan',
                    color: '#82ca9d',
                  },
                  {
                    id: '3',
                    type: 'circle',
                    value: 'Total Laba',
                    color: '#84d8c7',
                  },
                  {
                    id: '4',
                    type: 'circle',
                    value: 'Total Diskon',
                    color: '#d8d884',
                  },
                  {
                    id: '5',
                    type: 'circle',
                    value: 'Total Platform Fee Tagihan',
                    color: '#CBA0AE',
                  },
                  {
                    id: '6',
                    type: 'circle',
                    value: 'Total Platform Fee Nota',
                    color: '#0096FF',
                  },
                  {
                    id: '7',
                    type: 'circle',
                    value: 'Total Ongkir',
                    color: '#37B828',
                  },
                ]}
              />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ResponsiveContainer id='2' width='95%' height={300}>
            <BarChart
              width={600}
              height={400}
              data={dashboardEstimate}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                tickFormatter={(value) => `Cawu ${value}`}
                dataKey='quarterly'
              />
              <YAxis
                tickFormatter={(value) =>
                  new Intl.NumberFormat('id', {
                    notation: 'compact',
                    compactDisplay: 'long',
                  }).format(value)
                }
              />
              <Tooltip
                labelFormatter={(value) => `Cawu ${value} Tahun ${year}`}
                formatter={(value, name, props) => {
                  if (name === 'estimate_total_sales') {
                    return [
                      `Rp. ${new Intl.NumberFormat('id').format(value)}`,
                      'Estimasi Total Penjualan',
                      props,
                    ];
                  }

                  if (name === 'estimate_profit') {
                    return [
                      `Rp. ${new Intl.NumberFormat('id').format(value)}`,
                      'Estimasti Total Laba',
                      props,
                    ];
                  }
                }}
              />
              <Bar dataKey='estimate_total_sales' fill='#8884d8' />
              <Bar dataKey='estimate_profit' fill='#82ca9d' />
              <Legend
                payload={[
                  {
                    id: '1',
                    type: 'circle',
                    value: 'Estimasi Total Penjualan',
                    color: '#8884d8',
                  },
                  {
                    id: '2',
                    type: 'circle',
                    value: 'Estimasi Total Laba',
                    color: '#82ca9d',
                  },
                ]}
              />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
