import { gql } from '@apollo/client';

export const CREATE_RENTAL = gql`
  mutation createRental($data: InRental!) {
    createRental(data: $data) {
      id
    }
  }
`;

export const RENTALS = gql`
  query rentals {
    rentals {
      id
      name
      phone
      pic
      uid
      updated_at
      created_at
      address
      total_rental
      rentals {
        id
        status
      }
    }
  }
`;

export const FIND_RENTAL_BY_COMPANY_ID = gql`
  query findRentalByCompanyId($company_id: String!) {
    findRentalByCompanyId(company_id: $company_id) {
      id
      contract_date
      contract_periode
      contract_date_origin
      end_of_contract
      contract_value
      generate_mou
      generate_offering
      employee {
        name
      }
      free_printable
      installation_fee
      initial_counter
      photocopy {
        id
        uid
        name
        serial_number
        type
        description
        image
        notes
      }
      price_per_printable
      print_installation_fee
      status
      tax
      technician_name
      uid
      company {
        id
        name
        phone
        pic
        uid
        updated_at
        created_at
        address
        notes
      }
    }
  }
`;

export const FIND_RENTAL_BY_UID = gql`
  query findRentalByUid($uid: String!) {
    findRentalByUid(uid: $uid) {
      id
      contract_date
      contract_date_origin
      contract_periode
      end_of_contract
      contract_value
      generate_mou
      generate_offering
      generate_mou_date
      generate_offering_date
      travel_rental {
        reference_number
        driver_name
        driver_phone
        shipped_with
        licence_plat
        created_at
      }
      employee {
        id
        name
      }
      free_printable
      installation_fee
      initial_counter
      photocopy {
        id
        uid
        name
        serial_number
        type
        description
        image
        notes
        specification
        url
      }
      price_per_printable
      print_installation_fee
      status
      tax
      technician_name
      uid
      company {
        id
        name
        phone
        pic
        uid
        updated_at
        created_at
        address
        notes
      }
      invoices {
        id
        invoice_number
        invoice_sequence
        initial_counter
        amount
        overprint_price
        tax_amount
        total
        final_counter
        due_date
        contract_to
        created_at
        due_date_origin
        updated_at
        is_deadline
        status
      }
      invoice_installation {
        id
        invoice_number
        invoice_sequence
        initial_counter
        created_at
        updated_at
      }
      claims {
        id
        rental_id
        claim_cost
        images
        suggestion_to_customer
        technician_name
        created_at
        updated_at
      }
    }
  }
`;

export const FIND_RENTAL_BY_INVOICE = gql`
  query findRentalByInvoiceId($invoice: String!) {
    findRentalByInvoiceId(invoice: $invoice) {
      id
      contract_date
      contract_date_origin
      contract_periode
      end_of_contract
      contract_value
      generate_mou
      generate_offering
      generate_mou_date
      generate_offering_date
      travel_rental {
        reference_number
        driver_name
        driver_phone
        shipped_with
        licence_plat
        created_at
      }
      employee {
        id
        name
      }
      free_printable
      installation_fee
      initial_counter
      photocopy {
        id
        uid
        name
        serial_number
        type
        description
        image
        notes
      }
      price_per_printable
      print_installation_fee
      status
      tax
      technician_name
      uid
      company {
        id
        name
        phone
        pic
        uid
        updated_at
        created_at
        address
        notes
      }
      invoices {
        id
        invoice_number
        invoice_sequence
        initial_counter
        amount
        overprint_price
        tax_amount
        due_date_origin
        total
        final_counter
        due_date
        contract_to
        created_at
        updated_at
      }
      invoice_installation {
        id
        invoice_number
        invoice_sequence
        initial_counter
        created_at
        updated_at
      }
    }
  }
`;

export const APPROVE_CONTRACT = gql`
  mutation approveContract($uid: String!) {
    approveContract(uid: $uid) {
      id
      contract_date
      contract_periode
      end_of_contract
      contract_value
      generate_mou
      generate_offering
      generate_mou_date
      generate_offering_date
      employee {
        name
      }
      free_printable
      installation_fee
      initial_counter
      photocopy {
        id
        uid
        name
        serial_number
        type
        description
        image
        notes
      }
      price_per_printable
      print_installation_fee
      status
      tax
      technician_name
      uid
      company {
        id
        name
        phone
        pic
        uid
        updated_at
        created_at
        address
        notes
      }
      invoices {
        id
        invoice_number
        invoice_sequence
        initial_counter
        amount
        overprint_price
        tax_amount
        total
        final_counter
        due_date_origin
        due_date
        contract_to
        created_at
        updated_at
      }
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation updateInvoice($id: ID!, $data: InInvoice!) {
    updateInvoice(id: $id, data: $data) {
      id
      invoice_number
      invoice_sequence
      initial_counter
      amount
      overprint_price
      tax_amount
      total
      final_counter
      due_date
      due_date_origin
      contract_to
      is_deadline
      status
      created_at
      updated_at
    }
  }
`;

export const GENERATE_MOU = gql`
  mutation generateMou($rental_uid: String!, $date: Date!) {
    generateMou(rental_uid: $rental_uid, date: $date) {
      id
      contract_date
      contract_date_origin
      contract_periode
      end_of_contract
      contract_value
      generate_mou
      generate_offering
      generate_mou_date
      generate_offering_date
      employee {
        name
      }
      free_printable
      installation_fee
      initial_counter
      photocopy {
        id
        uid
        name
        serial_number
        type
        description
        image
        notes
      }
      price_per_printable
      print_installation_fee
      status
      tax
      technician_name
      uid
      company {
        id
        name
        phone
        pic
        uid
        updated_at
        created_at
        address
        notes
      }
      invoices {
        id
        invoice_number
        invoice_sequence
        initial_counter
        amount
        overprint_price
        tax_amount
        due_date_origin
        total
        final_counter
        due_date
        due_date_origin
        contract_to
        created_at
        updated_at
      }
    }
  }
`;

export const GENERATE_INSTALLATION = gql`
  mutation generateInvoiceInstallation($rental_uid: String!) {
    generateInvoiceInstallation(rental_uid: $rental_uid) {
      id
      contract_date
      contract_date_origin
      contract_periode
      end_of_contract
      contract_value
      generate_mou
      generate_offering
      generate_mou_date
      generate_offering_date
      employee {
        name
      }
      free_printable
      installation_fee
      initial_counter
      photocopy {
        id
        uid
        name
        serial_number
        type
        description
        image
        notes
      }
      price_per_printable
      print_installation_fee
      status
      tax
      technician_name
      uid
      company {
        id
        name
        phone
        pic
        uid
        updated_at
        created_at
        address
        notes
      }
      invoices {
        id
        invoice_number
        invoice_sequence
        initial_counter
        amount
        overprint_price
        tax_amount
        total
        due_date_origin
        final_counter
        due_date
        contract_to
        created_at
        updated_at
      }
      invoice_installation {
        id
        invoice_number
        invoice_sequence
        initial_counter
        created_at
        updated_at
      }
    }
  }
`;

export const UPDATE_RENTAL = gql`
  mutation updateRental($uid: String!, $data: InRental!) {
    updateRental(uid: $uid, data: $data) {
      id
      contract_date
      contract_date_origin
      contract_periode
      end_of_contract
      contract_value
      generate_mou
      generate_offering
      generate_mou_date
      generate_offering_date
      employee {
        name
      }
      free_printable
      installation_fee
      initial_counter
      photocopy {
        id
        uid
        name
        serial_number
        type
        description
        image
        notes
      }
      price_per_printable
      print_installation_fee
      status
      tax
      technician_name
      uid
      company {
        id
        name
        phone
        pic
        uid
        updated_at
        created_at
        address
        notes
      }
      invoices {
        id
        invoice_number
        invoice_sequence
        initial_counter
        amount
        due_date_origin
        overprint_price
        tax_amount
        total
        final_counter
        due_date
        contract_to
        created_at
        updated_at
      }
      invoice_installation {
        id
        invoice_number
        invoice_sequence
        initial_counter
        created_at
        updated_at
      }
    }
  }
`;

export const CREATE_TRAVEL_DOC = gql`
  mutation createTravelRental($data: InTravelRental!) {
    createTravelRental(data: $data) {
      id
      contract_date
      contract_date_origin
      contract_periode
      end_of_contract
      contract_value
      generate_mou
      generate_offering
      generate_mou_date
      generate_offering_date
      employee {
        name
      }
      free_printable
      installation_fee
      initial_counter
      photocopy {
        id
        uid
        name
        serial_number
        type
        description
        image
        notes
      }
      price_per_printable
      print_installation_fee
      status
      tax
      technician_name
      uid
      company {
        id
        name
        phone
        pic
        uid
        updated_at
        created_at
        address
        notes
      }
      invoices {
        id
        invoice_number
        invoice_sequence
        initial_counter
        amount
        overprint_price
        tax_amount
        total
        final_counter
        due_date
        due_date_origin
        contract_to
        created_at
        updated_at
      }
      invoice_installation {
        id
        invoice_number
        invoice_sequence
        initial_counter
        created_at
        updated_at
      }
      travel_rental {
        reference_number
        driver_name
        driver_phone
        shipped_with
        licence_plat
        created_at
      }
    }
  }
`;

export const CREATE_CLAIM = gql`
  mutation createClaim($data: InClaim!) {
    createClaim(data: $data) {
      id
      rental_id
      suggestion_to_customer
      technician_name
      created_at
      claim_cost
      images
      updated_at
    }
  }
`;

export const UPDATE_PAYMENT_INVOICE = gql`
  mutation updatePaymentInvoice($id: ID!) {
    updatePaymentInvoice(id: $id) {
      id
      invoice_number
      invoice_sequence
      initial_counter
      amount
      overprint_price
      tax_amount
      due_date_origin
      total
      final_counter
      due_date
      due_date_origin
      contract_to
      created_at
      updated_at
    }
  }
`;

export const UPDATE_INITIAL_COUNTER = gql`
  mutation updateInitialCounter($id: ID!, $initial_counter: Int!) {
    updateInitialCounter(id: $id, initial_counter: $initial_counter) {
      id
      invoice_number
      invoice_sequence
      initial_counter
      amount
      overprint_price
      tax_amount
      total
      final_counter
      due_date
      due_date_origin
      contract_to
      is_deadline
      status
      created_at
      updated_at
    }
  }
`;
