import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TopicIcon from '@mui/icons-material/Topic';
import ListIcon from '@mui/icons-material/List';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Route, Switch, Link, Redirect, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';

import DashboardPage from './Dashboard';
import UnitPage from './Unit/UnitPage';
import TypePage from './Type/TypePage';
import ClientPage from './Client/ClientPage';
import ClientDetail from './Client/ClientDetail';
import EmployeePage from './Employee/EmployeePage';
import ProductPage from './Product/ProductPage';
import ProductDetail from './Product/ProductDetail';
import ListOrderPage from './Transaction/ListOrderStepper';
import DataOrderPage from './Transaction/DataOrderPage';
import DetailDataOrderPage from './Transaction/DetailDataOrderPage';
import ExternalOrderPage from './ExternalOrder/ExternalOrderPage';
import ExternalOrderForm from './ExternalOrder/ExternalOrderFormNew';
import ReportPage from './Report/ReportPage';
import GlobalContext from '../utils/GlobalContext';
import CompanyPage from './Company/CompanyPage';
import PhotocopyPage from './Photocopy/PhotocopyPage';
import RentalForm from './Rental/RentalForm';
import RentalPage from './Rental/RentalPage';
import RentalDetailCompany from './Rental/RentalDetailCompany';
import RentalDetail from './Rental/RentalDetail';

const drawerWidth = 240;
const cookie = new Cookies();

const MyAdmin = (props) => {
  const { window } = props;
  const { setSnack } = React.useContext(GlobalContext);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let history = useHistory();
  const [openData, setOpenData] = React.useState(false);
  const [openTransaction, setOpenTransaction] = React.useState(false);

  const handleClick = () => {
    setOpenData(!openData);
  };

  const handleClickTransaction = () => {
    setOpenTransaction(!openTransaction);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = () => {
    cookie.remove('id');
    cookie.remove('name');
    cookie.remove('email');
    cookie.remove('token', { path: '/' });
    cookie.remove('role', { path: '/' });
    history.push('/login');
  };

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  const token = cookie.get('token');
  if (token) {
    const decodedJwt = parseJwt(token);
    if (
      decodedJwt.exp * 1000 < Date.now() &&
      decodedJwt.role === 'MONITORING'
    ) {
      cookie.remove('id');
      cookie.remove('name');
      cookie.remove('email');
      cookie.remove('token', { path: '/' });
      cookie.remove('role', { path: '/' });

      setSnack({
        variant: 'error',
        message: 'Sesi berakhir, silahkan hubungi Administrator!',
        opened: true,
      });
    }
  }
  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List component='nav'>
        <ListItemButton component={Link} to='/admin/dashboard'>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary='Dashboard' />
        </ListItemButton>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <TopicIcon />
          </ListItemIcon>
          <ListItemText primary='Data' />
          {openData ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openData} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItemButton component={Link} to='/admin/satuan' sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary='Satuan' />
            </ListItemButton>
            <ListItemButton component={Link} to='/admin/jenis' sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary='Jenis' />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to='/admin/pelanggan'
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary='Pelanggan' />
            </ListItemButton>
            <ListItemButton component={Link} to='/admin/pegawai' sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary='Pegawai' />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to='/admin/perusahaan'
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary='Perusahaan' />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to='/admin/photocopy'
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary='Fotokopi' />
            </ListItemButton>
          </List>
        </Collapse>

        {/* TRANSACTION */}
        <ListItemButton onClick={handleClickTransaction}>
          <ListItemIcon>
            <TopicIcon />
          </ListItemIcon>
          <ListItemText primary='Transaksi' />
          {openTransaction ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openTransaction} timeout='auto' unmountOnExit>
          {cookie.get('role') !== 'MONITORING' && (
            <List component='div' disablePadding>
              <ListItemButton
                component={Link}
                to='/admin/list_order'
                sx={{ pl: 4 }}
              >
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary='Rencana Belanja' />
              </ListItemButton>
            </List>
          )}
          <List component='div' disablePadding>
            <ListItemButton
              component={Link}
              to='/admin/data_order'
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary='Daftar Rencana Belanja' />
            </ListItemButton>
          </List>
          <List component='div' disablePadding>
            <ListItemButton
              component={Link}
              to='/admin/external_order'
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary='Nota' />
            </ListItemButton>
          </List>
          <List component='div' disablePadding>
            <ListItemButton component={Link} to='/admin/rental' sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary='Fotokopi' />
            </ListItemButton>
          </List>
        </Collapse>

        {/* LAPORAN */}
        <ListItemButton component={Link} to='/admin/report'>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary='Laporan' />
        </ListItemButton>

        <Divider sx={{ my: 1 }} />
        <React.Fragment>
          <ListItemButton onClick={logout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary='Logout' />
          </ListItemButton>
        </React.Fragment>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap component='div'>
            CV Global Jaya Indo | {cookie.get('name')}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component='nav'
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label='mailbox folders'
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Switch>
          <Route
            exact
            path='/admin/dashboard'
            render={(props) => <DashboardPage {...props} />}
          />
          <Route
            exact
            path='/admin/satuan'
            render={(props) => (
              <UnitPage role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/jenis'
            render={(props) => (
              <TypePage role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/pelanggan'
            render={(props) => (
              <ClientPage role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/pegawai'
            render={(props) => (
              <EmployeePage role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/produk'
            render={(props) => (
              <ProductPage role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/list_order'
            render={(props) => (
              <ListOrderPage role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/data_order'
            render={(props) => (
              <DataOrderPage role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/produk/:pid'
            render={(props) => (
              <ProductDetail role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/data_order/:oid'
            render={(props) => (
              <DetailDataOrderPage role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/external_order'
            render={(props) => (
              <ExternalOrderPage role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/external_order/new'
            render={(props) => (
              <ExternalOrderForm role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/client/:cid'
            render={(props) => (
              <ClientDetail role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/perusahaan'
            render={(props) => (
              <CompanyPage role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/photocopy'
            render={(props) => (
              <PhotocopyPage role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/rental/new'
            render={(props) => (
              <RentalForm role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/rental'
            render={(props) => (
              <RentalPage role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/rental/:cid'
            render={(props) => (
              <RentalDetailCompany role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/rental/:cid/:rid'
            render={(props) => (
              <RentalDetail role={cookie.get('role')} {...props} />
            )}
          />
          <Route
            exact
            path='/admin/report'
            render={(props) => (
              <ReportPage role={cookie.get('role')} {...props} />
            )}
          />
          <Redirect exact from='/' to='/admin/dashboard' />
        </Switch>
      </Box>
    </Box>
  );
};

MyAdmin.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MyAdmin;
