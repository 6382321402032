import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

const DrawerClient = (props) => {
  const {
    setOpen,
    open,
    marketing,
    setMarketing,
    regency,
    setRegency,
    doFilter,
    resetFilter,
    employees,
  } = props;

  return (
    <React.Fragment>
      <Drawer anchor={'right'} open={open} onClose={() => setOpen(false)}>
        <Box sx={{ width: 250 }} role="presentation">
          <Grid container spacing={2}>
            <Typography sx={{ mt: 4, ml: 4 }} variant="button">
              Filter Data
            </Typography>
            <Grid
              item
              sx={{ mt: 2, mr: 2, ml: 2 }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <FormControl fullWidth>
                <InputLabel id="regency">Wilayah</InputLabel>
                <Select
                  labelId="regency"
                  id="regency"
                  name="regency"
                  label="Wilayah"
                  value={regency}
                  onChange={(e) => setRegency(e.target.value)}
                >
                  <MenuItem disabled selected value={null}>
                    Pilih
                  </MenuItem>
                  <MenuItem value={'ALL'}>ALL</MenuItem>
                  <MenuItem value={'Gresik'}>Gresik</MenuItem>
                  <MenuItem value={'Jombang'}>Jombang</MenuItem>
                  <MenuItem value={'Mojokerto'}>Mojokerto</MenuItem>
                  <MenuItem value={'Sidoarjo'}>Sidoarjo</MenuItem>
                  <MenuItem value={'Surabaya'}>Surabaya</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item sx={{ mr: 2, ml: 2 }} xs={12} sm={12} md={12} lg={12}>
              <FormControl fullWidth>
                <InputLabel id="marketing">Marketing</InputLabel>
                <Select
                  labelId="marketing"
                  id="marketing"
                  name="marketing"
                  label="Marketing"
                  value={marketing}
                  onChange={(e) => setMarketing(e.target.value)}
                >
                  <MenuItem disabled selected value="">
                    Pilih
                  </MenuItem>
                  {employees.map((x) => (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sx={{ mr: 2, ml: 2 }} xs={12} sm={12} md={12} lg={12}>
              <Button
                variant="contained"
                size="small"
                onClick={() => doFilter()}
              >
                Cari
              </Button>
              <Button variant="text" size="small" onClick={() => resetFilter()}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default DrawerClient;
