/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import {
  PHOTOCOPIES,
  CREATE_PHOTOCOPY,
  UPDATE_PHOTOCOPY,
  SEARCH_PHOTOCOPIES,
} from '../../graphql/Photocopy';
import Box from '@mui/material/Box';
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import MyBreadcrumbs from '../MyBreadcrumbs';
import GlobalContext from '../../utils/GlobalContext';

const PhotocopyForm = React.lazy(() => import('./PhotocopyForm'));

const PhotocopyPage = (props) => {
  const { role } = props;
  const { setSnack, setGlobalLoading } = React.useContext(GlobalContext);
  const [photocopy, setPhotocopy] = React.useState([]);
  const [basePhotocopy, setBasePhotocopy] = React.useState([]);
  const [searched, setSearched] = React.useState('');
  const [isSearch, setIsSearch] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [data, setData] = React.useState({});

  const columns = [
    {
      field: 'id',
      headerName: 'Nomor',
      width: 90,
      renderCell: (params) => `${params.api.getRowIndex(params.row.id) + 1}.`,
    },
    {
      field: 'name',
      headerName: 'Nama Fotokopi',
      flex: 1,
    },
    {
      field: 'serial_number',
      headerName: 'No. Seri',
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Tipe',
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Deskripsi',
      flex: 1,
    },
    {
      field: 'notes',
      headerName: 'Catatan',
      flex: 1,
    },
    {
      field: 'base_price',
      headerName: 'Harga Dasar',
      flex: 1,
      renderCell: (params) => {
        return `Rp. ${params?.row?.base_price?.toLocaleString('id-ID')}`;
      },
    },
    {
      field: 'vendor_fee',
      headerName: 'Fee Vendor',
      flex: 1,
      renderCell: (params) => {
        return `Rp. ${params?.row?.vendor_fee?.toLocaleString('id-ID')}`;
      },
    },
  ];

  if (role !== 'MONITORING') {
    columns.push({
      field: 'uid',
      headerName: 'Aksi',
      width: 90,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <Tooltip title='Ubah' placement='right'>
              <IconButton
                aria-label='edit'
                size='small'
                onClick={() => {
                  setEdit(true);
                  setData(params?.row);
                  setOpen(true);
                }}
              >
                <EditIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        );
      },
    });
  }

  const requestSearch = (value) => {
    setSearched(value);
  };

  const clearSearch = () => {
    setSearched('');
    setIsSearch(false);
    setPhotocopy([...basePhotocopy]);
  };

  const doSearch = async () => {
    setIsSearch(true);
    const data = await getPhotocopies({
      variables: { name: searched },
    });

    setPhotocopy([...data.data?.searchPhotocopy]);
  };

  /* FETCH DATA PHOTOCOPIES */
  const { loading: loadClients, data: dataClients } = useQuery(PHOTOCOPIES, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  /* FETCH DATA SEARCH CLIENTS */
  const [getPhotocopies] = useLazyQuery(SEARCH_PHOTOCOPIES, {
    fetchPolicy: 'network-only',
  });

  /* MUTATE DATA */
  const [insertPhotocopy] = useMutation(CREATE_PHOTOCOPY, {
    onCompleted: (d) => {
      const data = photocopy;
      setPhotocopy([...data, d.createPhotocopy]);
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data Fotokopi berhasil disimpan',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);
    },
    onError: (e) => {
      console.log('error verify user: ', e);
      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message: 'Data Fotokopi gagal disimpan',
        opened: true,
      });
    },
  });

  const [updatePhotocopy] = useMutation(UPDATE_PHOTOCOPY, {
    onCompleted: (d) => {
      const data = photocopy;
      const updatedData = data.map((x) => {
        if (x.id === d.updatePhotocopy.id) {
          return { ...d.updatePhotocopy };
        }

        return x;
      });

      setPhotocopy([...updatedData]);
      setSnack({
        variant: 'success',
        message: 'Data Fotokopi berhasil diubah',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);
      setEdit(false);
      setData({});
    },
    onError: (e) => {
      console.log('error insert unit: ', e);
      let message = 'Data Fotokopi gagal diubah';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  /* BUTTON DIALOG */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /* INSERT DATA */
  const handleSubmit = async (values) => {
    setGlobalLoading(true);
    if (edit) {
      updatePhotocopy({
        variables: {
          uid: data.uid,
          data: values,
        },
      });
    } else {
      insertPhotocopy({
        variables: { data: values },
      });
    }
  };

  React.useEffect(() => {
    if (loadClients) {
      setGlobalLoading(true);
    }
  }, []);

  React.useEffect(() => {
    if (dataClients) {
      setBasePhotocopy(dataClients.photocopies);
      setPhotocopy(dataClients.photocopies);
      setGlobalLoading(false);
    }
  }, [dataClients]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <MyBreadcrumbs data={['Master', 'Fotokopi']} />
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
          }}
        >
          <FormControl size='small' margin='dense'>
            <OutlinedInput
              name='search'
              margin='dense'
              id='search'
              placeholder='Cari'
              onChange={(e) => requestSearch(e.target.value)}
              value={searched}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  doSearch();
                }
              }}
              endAdornment={
                <InputAdornment position='end'>
                  {isSearch && (
                    <IconButton onClick={() => clearSearch()}>
                      <ClearIcon />
                    </IconButton>
                  )}
                  {!isSearch && (
                    <IconButton onClick={() => doSearch()}>
                      <SearchIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        {role !== 'MONITORING' && (
          <Box
            sx={{
              p: 0.5,
              m: 0.5,
            }}
          >
            <Tooltip title='Tambah' placement='top'>
              <IconButton
                aria-label='add'
                size='small'
                onClick={() => handleClickOpen()}
                style={{
                  backgroundColor: '#1976d2',
                  color: 'white',
                  marginRight: 10,
                }}
              >
                <AddIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={photocopy}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </div>
      </Paper>

      <React.Suspense fallback={<div></div>}>
        <PhotocopyForm
          open={open}
          setOpen={setOpen}
          handleSubmit={handleSubmit}
          setEdit={setEdit}
          edit={edit}
          setData={setData}
          data={data}
        />
      </React.Suspense>
    </React.Fragment>
  );
};

export default PhotocopyPage;
