import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import logo_gja from './logo-gja.png';
import { companyInfo } from '../../utils';

function addWaterMark(doc, image, x, y) {
  var totalPages = doc.internal.getNumberOfPages();

  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    doc.saveGraphicsState();
    doc.setGState(new doc.GState({ opacity: 0.2 }));
    doc.addImage(image, 'PNG', x, y - 15, 100, 100);

    doc.restoreGraphicsState();
  }

  return doc;
}

const OfferingPDF = async (rental, invoice) => {
  let doc = new jsPDF({
    putOnlyUsedFonts: true,
    orientation: 'portrait',
    format: [210, 330],
  });
  const image = new Image();
  image.src = logo_gja;

  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(22);
  doc.setFont('times', 'bold');
  doc.text('INVOICE RENTAL', 15, 20);
  doc.text('INVOICE RENTAL', 15, 20);
  doc.setFontSize(12);
  doc.setFont('times', 'normal');
  doc.text(`No. ${invoice.invoice_number}`, 15, 25);
  doc.text(`Tanggal: 24 Mei 2024`, 15, 35);
  doc.text(`Jatuh Tempo: 30 Mei 2024`, 15, 42);

  doc.text('Kepada:', 125, 28);
  doc.setFont('times');
  var text = `${rental.company?.name} \n${rental.company?.address}`;
  var lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor;
  var splittedText = doc.splitTextToSize(text, 75);
  var lines = splittedText.length;
  var blockHeight = lines * lineHeight;
  var yPos = 35;
  var xPos = 125;
  doc.text(xPos, yPos, splittedText, { lineHeightFactor: 1.5 });
  yPos += blockHeight;

  autoTable(doc, {
    margin: { left: 15 },
    startY: 55,
    theme: 'grid',
    headStyles: { fillColor: [47, 84, 150], textColor: 'white', fontSize: 12 },
    bodyStyles: { textColor: 'black' },
    head: [
      {
        no: 'No.',
        description: 'Deskripsi',
        qty: 'Jumlah',
        price: 'Harga',
        total: 'Total',
      },
    ],
    body: [
      [
        { content: '1' },
        {
          content: 'Biaya Instalasi dan Ongkir',
          styles: { fontStyle: 'bold' },
        },
        { content: 1 },
        { content: `Rp. 250.000` }, // rental?.contract_value
        { content: `Rp. 250.000` }, // rental?.contract_value
      ],
      [
        {
          content: 'Total',
          colSpan: 4,
          styles: { fontStyle: 'bold', halign: 'right' },
        },
        {
          content: `Rp. 250.000`,
        },
      ],
    ],
  });

  const lastTable = doc.lastAutoTable.finalY + 10;
  doc.setFont('times', 'bold');
  doc.text('Sistem Pembayaran', 15, lastTable);
  doc.setFont('times', 'normal');
  doc.text('1. Tunai', 15, lastTable + 6);
  doc.text('2. Transfer Bank: ', 15, lastTable + 12);
  doc.setFont('times', 'bold');
  doc.text('Bank BCA 4680348001', 46, lastTable + 12);
  doc.setFont('times', 'normal');
  doc.text('a.n BELLA ASTRIA SUMARYONO PUTRI', 15, lastTable + 18);
  doc.text(
    'Pertanyaan dan informasi dapat diajukan melalui:',
    15,
    lastTable + 26,
  );
  doc.text(
    '+62 813 9343 9398 atau cvganeshajayaartha@gmail.com',
    15,
    lastTable + 32,
  );
  doc.text(
    'Catatan: Setiap pembayaran yang sudah dilakukan mohon sertakan bukti pembayaran',
    15,
    lastTable + 40,
  );
  doc.text(
    'Terima kasih atas kepercayaan Anda kepada perusahaan kami.',
    15,
    lastTable + 46,
  );
  doc.text(
    'Semoga hubungan kerjasama ini dapat berlanjut.',
    15,
    lastTable + 52,
  );
  const halfPage = pageWidth / 2;
  doc = addWaterMark(doc, image, halfPage / 2, 85);
  doc.setProperties({
    title: `${invoice?.invoice_number} | ${rental?.company?.name}`,
  });
  // OPEN NEW TAB
  window.open(doc.output('bloburl', { filename: `Surat Penawaran.pdf` }));
};

export default OfferingPDF;
