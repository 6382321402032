import { gql } from '@apollo/client';

export const TYPES = gql`
  query types {
    types {
      id
      tid
      name
      created_at
      updated_at
    }
  }
`;

export const SEARCH_TYPE = gql`
  query searchTypes($name: String!) {
    searchTypes(name: $name) {
      id
      tid
      name
      created_at
      updated_at
    }
  }
`;

export const CREATE_TYPE = gql`
  mutation createType($name: String!) {
    createType(name: $name) {
      id
      tid
      name
      created_at
      updated_at
    }
  }
`;

export const UPDATE_TYPE = gql`
  mutation updateType($tid: String!, $name: String!) {
    updateType(tid: $tid, name: $name) {
      id
      tid
      name
      created_at
      updated_at
    }
  }
`;
