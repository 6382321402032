import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const headTable = () => {
  return [
    {
      no: 'No.',
      name: 'Nama Pelanggan',
      sum: 'Nominal',
    },
  ];
};

const ReportPDF = (data, type, year, quarterly, employeeName) => {
  let doc = new jsPDF({
    putOnlyUsedFonts: true,
    orientation: 'portrait',
    format: [210, 330],
  });

  let result = data.map((x, i) => ({
    no: `${i + 1}.`,
    name: x.name,
    sum: `Rp. ${x.sum?.toLocaleString('id-ID')}`,
  }));

  const grand_total = data?.reduce((n, { sum }) => Number(n) + Number(sum), 0);

  if (!result.length) {
    result = [
      [
        {
          content: 'Tidak ada data',
          colSpan: 3,
          styles: { halign: 'center', valign: 'middle', fontStyle: 'bold' },
        },
      ],
    ];
  }

  const gt = [
    [
      {
        content: 'Total',
        colSpan: 2,
        styles: { halign: 'center', valign: 'middle', fontStyle: 'bold' },
      },
      {
        content: `Rp. ${grand_total?.toLocaleString('id-ID')}`,
        styles: { fontStyle: 'bold' },
      },
    ],
  ];

  let titleType = type === 'ONGKIR' ? 'Ongkos Kirim' : 'Laba';

  doc.setFontSize(10);
  doc.setFont('times', 'bold');
  doc.text(`Laporan Total ${titleType}`, 14, 10);
  doc.text(`Cawu ${quarterly} Tahun ${year}`, 14, 15);
  doc.text(`Nama Marketing: ${employeeName}`, 14, 20);

  autoTable(doc, {
    startY: 23,
    theme: 'grid',
    headStyles: { fillColor: [47, 84, 150], textColor: 'white' },
    head: headTable(),
    body: [...result, ...gt],
    columnWidth: 'wrap',
    tableWidth: 'auto',
    styles: {
      overflow: 'linebreak',
      overflowColumns: 'linebreak',
      textColor: 'black',
    },
  });

  doc.setProperties({
    title: `Laporan ${titleType}`,
  });

  window.open(
    doc.output('bloburl', {
      filename: `Laporan ${titleType}.pdf`,
    }),
  );
};

export default ReportPDF;
