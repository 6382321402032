import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import logo_gji from './logo-gji.png';
import logo_gja from './logo-gja.png';
import { companyInfo } from '../../utils';

function addWaterMark(doc, image, x, y) {
  var totalPages = doc.internal.getNumberOfPages();

  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    doc.saveGraphicsState();
    doc.setGState(new doc.GState({ opacity: 0.2 }));
    doc.addImage(image, 'PNG', x, y - 15, 100, 100);

    doc.restoreGraphicsState();
  }

  return doc;
}

const headTable = () => {
  return [
    {
      no: 'No.',
      name: 'Nama Barang',
      qty: 'Jumlah',
      unit: 'Satuan',
    },
  ];
};

const TravelDocument = (data, product_ids) => {
  const rowsTable = () => {
    const body = data.order_products
      // ?.filter((y) => y.status === 'TERBELI')
      ?.filter((y) => product_ids.includes(y.id.toString()))
      .map((x, index) => ({
        no: `${index + 1}.`,
        name: x.name,
        qty: x.qty,
        unit: x.unit?.name,
      }));

    return body;
  };

  let doc = new jsPDF({
    putOnlyUsedFonts: true,
    orientation: 'portrait',
    format: [210, 330],
  });
  const image = new Image();
  const company = companyInfo(data.company);

  if (data.company === 'GJI') {
    image.src = logo_gji;
  } else if (data.company === 'GJA') {
    image.src = logo_gja;
  }

  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const COMPANY_NAME = company?.name;
  const TITLE = 'SURAT JALAN';
  doc.addImage(image, 'PNG', 10, 5, 16, 16);

  // COMPANY_NAME
  doc.setFontSize(13);
  doc.setFont('times', 'bold');
  doc.text(COMPANY_NAME, 28, 9);
  doc.setFont('times', 'normal');

  // ADDRESS
  doc.setFontSize(10);
  // doc.text(company?.address, 28, 15);
  var addressText = company?.address;
  var splittedTextAddress = doc.splitTextToSize(addressText, pageWidth / 2);
  doc.text(28, 14, splittedTextAddress);
  doc.text(company?.phone, 28, 22);

  doc.setFontSize(18);
  doc.text(TITLE, pageWidth - 55, 20);
  doc.setDrawColor(47, 84, 150);
  doc.setLineWidth(1);
  doc.line(10, 24, pageWidth - 10, 24);
  doc.setLineWidth(1.5);
  doc.line(10, 26, pageWidth - 10, 26);

  // NO INVOICE
  doc.setFontSize(10);
  doc.setFont('times', 'bold');
  doc.text(
    `Tanggal Pengiriman: ${data.travel_document?.created_at}`,
    pageWidth - 75,
    32
  );
  doc.text(
    `Nama Pengemudi: ${data.travel_document?.driver_name}`,
    pageWidth - 75,
    38
  );
  doc.text(
    `Dikirim dengan: ${data.travel_document?.shipped_with}`,
    pageWidth - 75,
    44
  );
  doc.text(
    `Nomor Polisi: ${data.travel_document?.licence_plat}`,
    pageWidth - 75,
    50
  );
  doc.setFontSize(10);
  doc.text('Kepada:', 10, 32);
  doc.setFont('times');
  doc.setFontSize(10);
  var text = `${data.client?.name} \n${data.client?.regency}`;
  var lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor;
  var splittedText = doc.splitTextToSize(text, pageWidth / 2);
  var lines = splittedText.length; // splitted text is a string array
  var blockHeight = lines * lineHeight;
  var yPos = 38;
  var xPos = 10;
  doc.text(xPos, yPos, splittedText);
  yPos += blockHeight;

  const startY = yPos > 56 ? yPos : 60;

  // WITH AUTO TABLE
  autoTable(doc, {
    startY,
    theme: 'grid',
    headStyles: { fillColor: [47, 84, 150], textColor: 'white' },
    head: headTable(),
    body: rowsTable(),
    columnWidth: 'wrap',
    tableWidth: 'auto',
    columnStyles: {
      2: {
        cellWidth: 30,
      },
      3: {
        cellWidth: 40,
      },
    },
    styles: {
      overflow: 'linebreak',
      overflowColumns: 'linebreak',
      textColor: 'black',
    },
  });

  const lastTable = doc.lastAutoTable.finalY + 10;
  const halfPage = pageWidth / 2;
  const additionalInfo = 'Barang yang diterima dalam kondisi lengkap dan baik';
  doc.text(additionalInfo, 10, doc.lastAutoTable.finalY + 5);
  const textSupervisor = 'Tanda Terima,';
  const textSupervisorLength = Math.round(doc.getTextWidth(textSupervisor));
  const textSubordinate = 'Hormat Kami,';
  const textSubordinateLength = Math.round(doc.getTextWidth(textSubordinate));

  const textSupervisorStartAt = (halfPage - textSupervisorLength) / 2;
  const textSubordinateStartAt =
    (halfPage - textSubordinateLength) / 2 + halfPage;

  doc.setFontSize(12);
  doc.text(textSupervisor, textSupervisorStartAt, lastTable + 5);
  doc.text(textSubordinate, textSubordinateStartAt, lastTable + 5);

  const supervisorName = '( ...................................... )';
  const supervisorNameLength = Math.round(doc.getTextWidth(supervisorName));
  const subordinateName = company?.name;
  const subordinateNameLength = Math.round(doc.getTextWidth(subordinateName));

  const textSupervisorNameStartAt = (halfPage - supervisorNameLength) / 2;
  const textSubordinateNameStartAt =
    (halfPage - subordinateNameLength) / 2 + halfPage;

  doc.setFontSize(12);
  doc.text(supervisorName, textSupervisorNameStartAt, lastTable + 40);
  doc.text(subordinateName, textSubordinateNameStartAt, lastTable + 40);

  //   title: `LAPORAN AKTIVITAS PERIODE ${periode.startDate} - ${periode.endDate}`,
  doc.setProperties({
    title: `Surat Jalan ${data.travel_document?.reference_number}`,
  });
  doc = addWaterMark(doc, image, halfPage / 2, startY);
  // OPEN NEW TAB
  window.open(doc.output('bloburl', { filename: 'Surat Jalan.pdf' }));

  // doc.save('Nota.pdf');
};

export default TravelDocument;
