import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import dayjs from 'dayjs';
import logo_gji from './logo-gji.png';
import logo_gja from './logo-gja.png';
import { companyInfo } from '../../utils';

function addWaterMark(doc, image, x, y) {
  var totalPages = doc.internal.getNumberOfPages();

  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    doc.saveGraphicsState();
    doc.setGState(new doc.GState({ opacity: 0.2 }));
    doc.addImage(image, 'PNG', x, y - 15, 100, 100);

    doc.restoreGraphicsState();
  }

  return doc;
}

const headTable = () => {
  return [
    {
      name: 'Nama Barang',
      discount: 'Diskon',
      selling_price: 'Harga',
      qty: 'Jumlah',
      sub_total: 'Sub Total',
    },
  ];
};

const InformationProductPDF = (data, datetime, custom) => {
  const printDate = data?.printed_at || dayjs(datetime).format('DD-MM-YYYY');
  const fields = ['name', 'discount', 'selling_price', 'qty', 'sub_total'];

  const body = data.order_products
    ?.filter((y) => y.status === 'TERKIRIM' || y.status === 'TERBAYAR')
    .map((x, index) => {
      const obj = fields.map((z) => {
        const child = {};

        if (['discount', 'selling_price', 'sub_total'].includes(z)) {
          if (custom && z === 'selling_price') {
            child.content = `Rp. ${x.markup_price?.toLocaleString('id-ID')}`;
          } else if (custom && z === 'sub_total') {
            const sTotal = x.markup_price * x.qty;
            child.content = `Rp. ${sTotal.toLocaleString('id-ID')}`;
          } else {
            child.content = `Rp. ${x[z]?.toLocaleString('id-ID')}`;
          }

          if (z === 'sub_total') {
            child.styles = {
              fontStyle: 'bold',
            };
          }
        } else {
          child.content = x[z];
        }

        if (z === 'name') {
          const text = x.description ? `${x.name} (${x.description})` : x.name;
          child.content = text;
        }

        return child;
      });

      return obj;
    });

  let doc = new jsPDF({
    putOnlyUsedFonts: true,
    orientation: 'portrait',
    format: [210, 330],
  });

  const image = new Image();
  const company = companyInfo(data.company);
  const address = data.client?.address || '';

  if (data.company === 'GJI') {
    image.src = logo_gji;
  } else if (data.company === 'GJA') {
    image.src = logo_gja;
  }

  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const COMPANY_NAME = company?.name;
  const TITLE = 'D R A F T';
  doc.addImage(image, 'PNG', 10, 5, 16, 16);

  // COMPANY_NAME
  doc.setFontSize(13);
  doc.setFont('times', 'bold');
  doc.text(COMPANY_NAME, 28, 9);
  doc.setFont('times', 'normal');

  // ADDRESS
  doc.setFontSize(10);
  var addressText = company?.address;
  var splittedTextAddress = doc.splitTextToSize(addressText, pageWidth / 2);
  doc.text(28, 14, splittedTextAddress);
  doc.text(company?.phone, 28, 22);

  doc.setFontSize(24);
  doc.text(TITLE, pageWidth - 47, 15);
  doc.setDrawColor(47, 84, 150);
  doc.setLineWidth(1);
  doc.line(10, 24, pageWidth - 10, 24);
  doc.setLineWidth(1.5);
  doc.line(10, 26, pageWidth - 10, 26);

  const name = custom ? `${data?.employee?.name}*` : data?.employee?.name;

  // NO INVOICE
  doc.setFontSize(10);
  doc.setFont('times', 'bold');
  doc.text(`Tanggal Cetak: ${printDate}`, pageWidth - 54, 32);
  doc.text(`Marketing: ${name}`, pageWidth - 54, 38);
  doc.setFontSize(10);
  doc.text('Kepada:', 10, 32);
  doc.setFont('times');
  doc.setFontSize(10);
  var text = `${data.client?.name} ${data.client?.regency} \n${address}`;
  var lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor;
  var splittedText = doc.splitTextToSize(text, pageWidth / 2);
  var lines = splittedText.length;
  var blockHeight = lines * lineHeight;
  var yPos = 38;
  var xPos = 10;
  doc.text(xPos, yPos, splittedText, { lineHeightFactor: 1.5 });
  yPos += blockHeight;

  const startY = yPos > 56 ? yPos : 60;
  let paymentInfo = [];
  const totalSales = custom
    ? data?.order_products?.reduce(
        (n, { markup_price, qty }) =>
          Number(n) + Number(markup_price) * Number(qty),
        0,
      )
    : data?.total_sales;

  paymentInfo = [
    [
      {
        content: '',
        colSpan: 5,
      },
    ],
    [
      {
        content: 'Total Jual',
        colSpan: 4,
        styles: { halign: 'left', valign: 'middle', fontStyle: 'bold' },
      },
      {
        content: `Rp. ${totalSales.toLocaleString('id-ID')}`,
        styles: { fontStyle: 'bold' },
      },
    ],
  ];

  autoTable(doc, {
    margin: { left: 10 },
    startY: startY,
    theme: 'grid',
    headStyles: { fillColor: [47, 84, 150], textColor: 'white', fontSize: 8 },
    head: headTable(),
    body: [...body, ...paymentInfo],
    columnStyles: {
      0: {
        cellWidth: 80,
      },
      1: {
        cellWidth: 25,
      },
      2: {
        cellWidth: 35,
      },
      3: {
        cellWidth: 15,
      },
      4: {
        cellWidth: 35,
      },
    },
    styles: {
      overflow: 'linebreak',
      overflowColumns: 'linebreak',
      textColor: 'black',
    },
  });

  const lastTable = doc.lastAutoTable.finalY + 10;

  const halfPage = pageWidth / 2;
  const textSupervisor = 'Tanda Terima,';
  const textSupervisorLength = Math.round(doc.getTextWidth(textSupervisor));
  const textSubordinate = 'Hormat Kami,';
  const textSubordinateLength = Math.round(doc.getTextWidth(textSubordinate));

  const textSupervisorStartAt = (halfPage - textSupervisorLength) / 2;
  const textSubordinateStartAt =
    (halfPage - textSubordinateLength) / 2 + halfPage;

  doc.setFontSize(12);
  doc.text(textSupervisor, textSupervisorStartAt, lastTable + 5);
  doc.text(textSubordinate, textSubordinateStartAt, lastTable + 5);

  const supervisorName = '( ...................................... )';
  const supervisorNameLength = Math.round(doc.getTextWidth(supervisorName));
  const subordinateName = company?.name;
  const subordinateNameLength = Math.round(doc.getTextWidth(subordinateName));

  const textSupervisorNameStartAt = (halfPage - supervisorNameLength) / 2;
  const textSubordinateNameStartAt =
    (halfPage - subordinateNameLength) / 2 + halfPage;

  doc.setFontSize(12);
  doc.text(supervisorName, textSupervisorNameStartAt, lastTable + 40);
  doc.text(subordinateName, textSubordinateNameStartAt, lastTable + 40);

  doc = addWaterMark(doc, image, halfPage / 2, startY);
  doc.setProperties({
    title: `Informasi Produk ${data.client?.name}`,
  });
  // OPEN NEW TAB
  window.open(
    doc.output('bloburl', {
      filename: `Informasi Produk ${data.client?.name}.pdf`,
    }),
  );
};

export default InformationProductPDF;
