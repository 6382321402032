/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import GlobalContext from '../../utils/GlobalContext';
import { ORDER_BY_CLIENT_ID } from '../../graphql/Order';
import { CLIENT_BY_CID } from '../../graphql/Client';

const ClientDetail = (props) => {
  const { cid } = props?.match?.params;
  const { setGlobalLoading } = React.useContext(GlobalContext);
  const [orders, setOrders] = React.useState([]);
  const [client, setClient] = React.useState({});
  const [name, setName] = React.useState('');
  const [init, setInit] = React.useState(true);
  const [isSearch, setIsSearch] = React.useState(false);

  /* FETCH DATA PRODUCTS */
  const { loading: loadOrder, data: dataOrder } = useQuery(ORDER_BY_CLIENT_ID, {
    variables: { client_id: client?.id },
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  const { loading: loadClient, data: dataClient } = useQuery(CLIENT_BY_CID, {
    variables: { cid },
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  React.useEffect(() => {
    if (loadClient) {
      setGlobalLoading(true);
    }
  }, []);

  React.useEffect(() => {
    if (dataClient) {
      setClient(dataClient.findClientByCid);
      setGlobalLoading(false);
    }
  }, [dataClient]);

  React.useEffect(() => {
    if (loadOrder) {
      setGlobalLoading(true);
    }
  }, []);

  React.useEffect(() => {
    if (client?.id && init) {
      setOrders(dataOrder.findOrderByClientId);
      console.log('dataOrder');
      setInit(false);
      setGlobalLoading(false);
    }
  }, [dataOrder]);

  const [getOrderProductName] = useLazyQuery(ORDER_BY_CLIENT_ID, {
    fetchPolicy: 'network-only',
  });

  const doSearch = async () => {
    setIsSearch(true);
    const data = await getOrderProductName({
      variables: { client_id: client?.id, name },
    });
    const result = data.data?.findOrderByClientId.filter(
      (x) => x.order_products.length
    );

    setOrders(result);
  };

  const clearSearch = async () => {
    setName('');
    setIsSearch(false);
    const data = await getOrderProductName({
      variables: { client_id: client?.id, name: '' },
    });
    const result = data.data?.findOrderByClientId.filter(
      (x) => x.order_products.length
    );

    setOrders(result);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" mb={2} gutterBottom>
            Detail Pelanggan
          </Typography>
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
          }}
        >
          <Button
            component={Link}
            to={'/admin/pelanggan'}
            variant="text"
            size="small"
          >
            Kembali
          </Button>{' '}
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
          }}
        >
          <FormControl size="small" margin="dense">
            <OutlinedInput
              name="search"
              margin="dense"
              id="search"
              placeholder="Cari"
              onChange={(e) => setName(e.target.value)}
              value={name}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  doSearch();
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  {isSearch && (
                    <IconButton onClick={() => clearSearch()}>
                      <ClearIcon />
                    </IconButton>
                  )}
                  {!isSearch && (
                    <IconButton onClick={() => doSearch()}>
                      <SearchIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
            Nama Pelanggan
          </Typography>
          <Typography variant="body2">{client?.name}</Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
            Alamat
          </Typography>
          <Typography variant="body2">{client?.address}</Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
            Wilayah
          </Typography>
          <Typography variant="body2">{client?.regency}</Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
            No Telepon
          </Typography>
          <Typography variant="body2">{client?.phone}</Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
            Marketing
          </Typography>
          <Typography variant="body2">{client?.employee?.name}</Typography>
        </Box>
        <Box sx={{ mb: 3, mr: 3 }}>
          <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
            Tanggal Berdiri Sekolah
          </Typography>
          <Typography variant="body2">{client?.anniversary || '-'}</Typography>
        </Box>
      </Box>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12}>
          <TableContainer>
            <Table id="userTable" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Tanggal Transaksi</TableCell>
                  <TableCell>Nama Barang</TableCell>
                  <TableCell>Harga Dasar</TableCell>
                  <TableCell>Harga Jual</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders?.map((row, index) => (
                  <>
                    <TableRow key={index}>
                      <TableCell
                        key={index}
                        rowSpan={row?.order_products.length + 1}
                      >
                        {row?.created_at}
                      </TableCell>
                    </TableRow>
                    {row?.order_products?.map((x, idx) => (
                      <TableRow key={idx}>
                        <TableCell>{x.name}</TableCell>
                        <TableCell>
                          Rp. {x.base_price.toLocaleString('id-ID')}
                        </TableCell>
                        <TableCell>
                          Rp. {x.selling_price.toLocaleString('id-ID')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ClientDetail;
