/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { FIND_PRODUCT_BY_PID } from '../../graphql/Product';
import GlobalContext from '../../utils/GlobalContext';
import { Button } from '@mui/material';

const ProductDetail = (props) => {
  const { pid } = props?.match?.params;
  const { setGlobalLoading } = React.useContext(GlobalContext);
  // const isEdit = props?.location?.search ? 'true' : 'false';
  const [product, setProduct] = React.useState({});

  /* FETCH DATA PRODUCTS */
  const { loading: loadProducts, data: dataProduct } = useQuery(
    FIND_PRODUCT_BY_PID,
    {
      variables: { pid },
      fetchPolicy: 'network-only',
      onError: (e) => console.log('error getting selor:', e),
    }
  );

  React.useEffect(() => {
    if (loadProducts) {
      setGlobalLoading(true);
    }
  }, []);

  React.useEffect(() => {
    if (dataProduct) {
      setProduct(dataProduct.findProductByPid);
      setGlobalLoading(false);
    }
  }, [dataProduct]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" mb={2} gutterBottom>
            {product?.name}
          </Typography>
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
          }}
        >
          <Button
            component={Link}
            to={'/admin/produk/'}
            variant="outlined"
            size="small"
          >
            Kembali
          </Button>
        </Box>
      </Box>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={6}>
          <Paper
            sx={{
              borderRadius: '8px',
              padding: '24px',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={10} container direction="column">
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  style={{ fontWeight: 'bold' }}
                >
                  Informasi Harga
                </Typography>
              </Grid>
              <Grid item xs={2} container direction="column">
                <Button variant="contained" size="small">
                  Ubah
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                container
                direction="column"
              >
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  Harga Dasar
                </Typography>
                <Typography variant="body2">
                  Rp. {product?.base_price?.toLocaleString('id-ID')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                container
                direction="column"
              >
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  Ongkos Kirim
                </Typography>
                <Typography variant="body2">
                  Rp. {product?.delivery_fee?.toLocaleString('id-ID')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                container
                direction="column"
              >
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  Total Harga Dasar
                </Typography>
                <Typography variant="body2">
                  Rp. {product?.total_base_price?.toLocaleString('id-ID')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                container
                direction="column"
              >
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  Diskon & Tipe
                </Typography>
                <Typography variant="body2">
                  Rp. {product?.discount?.toLocaleString('id-ID')} (
                  {product?.discount_type})
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                container
                direction="column"
              >
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  Harga Jual
                </Typography>
                <Typography variant="body2">
                  Rp. {product?.selling_price?.toLocaleString('id-ID')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                container
                direction="column"
              >
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  Profit
                </Typography>
                <Typography variant="body2">
                  Rp. {product?.profit?.toLocaleString('id-ID')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                container
                direction="column"
              >
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  Persentase Profit (%)
                </Typography>
                <Typography variant="body2">{product?.percentage} %</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            sx={{
              borderRadius: '8px',
              padding: '24px',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} container direction="column">
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  style={{ fontWeight: 'bold' }}
                >
                  Informasi Umum
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                container
                direction="column"
              >
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  Satuan
                </Typography>
                <Typography variant="body2">{product?.unit?.name}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                container
                direction="column"
              >
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  Jenis
                </Typography>
                <Typography variant="body2">{product?.type?.name}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                container
                direction="column"
              >
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  Tanggal Dibuat
                </Typography>
                <Typography variant="body2">{product?.created_at}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                container
                direction="column"
              >
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  Deskripsi
                </Typography>
                <Typography variant="body2">
                  {product?.description || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ProductDetail;
