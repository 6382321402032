/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import {
  COMPANIES,
  CREATE_COMPANY,
  UPDATE_COMPANY,
  SEARCH_COMPANY,
  UPLOAD_PHOTO,
} from '../../graphql/Company';
import Box from '@mui/material/Box';
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import MyBreadcrumbs from '../MyBreadcrumbs';
import GlobalContext from '../../utils/GlobalContext';
import DrawerCompany from './DrawerCompany';

const CompanyForm = React.lazy(() => import('./CompanyForm'));

const ClientPage = (props) => {
  const { role } = props;
  const { setSnack, setGlobalLoading } = React.useContext(GlobalContext);
  const [company, setCompany] = React.useState([]);
  const [baseCompany, setBaseCompany] = React.useState([]);
  const [searched, setSearched] = React.useState('');
  const [isSearch, setIsSearch] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [data, setData] = React.useState({});
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [regency, setRegency] = React.useState('ALL');
  const [marketing, setMarketing] = React.useState('');

  const columns = [
    {
      field: 'id',
      headerName: 'Nomor',
      width: 90,
      renderCell: (params) => `${params.api.getRowIndex(params.row.id) + 1}.`,
    },
    {
      field: 'name',
      headerName: 'Nama Perusahaan',
      width: 250,
    },
    {
      field: 'address',
      headerName: 'Alamat',
      width: 250,
    },
    {
      field: 'pic',
      headerName: 'PIC',
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'Nomor Telepon',
      flex: 1,
    },
    {
      field: 'notes',
      headerName: 'Catatan',
      flex: 1,
    },
  ];

  if (role !== 'MONITORING') {
    columns.push({
      field: 'uid',
      headerName: 'Aksi',
      width: 90,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <Tooltip title='Ubah' placement='right'>
              <IconButton
                aria-label='edit'
                size='small'
                onClick={() => {
                  setEdit(true);
                  setData(params?.row);
                  setOpen(true);
                }}
              >
                <EditIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        );
      },
    });
  }

  const requestSearch = (value) => {
    setSearched(value);
  };

  const clearSearch = () => {
    setSearched('');
    setIsSearch(false);
    setCompany([...baseCompany]);
  };

  const doSearch = async () => {
    setIsSearch(true);
    const data = await getCompanies({
      variables: { name: searched },
    });

    setCompany([...data.data?.searchCompanies]);
  };

  /* FETCH DATA COMPANIES */
  const { loading: loadClients, data: dataClients } = useQuery(COMPANIES, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  /* FETCH DATA SEARCH CLIENTS */
  const [getCompanies] = useLazyQuery(SEARCH_COMPANY, {
    fetchPolicy: 'network-only',
  });

  /* MUTATE DATA */
  const [insertCompany] = useMutation(CREATE_COMPANY, {
    onCompleted: (d) => {
      const data = company;
      setCompany([...data, d.createCompany]);
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data Perusahaan berhasil disimpan',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);
    },
    onError: (e) => {
      console.log('error verify user: ', e);
      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message: 'Data Perusahaan gagal disimpan',
        opened: true,
      });
    },
  });

  const [uploadPhoto] = useMutation(UPLOAD_PHOTO, {
    onCompleted: (d) => {
      debugger;
    },
    onError: (e) => {
      console.log('error verify user: ', e);
      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message: 'Data Perusahaan gagal disimpan',
        opened: true,
      });
    },
  });

  const [updateCompany] = useMutation(UPDATE_COMPANY, {
    onCompleted: (d) => {
      const data = company;
      const updatedData = data.map((x) => {
        if (x.id === d.updateCompany.id) {
          return { ...d.updateCompany };
        }

        return x;
      });

      setCompany([...updatedData]);
      setSnack({
        variant: 'success',
        message: 'Data Perusahaan berhasil diubah',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);
      setEdit(false);
      setData({});
    },
    onError: (e) => {
      console.log('error insert unit: ', e);
      let message = 'Data Perusahaan gagal diubah';

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  /* BUTTON DIALOG */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /* INSERT DATA */
  const handleSubmit = async (values) => {
    setGlobalLoading(true);
    if (edit) {
      updateCompany({
        variables: {
          uid: data.uid,
          data: values,
        },
      });
    } else {
      insertCompany({
        variables: { data: values },
      });
    }
  };

  React.useEffect(() => {
    if (loadClients) {
      setGlobalLoading(true);
    }
  }, []);

  React.useEffect(() => {
    if (dataClients) {
      setBaseCompany(dataClients.companies);
      setCompany(dataClients.companies);
      setGlobalLoading(false);
    }
  }, [dataClients]);

  const doFilter = async () => {
    setGlobalLoading(true);
    setOpenDrawer(false);
    setIsSearch(true);
    // const data = await getClients({
    //   variables: { regency, employee_id: marketing },
    // });

    setCompany([...data.data?.searchClients]);
    setGlobalLoading(false);
  };

  const resetFilter = () => {
    setOpenDrawer(false);
    setCompany([...baseCompany]);
    setRegency('ALL');
    setMarketing('');
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    uploadPhoto({ variables: { photo: file } });
    debugger;
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <MyBreadcrumbs data={['Master', 'Perusahaan']} />
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
          }}
        >
          <FormControl size='small' margin='dense'>
            <OutlinedInput
              name='search'
              margin='dense'
              id='search'
              placeholder='Cari'
              onChange={(e) => requestSearch(e.target.value)}
              value={searched}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  doSearch();
                }
              }}
              endAdornment={
                <InputAdornment position='end'>
                  {isSearch && (
                    <IconButton onClick={() => clearSearch()}>
                      <ClearIcon />
                    </IconButton>
                  )}
                  {!isSearch && (
                    <IconButton onClick={() => doSearch()}>
                      <SearchIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        {role !== 'MONITORING' && (
          <Box
            sx={{
              p: 0.5,
              m: 0.5,
            }}
          >
            <Tooltip title='Tambah' placement='top'>
              <IconButton
                aria-label='add'
                size='small'
                onClick={() => handleClickOpen()}
                style={{
                  backgroundColor: '#1976d2',
                  color: 'white',
                  marginRight: 10,
                }}
              >
                <AddIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={company}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </div>
      </Paper>

      <React.Suspense fallback={<div></div>}>
        <CompanyForm
          open={open}
          setOpen={setOpen}
          handleSubmit={handleSubmit}
          setEdit={setEdit}
          edit={edit}
          setData={setData}
          data={data}
        />
      </React.Suspense>

      <React.Suspense fallback={<div></div>}>
        <DrawerCompany
          open={openDrawer}
          setOpen={setOpenDrawer}
          marketing={marketing}
          setMarketing={setMarketing}
          regency={regency}
          setRegency={setRegency}
          doFilter={doFilter}
          resetFilter={resetFilter}
          employees={[]}
        />
      </React.Suspense>

      <>
        <input
          accept='application/pdf'
          type='file'
          onChange={async (e) => handleUpload(e)}
          hidden
        />
      </>
    </React.Fragment>
  );
};

export default ClientPage;
