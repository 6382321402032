import { gql } from '@apollo/client';

export const FIND_EXTERNAL_ORDER = gql`
  query findExternalOrderByQuarterly($quarterly: Int!, $year: Int!) {
    findExternalOrderByQuarterly(quarterly: $quarterly, year: $year) {
      id
      eoid
      employee_id
      quarterly
      total_payment_fee
      total_sales
      tax
      type
      admin_fee
      tax_admin_fee
      refund
      company
      mutation_at
      mutation_at_default
      created_at
      updated_at
      additional_fee
      additional
      employee {
        id
        name
        role
      }
      client {
        id
        name
        address
        regency
      }
      external_order_details {
        id
        eodid
        external_order_id
        no_reference
        nominal
        transaction_at
        created_at
        updated_at
        tax
      }
    }
  }
`;

export const CREATE_EXTERNAL_ORDER = gql`
  mutation createExternalOrder($data: InExternalOrder!) {
    createExternalOrder(data: $data) {
      id
      eoid
    }
  }
`;
