import React from 'react';
import Cookies from 'universal-cookie';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import MyAdmin from './MyAdmin';
import MyLoading from './MyLoading';
import MyLogin from './MyLogin';
import MySnack from './MySnack';
import ClaimForm from '../components/Claim/ClaimForm';

const cookies = new Cookies();
const PrivateRoute = (prop) => (
  <div>
    <Route
      path='/'
      render={(props) =>
        cookies.get('token') ? (
          <MyAdmin {...prop} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  </div>
);

const MyRouter = (greaterProps) => {
  return (
    <>
      {greaterProps.globalLoading && <MyLoading />}
      <Router>
        <Switch>
          <Route
            exact
            path='/login'
            render={(props) => (
              <MyLogin token={cookies.get('token')} {...props} />
            )}
          />
          <Route
            exact
            path='/claim'
            render={(props) => <ClaimForm {...props} />}
          />
          <PrivateRoute />
          <Route path='*'>
            <>404</>
          </Route>
          <Route path='/'>
            <Redirect to='/login' />
          </Route>
        </Switch>
        <MySnack {...greaterProps.snack} />
      </Router>
    </>
  );
};

export default MyRouter;
