import { gql } from '@apollo/client';

export const UNITS = gql`
  query units {
    units {
      id
      unid
      name
      created_at
      updated_at
    }
  }
`;

export const SEARCH_UNIT = gql`
  query searchUnits($name: String!) {
    searchUnits(name: $name) {
      id
      unid
      name
      created_at
      updated_at
    }
  }
`;

export const CREATE_UNIT = gql`
  mutation createUnit($name: String!) {
    createUnit(name: $name) {
      id
      unid
      name
      created_at
      updated_at
    }
  }
`;

export const UPDATE_UNIT = gql`
  mutation updateUnit($unid: String!, $name: String!) {
    updateUnit(unid: $unid, name: $name) {
      id
      unid
      name
      created_at
      updated_at
    }
  }
`;
