import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import logo_gji from './logo-gji.png';
import logo_gja from './logo-gja.png';
import { companyInfo } from '../../utils';

const headTable = () => {
  return [
    {
      name: 'Nama Produk',
      qty: 'Jumlah',
      type: 'Jenis',
      unit: 'Satuan',
      budget: 'Anggaran',
    },
  ];
};

const PurchaseOrderDocument = (data) => {
  const rowsTable = () => {
    const body = data.order_products
      ?.filter((y) => y.status === 'NEW')
      .map((x) => ({
        name: x.name,
        qty: x.qty,
        type: x.type?.name,
        unit: x.unit?.name,
        budget: `Rp. ${x.budget?.toLocaleString('id-ID')}`,
      }))
      .sort((a, b) => a.type.localeCompare(b.type));

    return body;
  };

  let doc = new jsPDF({
    putOnlyUsedFonts: true,
    orientation: 'portrait',
    format: [210, 330],
  });
  const image = new Image();
  const company = companyInfo(data.company);

  if (data.company === 'GJI') {
    image.src = logo_gji;
  } else if (data.company === 'GJA') {
    image.src = logo_gja;
  }

  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const COMPANY_NAME = company?.name;
  doc.addImage(image, 'PNG', 10, 5, 16, 16);

  // COMPANY_NAME
  doc.setFontSize(13);
  doc.setFont('times', 'bold');
  doc.text(COMPANY_NAME, 28, 9);
  doc.setFont('times', 'normal');

  // ADDRESS
  doc.setFontSize(10);
  // doc.text(company?.address, 28, 15);
  var addressText = company?.address;
  var splittedTextAddress = doc.splitTextToSize(addressText, pageWidth / 2);
  doc.text(28, 14, splittedTextAddress);
  doc.text(company?.phone, 28, 22);

  doc.setFontSize(18);
  doc.setDrawColor(47, 84, 150);
  doc.setLineWidth(1);
  doc.line(10, 24, pageWidth - 10, 24);
  doc.setLineWidth(1.5);
  doc.line(10, 26, pageWidth - 10, 26);

  doc.setFontSize(10);
  doc.setFont('times', 'bold');
  doc.text(`Nama Sekolah: ${data?.client?.name}`, 10, 32);
  doc.text(`Marketing: ${data?.employee?.name}`, 10, 38);

  autoTable(doc, {
    startY: 44,
    theme: 'grid',
    headStyles: { fillColor: [47, 84, 150], textColor: 'white' },
    head: headTable(),
    body: rowsTable(),
    columnWidth: 'wrap',
    tableWidth: 'auto',
    styles: {
      overflow: 'linebreak',
      overflowColumns: 'linebreak',
      textColor: 'black',
    },
  });

  doc.setProperties({
    title: `Purchase Order ${data?.client?.name}`,
  });

  window.open(
    doc.output('bloburl', {
      filename: `Purchase Order ${data?.client?.name}.pdf`,
    })
  );

  // doc.save('Nota.pdf');
};

export default PurchaseOrderDocument;
