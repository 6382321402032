import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { generateArrayOfYears } from '../../utils';

export default function TemporaryDrawer(props) {
  const {
    setOpen,
    open,
    quarterly,
    setQuarterly,
    status,
    setStatus,
    doFilter,
    resetFilter,
    year,
    setYear,
  } = props;
  const years = generateArrayOfYears();

  return (
    <React.Fragment>
      <Drawer anchor={'right'} open={open} onClose={() => setOpen(false)}>
        <Box sx={{ width: 250 }} role="presentation">
          <Grid container spacing={2}>
            <Typography sx={{ mt: 4, ml: 4 }} variant="button">
              Filter Data
            </Typography>
            <Grid
              item
              sx={{ mt: 2, mr: 2, ml: 2 }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <FormControl fullWidth>
                <InputLabel id="quarter">Cawu</InputLabel>
                <Select
                  labelId="quarter"
                  id="quarter"
                  name="quarter"
                  label="Cawu"
                  value={quarterly}
                  onChange={(e) => setQuarterly(e.target.value)}
                >
                  <MenuItem disabled selected value={null}>
                    Pilih
                  </MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item sx={{ mr: 2, ml: 2 }} xs={12} sm={12} md={12} lg={12}>
              <FormControl fullWidth>
                <InputLabel id="status">Status Order</InputLabel>
                <Select
                  labelId="status"
                  id="status"
                  name="status"
                  label="Status Order"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem disabled selected value="">
                    Pilih
                  </MenuItem>
                  <MenuItem value="ALL">ALL</MenuItem>
                  <MenuItem value="NEW">NEW</MenuItem>
                  <MenuItem value="PROCESS">PROCESS</MenuItem>
                  <MenuItem value="WAITING INPUT BILL">
                    WAITING INPUT BILL
                  </MenuItem>
                  <MenuItem value="WAITING PAYMENT">WAITING PAYMENT</MenuItem>
                  <MenuItem value="PENDING PAYMENT">PENDING PAYMENT</MenuItem>
                  <MenuItem value="WAITING CASH">WAITING CASH</MenuItem>
                  <MenuItem value="DONE">DONE</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item sx={{ mr: 2, ml: 2 }} xs={12} sm={12} md={12} lg={12}>
              <FormControl fullWidth>
                <InputLabel id="year">Tahun</InputLabel>
                <Select
                  labelId="year"
                  id="year"
                  name="year"
                  label="Tahun"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  {years.map((x, i) => (
                    <MenuItem key={i} value={x}>
                      {x}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sx={{ mr: 2, ml: 2 }} xs={12} sm={12} md={12} lg={12}>
              <Button
                variant="contained"
                size="small"
                onClick={() => doFilter()}
              >
                Cari
              </Button>
              <Button variant="text" size="small" onClick={() => resetFilter()}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
