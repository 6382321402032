import { gql } from '@apollo/client';

export const PRODUCTS = gql`
  query products($limit: Int = 10, $offset: Int = 0) {
    products(limit: $limit, offset: $offset) {
      id
      pid
      unit_id
      type_id
      name
      description
      base_price
      delivery_fee
      total_base_price
      selling_price
      discount
      discount_type
      profit
      percentage
      created_at
      updated_at
      unit {
        id
        name
      }
      type {
        id
        name
      }
    }
  }
`;

export const SEARCH_PRODUCT = gql`
  query searchProducts($name: String!) {
    searchProducts(name: $name) {
      id
      name
      selling_price
      created_at
    }
  }
`;

export const FIND_PRODUCT_BY_PID = gql`
  query findProductByPid($pid: String!) {
    findProductByPid(pid: $pid) {
      id
      pid
      unit_id
      type_id
      name
      description
      base_price
      delivery_fee
      total_base_price
      selling_price
      discount
      discount_type
      profit
      percentage
      created_at
      updated_at
      unit {
        id
        name
      }
      type {
        id
        name
      }
    }
  }
`;
