import React from 'react';
import {
  Box,
  IconButton,
  Paper,
  Tooltip,
  InputAdornment,
  FormControl,
  OutlinedInput,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';

import MyBreadcrumbs from '../MyBreadcrumbs';
import GlobalContext from '../../utils/GlobalContext';
import { RENTALS } from '../../graphql/Rental';

const RentalPage = () => {
  let history = useHistory();
  const { setGlobalLoading } = React.useContext(GlobalContext);
  const [rentals, setRentals] = React.useState([]);
  const [baseRentals, setBaseRentals] = React.useState([]);
  const [searched, setSearched] = React.useState('');
  const [isSearch, setIsSearch] = React.useState(false);

  const columns = [
    {
      field: 'id',
      headerName: 'Nomor',
      width: 90,
      renderCell: (params) => `${params.api.getRowIndex(params.row.id) + 1}.`,
    },
    {
      field: 'name',
      headerName: 'Nama Perusahaan',
      width: 250,
    },
    {
      field: 'address',
      headerName: 'Alamat',
      width: 250,
    },
    {
      field: 'pic',
      headerName: 'PIC',
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'Nomor Telepon',
      flex: 1,
    },
    {
      field: 'total_rental',
      headerName: 'Jumlah Peminjaman',
      flex: 1,
    },
    {
      field: 'oid',
      headerName: 'Aksi',
      width: 90,
      renderCell: (params) => {
        return (
          <Tooltip title='Detail' placement='top'>
            <IconButton
              aria-label='detail'
              size='small'
              component={Link}
              to={`/admin/rental/${params?.row?.uid}`}
              target='__blank'
            >
              <InfoIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const requestSearch = (value) => {
    setSearched(value);
  };

  const clearSearch = () => {
    setSearched('');
    setIsSearch(false);
    setRentals([...baseRentals]);
  };

  const doSearch = async () => {
    setIsSearch(true);
    // const data = await getCompanies({
    //   variables: { name: searched },
    // });

    // setCompany([...data.data?.searchCompanies]);
  };

  /* FETCH DATA COMPANIES */
  const { loading: loadRentals, data: dataRentals } = useQuery(RENTALS, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  React.useEffect(() => {
    if (loadRentals) {
      setGlobalLoading(true);
    }
  }, []);

  React.useEffect(() => {
    if (dataRentals) {
      setBaseRentals(dataRentals.rentals);
      setRentals(dataRentals.rentals);
      setGlobalLoading(false);
    }
  }, [dataRentals]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <MyBreadcrumbs data={['Transaksi', 'Mesin Fotokopi']} />
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
          }}
        >
          <FormControl size='small' margin='dense'>
            <OutlinedInput
              name='search'
              margin='dense'
              id='search'
              placeholder='Cari'
              onChange={(e) => requestSearch(e.target.value)}
              value={searched}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  doSearch();
                }
              }}
              endAdornment={
                <InputAdornment position='end'>
                  {isSearch && (
                    <IconButton onClick={() => clearSearch()}>
                      <ClearIcon />
                    </IconButton>
                  )}
                  {!isSearch && (
                    <IconButton onClick={() => doSearch()}>
                      <SearchIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
          }}
        >
          <Tooltip title='Tambah' placement='top'>
            <IconButton
              aria-label='add'
              size='small'
              onClick={() => history.push('/admin/rental/new')}
              style={{
                backgroundColor: '#1976d2',
                color: 'white',
                marginRight: 10,
              }}
            >
              <AddIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={rentals}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default RentalPage;
