/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import { PRODUCTS, SEARCH_PRODUCT } from '../../graphql/Product';
import Box from '@mui/material/Box';
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import MyBreadcrumbs from '../MyBreadcrumbs';
import GlobalContext from '../../utils/GlobalContext';

// const ClientForm = React.lazy(() => import('./ClientForm'));

const columns = [
  {
    field: 'id',
    headerName: 'Nomor',
    width: 90,
    renderCell: (params) => `${params.api.getRowIndex(params.row.id) + 1}.`,
  },
  {
    field: 'name',
    headerName: 'Nama',
    width: 250,
  },
  {
    field: 'unit',
    headerName: 'Satuan',
    width: 90,
    valueGetter: (params) => params.row?.unit?.name,
  },
  {
    field: 'type',
    headerName: 'Jenis',
    width: 90,
    valueGetter: (params) => params.row?.type?.name,
  },
  {
    field: 'base_price',
    headerName: 'Harga Dasar',
    flex: 1,
    valueGetter: (params) =>
      `Rp. ${params.row.base_price.toLocaleString('id-ID')}`,
  },
  {
    field: 'selling_price',
    headerName: 'Harga Jual',
    flex: 1,
    valueGetter: (params) =>
      `Rp. ${params.row.selling_price.toLocaleString('id-ID')}`,
  },
  {
    field: 'pid',
    headerName: 'Aksi',
    width: 90,
    renderCell: (params) => {
      return (
        <Tooltip title="Detail" placement="top">
          <IconButton
            aria-label="detail"
            size="small"
            component={Link}
            to={`/admin/produk/${params.row.pid}`}
            target="__blank"
          >
            <InfoIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      );
    },
  },
];

const ProductPage = () => {
  const { setGlobalLoading } = React.useContext(GlobalContext);
  const [products, setProducts] = React.useState([]);
  const [baseProducts, setBaseProducts] = React.useState([]);
  const [searched, setSearched] = React.useState('');
  const [isSearch, setIsSearch] = React.useState(false);
  const [setOpen] = React.useState(false);
  const [finalClickInfo, setFinalClickInfo] = React.useState(null);

  const requestSearch = (value) => {
    setSearched(value);
  };

  const clearSearch = () => {
    setSearched('');
    setIsSearch(false);
    setProducts([...baseProducts]);
  };

  const doSearch = async () => {
    setIsSearch(true);
    const data = await getProducts({
      variables: { name: searched },
    });

    setProducts([...data.data?.searchProducts]);
  };

  /* FETCH DATA PRODUCTS */
  const { loading: loadProducts, data: dataProducts } = useQuery(PRODUCTS, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  /* FETCH DATA SEARCH PRODUCTS */
  const [getProducts] = useLazyQuery(SEARCH_PRODUCT, {
    fetchPolicy: 'network-only',
  });

  /* MUTATE DATA */
  // const [mutateClient] = useMutation(CREATE_CLIENT, {
  //   onCompleted: (d) => {
  //     const data = products;
  //     setProducts([...data, d.createClient]);
  //     setGlobalLoading(true);
  //     setSnack({
  //       variant: 'success',
  //       message: 'Data Produk berhasil disimpan',
  //       opened: true,
  //     });
  //     setGlobalLoading(false);
  //     setOpen(false);
  //   },
  //   onError: (e) => {
  //     console.log('error verify user: ', e);
  //     setGlobalLoading(false);
  //     setSnack({
  //       variant: 'error',
  //       message: 'Data Produk gagal disimpan',
  //       opened: true,
  //     });
  //   },
  // });

  /* BUTTON DIALOG */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /* INSERT DATA */
  // const handleSubmit = async (data) => {
  //   mutateClient({
  //     variables: { data },
  //   });
  // };

  React.useEffect(() => {
    if (loadProducts) {
      setGlobalLoading(true);
    }
  }, []);

  React.useEffect(() => {
    if (dataProducts) {
      setBaseProducts(dataProducts.products);
      setProducts(dataProducts.products);
      setGlobalLoading(false);
    }
  }, [dataProducts]);

  const handleOnCellClick = (params) => {
    setFinalClickInfo(params);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <MyBreadcrumbs data={['Master', 'Produk']} />
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
          }}
        >
          <FormControl size="small" margin="dense">
            <OutlinedInput
              name="search"
              margin="dense"
              id="search"
              placeholder="Cari"
              onChange={(e) => requestSearch(e.target.value)}
              value={searched}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  doSearch();
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  {isSearch && (
                    <IconButton onClick={() => clearSearch()}>
                      <ClearIcon />
                    </IconButton>
                  )}
                  {!isSearch && (
                    <IconButton onClick={() => doSearch()}>
                      <SearchIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
          }}
        >
          <Tooltip title="Tambah" placement="top">
            <IconButton
              aria-label="add"
              size="small"
              onClick={() => handleClickOpen()}
              style={{ backgroundColor: '#1976d2', color: 'white' }}
            >
              <AddIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={products}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            onCellClick={handleOnCellClick}
          />
          {finalClickInfo &&
            console.log(`Final clicked id = ${finalClickInfo.id},
            Final clicked field = ${finalClickInfo.field},
            Final clicked value = ${finalClickInfo.value}`)}
        </div>
      </Paper>

      {/* <React.Suspense fallback={<div></div>}>
        <ClientForm open={open} setOpen={setOpen} handleSubmit={handleSubmit} />
      </React.Suspense> */}
    </React.Fragment>
  );
};

export default ProductPage;
