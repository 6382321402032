/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import {
  UNITS,
  SEARCH_UNIT,
  CREATE_UNIT,
  UPDATE_UNIT,
} from '../../graphql/Unit';
import Box from '@mui/material/Box';
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import MyBreadcrumbs from '../MyBreadcrumbs';
import GlobalContext from '../../utils/GlobalContext';

const UnitForm = React.lazy(() => import('./UnitForm'));

export default function DataGridDemo(props) {
  const { role } = props;
  const { setSnack, setGlobalLoading } = React.useContext(GlobalContext);
  const [units, setUnits] = React.useState([]);
  const [baseUnits, setBaseUnits] = React.useState([]);
  const [searched, setSearched] = React.useState('');
  const [isSearch, setIsSearch] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');

  const [data, setData] = React.useState({});
  const [edit, setEdit] = React.useState(false);

  const columns = [
    {
      field: 'id',
      headerName: 'Nomor',
      width: 90,
      renderCell: (params) => `${params.api.getRowIndex(params.row.id) + 1}.`,
    },
    {
      field: 'name',
      headerName: 'Nama Satuan',
      flex: 1,
    },
  ];

  if (role !== 'MONITORING') {
    columns.push({
      field: 'cid',
      headerName: 'Aksi',
      width: 90,
      renderCell: (params) => {
        return (
          <Tooltip title="Ubah" placement="right">
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => {
                setEdit(true);
                setData(params?.row);
                setOpen(true);
                setName(params?.row.name);
              }}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        );
      },
    });
  }

  const requestSearch = (value) => {
    setSearched(value);
  };

  const clearSearch = () => {
    setSearched('');
    setIsSearch(false);
    setUnits([...baseUnits]);
  };

  const doSearch = async () => {
    setIsSearch(true);
    const data = await getUnits({
      variables: { name: searched },
    });

    setUnits([...data.data?.searchUnits]);
  };

  /* FETCH DATA UNITS */
  const { loading: loadUnits, data: dataUnits } = useQuery(UNITS, {
    fetchPolicy: 'network-only',
    onError: (e) => console.log('error getting selor:', e),
  });

  /* FETCH DATA SEARCH UNITS */
  const [getUnits] = useLazyQuery(SEARCH_UNIT, {
    fetchPolicy: 'network-only',
  });

  /* MUTATE DATA */
  const [insertUnit] = useMutation(CREATE_UNIT, {
    onCompleted: (d) => {
      const data = units;
      setUnits([...data, d.createUnit]);
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data Satuan berhasil disimpan',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);
      setName('');
    },
    onError: (e) => {
      console.log('error insert unit: ', e);
      let message = 'Data satuan gagal disimpan';

      if (e.toString().includes('duplicate key')) {
        message = 'Nama satuan tidak boleh sama';
      }

      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  const [updateUnit] = useMutation(UPDATE_UNIT, {
    onCompleted: (d) => {
      const data = units;
      const updatedData = data.map((x) => {
        if (x.id === d.updateUnit.id) {
          return {
            ...x,
            name: d.updateUnit.name,
          };
        }

        return x;
      });

      setUnits([...updatedData]);
      setGlobalLoading(true);
      setSnack({
        variant: 'success',
        message: 'Data Satuan berhasil diubah',
        opened: true,
      });
      setGlobalLoading(false);
      setOpen(false);
      setEdit(false);
      setData({});
      setName('');
    },
    onError: (e) => {
      console.log('error insert unit: ', e);
      let message = 'Data satuan gagal diubah';

      if (e.toString().includes('duplicate key')) {
        message = 'Nama satuan tidak boleh sama';
      }
      setGlobalLoading(false);
      setSnack({
        variant: 'error',
        message,
        opened: true,
      });
    },
  });

  /* BUTTON DIALOG */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /* INSERT DATA */
  const handleSubmit = async () => {
    if (edit) {
      updateUnit({
        variables: {
          unid: data.unid,
          name,
        },
      });
    } else {
      insertUnit({
        variables: { name },
      });
    }
  };

  React.useEffect(() => {
    if (loadUnits) {
      setGlobalLoading(true);
    }
  }, []);

  React.useEffect(() => {
    if (dataUnits) {
      setBaseUnits(dataUnits.units);
      setUnits(dataUnits.units);
      setGlobalLoading(false);
    }
  }, [dataUnits]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <MyBreadcrumbs data={['Master', 'Satuan']} />
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
          }}
        >
          <FormControl size="small" margin="dense">
            <OutlinedInput
              name="search"
              margin="dense"
              id="search"
              placeholder="Cari"
              onChange={(e) => requestSearch(e.target.value)}
              value={searched}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  doSearch();
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  {isSearch && (
                    <IconButton onClick={() => clearSearch()}>
                      <ClearIcon />
                    </IconButton>
                  )}
                  {!isSearch && (
                    <IconButton onClick={() => doSearch()}>
                      <SearchIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        {role !== 'MONITORING' && (
          <Box
            sx={{
              p: 0.5,
              m: 0.5,
            }}
          >
            <Tooltip title="Tambah" placement="top">
              <IconButton
                aria-label="add"
                size="small"
                onClick={() => handleClickOpen()}
                style={{ backgroundColor: '#1976d2', color: 'white' }}
              >
                <AddIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={units}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </div>
      </Paper>

      <React.Suspense fallback={<div></div>}>
        <UnitForm
          open={open}
          setOpen={setOpen}
          name={name}
          setName={setName}
          handleSubmit={handleSubmit}
          setEdit={setEdit}
          edit={edit}
          data={data}
        />
      </React.Suspense>
    </React.Fragment>
  );
}
