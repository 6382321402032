/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLocation } from 'react-router-dom';
import { ORDER_BY_QUARTERLY } from '../../graphql/Order';
import Box from '@mui/material/Box';
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';
import PrintIcon from '@mui/icons-material/Print';
import FilterListIcon from '@mui/icons-material/FilterList';
import Badge from '@mui/material/Badge';
import { useHistory } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import MyBreadcrumbs from '../MyBreadcrumbs';
import GlobalContext from '../../utils/GlobalContext';
import { chipColor } from '../../utils';
import DrawerFilter from './DrawerFilter';
import OrderReportPDF from '../PDF/OrderReportPDF';

function useQueryParam() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DataOrderPage = (props) => {
  let q = useQueryParam();
  const queryParamStatus = q.get('status');
  const queryParamYear = Number(q.get('year'));
  const qStatus =
    queryParamStatus === 'new'
      ? 'NEW'
      : queryParamStatus === 'process'
      ? 'PROCESS'
      : '';

  const { setGlobalLoading } = React.useContext(GlobalContext);
  const [orders, setOrders] = React.useState([]);
  const [baseOrders, setbaseOrders] = React.useState([]);
  const [searched, setSearched] = React.useState('');
  const [isSearch, setIsSearch] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [skip, setSkip] = React.useState(false);
  const [quarterly, setQuarterly] = React.useState(queryParamStatus ? 0 : 1);
  const [year, setYear] = React.useState(
    queryParamYear || new Date().getFullYear(),
  );
  const [status, setStatus] = React.useState(
    queryParamStatus ? qStatus : 'ALL',
  );
  const [isFilter, setIsFilter] = React.useState(false);
  let history = useHistory();

  const columns = [
    {
      field: 'id',
      headerName: 'Nomor',
      width: 70,
      renderCell: (params) => `${params.api.getRowIndex(params.row.id) + 1}.`,
    },
    {
      field: 'name',
      headerName: 'Nama Pelanggan',
      flex: 1,
      renderCell: (params) => {
        return (
          <React.Fragment>
            {params.row?.client?.name}
            &nbsp;
            <Chip
              label={params.row.status}
              variant='filled'
              color={chipColor(params.row?.status)}
              size='small'
            />
            {params.row?.is_custom && (
              <StarIcon sx={{ color: 'gold' }} size='small' />
            )}
          </React.Fragment>
        );
      },
    },
    {
      field: 'quarterly',
      headerName: 'Cawu & Tahun',
      width: 175,
      valueGetter: (params) =>
        `Cawu ${params?.row?.quarterly} - Tahun ${params?.row?.year}`,
    },
    {
      field: 'company',
      headerName: 'CV',
      width: 70,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <Chip
              label={params.row?.company}
              variant='filled'
              color={chipColor(params.row?.company)}
              size='small'
            />
          </React.Fragment>
        );
      },
    },
    {
      field: 'pic_name',
      headerName: 'Nama PIC',
      width: 120,
      valueGetter: (params) => params.row?.employee?.name,
    },
    {
      field: 'grand_total',
      headerName: 'Total Anggaran',
      width: 120,
      valueGetter: (params) =>
        `Rp. ${params.row.total_budget.toLocaleString('id-ID')}`,
    },
    {
      field: 'created_at',
      headerName: 'Tanggal Input',
      width: 160,
    },
    {
      field: 'oid',
      headerName: 'Aksi',
      width: 90,
      renderCell: (params) => {
        return (
          <Tooltip title='Detail' placement='top'>
            <IconButton
              aria-label='detail'
              size='small'
              component={Link}
              to={`/admin/data_order/${params.row.oid}`}
              target='__blank'
            >
              <InfoIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const requestSearch = (value) => {
    setSearched(value);
  };

  const clearSearch = () => {
    setSearched('');
    setIsSearch(false);
    setOrders([...baseOrders]);
  };

  const doSearch = async () => {
    setIsSearch(true);
    let filteredRows;
    // eslint-disable-next-line array-callback-return
    filteredRows = orders.filter((row) => {
      let search = searched.toLowerCase();
      var values = Object.values(row);
      var flag = false;

      values.forEach((val) => {
        if (val && typeof val === 'object') {
          if (
            val.__typename === 'Client' &&
            val?.name?.toLowerCase().indexOf(search) > -1
          ) {
            flag = true;
            return;
          }
        }
      });

      if (flag) return row;
    });
    setOrders(filteredRows);
  };

  /* FETCH DATA ORDER_BY_QUARTERLY */
  const { loading: loadOrders, data: dataOrderQuarterly } = useQuery(
    ORDER_BY_QUARTERLY,
    {
      variables: { quarterly, status, year },
      fetchPolicy: 'network-only',
      onError: (e) => console.log('error getting selor:', e),
      skip,
    },
  );

  React.useEffect(() => {
    if (loadOrders) {
      setGlobalLoading(true);
    }
  }, [year]);

  React.useEffect(() => {
    if (!loadOrders && !!dataOrderQuarterly) {
      setSkip(true);
    }
    if (dataOrderQuarterly) {
      setbaseOrders(dataOrderQuarterly.findOrderByQuarterly);
      setOrders(dataOrderQuarterly.findOrderByQuarterly);
      setGlobalLoading(false);
    }
  }, [dataOrderQuarterly]);

  const doFilter = async () => {
    setIsFilter(true);
    setGlobalLoading(true);
    setOpen(false);
    const data = await getFilterOrders({
      variables: { name: searched },
    });

    setOrders([...data.data?.findOrderByQuarterly]);
    setGlobalLoading(false);
  };

  const resetFilter = () => {
    setIsFilter(false);
    setOpen(false);
    setOrders([...baseOrders]);

    if (queryParamStatus) {
      console.log('refresh');
      history.push('/admin/data_order');
    }
  };

  /* FETCH DATA SEARCH CLIENTS */
  const [getFilterOrders] = useLazyQuery(ORDER_BY_QUARTERLY, {
    fetchPolicy: 'network-only',
    variables: { quarterly, status, year },
  });

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <MyBreadcrumbs
            data={['Transaksi', `Daftar Rencana Belanja ${year}`]}
          />
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
          }}
        >
          <FormControl size='small' margin='dense'>
            <OutlinedInput
              name='search'
              margin='dense'
              id='search'
              placeholder='Cari Nama Pelanggan...'
              onChange={(e) => requestSearch(e.target.value)}
              value={searched}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  doSearch();
                }
              }}
              endAdornment={
                <InputAdornment position='end'>
                  {isSearch && (
                    <IconButton onClick={() => clearSearch()}>
                      <ClearIcon />
                    </IconButton>
                  )}
                  {!isSearch && (
                    <IconButton onClick={() => doSearch()}>
                      <SearchIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            p: 0.5,
            m: 0.5,
          }}
        >
          <Tooltip title='Cetak PDF' placement='top'>
            <IconButton
              aria-label='add'
              size='small'
              onClick={() => OrderReportPDF(orders)}
              style={{ backgroundColor: '#1976d2', color: 'white' }}
            >
              <PrintIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip title='Filter' placement='top'>
            <IconButton
              aria-label='add'
              size='small'
              onClick={() => setOpen(true)}
              style={{ backgroundColor: '#1976d2', color: 'white' }}
            >
              <Badge invisible={!isFilter} color='error' variant='dot'>
                <FilterListIcon fontSize='inherit' />
              </Badge>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={orders}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </div>
      </Paper>

      <DrawerFilter
        open={open}
        setOpen={setOpen}
        quarterly={quarterly}
        setQuarterly={setQuarterly}
        status={status}
        setStatus={setStatus}
        doFilter={doFilter}
        resetFilter={resetFilter}
        year={year}
        setYear={setYear}
      />
    </React.Fragment>
  );
};

export default DataOrderPage;
